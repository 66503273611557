import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Typography } from '@mui/material';

export default function BasicDatePicker({
  width,
  height,
  onChange,
  error,
  errorMessage,
  required,
  value,  
}) {
  const handleDateChange = (date) => {
    onChange(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          sx={{
            height: height,
            width: width,
            borderRadius: '20px',
          }}
          onChange={handleDateChange}
          value={value} 
          disablePast
          required={required}
        />
        {error && (
          <Typography variant='p' color='error'>
            {errorMessage}
          </Typography>
        )}
      </DemoContainer>
    </LocalizationProvider>
  );
}
