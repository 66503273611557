import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HomeLayout from "../../asset/layout/HomeLayout";
import { getClientById, updateClientById } from "../../actions/clientsAuth";
import { useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  FormControlLabel,
  Tabs,
  Tab,
  FormControl,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { Table } from "react-bootstrap";
import IOSSwitch from "../../style/globalStyles";
import { enqueueSnackbar } from "notistack";
import { TabContext, TabPanel } from "@mui/lab";
import { BrandManagement } from "./BrandManagement";
import { Transactions } from "./TransactionsTab";
import RechargeRequests from "./RechargeRequests";
import { applyPermissions } from "../../actions/userAuth";

const clientDetailsMapping = {
  email: "Email",
  firstName: "First Name",
  lastName: "Last Name",
  phone: "Phone",
  addressProofDocument: "Address Proof Document",
  addressProofType: "Address Proof Type",
  adhaarDocument: "Aadhaar Document",
  balance: "Balance",
  cinNumber: "CIN Number",
  companyName: "Company Name",
  companyPanCardNumber: "Company Pan Card Number",
  companyType: "Company Type",
  createdAt: "Created At",
  directorAdhaarNumber: "Director Aadhaar Number",
  directorPanCardNumber: "Director Pan Card Number",
  gst: "GST Number",
  gstDocument: "GST Document",
  gstStatus: "GST Status",

  isVerified: "Is Verified",
  officeAddress: "Office Address",
  panCardDocument: "Pan Card Document",
  tradeName: "Trade Name",
  updatedAt: "Updated At",
  _id: "ID",
  isActive: "Is Active",
};

const ClientDetails = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <HomeLayout>
      <Box>
        <TabContext value={value}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              my: 6,
              overflowX: "auto",
              width: "100%",
            }}
          >
            <Tab label="Company" value="1" />
            <Tab label="Permissions" value="2" />
            <Tab label="Transactions" value="3" />
            <Tab label="Brand Management" value="4" />
            <Tab label="Recharge Requests" value="5" />
          </Tabs>
          <TabPanel value="1">
            <CompanyDetails />
          </TabPanel>
          <TabPanel value="2">
            <Permissions />
          </TabPanel>
          <TabPanel value="3">
            <Transactions />
          </TabPanel>
          <TabPanel value="4">
            <BrandManagement />
          </TabPanel>
          <TabPanel value="5">
            <RechargeRequests />
          </TabPanel>
        </TabContext>
      </Box>
    </HomeLayout>
  );
};

export default ClientDetails;

export const CompanyDetails = () => {
  const { id } = useParams();
  const [clientData, setClientData] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";

    const date = new Date(dateString);

    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };
  const getData = () => {
    setLoading(true);
    dispatch(
      getClientById(
        id,
        (data) => {
          console.log(data.data.permission);
          setLoading(false);
          const formattedData = {
            ...data.data,
            createdAt: formatDate(data.data.createdAt),
            updatedAt: formatDate(data.data.updatedAt),
          };
          setClientData(formattedData);
        },
        (error) => {
          console.log("Error fetching client data:", error);
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    getData();
  }, [dispatch, id]);

  const handleActiveState = (event) => {
    const updatedStatus = event.target.checked;

    const payload = { isActive: updatedStatus };

    dispatch(
      updateClientById(
        id,
        payload,
        (response) => {
          enqueueSnackbar("User status updated successfully", {
            variant: "success",
          });
          setClientData((prevData) => ({
            ...prevData,
            isActive: updatedStatus,
          }));
        },
        (error) => {
          enqueueSnackbar("Error updating user status", {
            variant: "error",
          });
          console.error("Error updating user status:", error);
        }
      )
    );
  };

  const renderDocumentLink = (key, value) => {
    if (value && value.startsWith("http")) {
      return (
        <a href={value} target="_blank" rel="noopener noreferrer">
          View Document
        </a>
      );
    }
    return value || "N/A";
  };
  return (
    <Box>
      <Typography pt={5} textAlign={"center"} variant="h2">
        Company Details
      </Typography>

      <Box mx={{ md: 10, xs: 0.2 }} my={{ md: 10, xs: 5 }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={10}>
            <TableContainer
              component={Paper}
              elevation={3}
              sx={{ borderRadius: "2rem" }}
            >
              <Table style={{ width: "100%" }}>
                <TableBody>
                  {Object?.keys(clientDetailsMapping).map((key) => (
                    <TableRow key={key}>
                      <TableCell xs={{ width: "100%" }}>
                        <Typography
                          mx={3}
                          variant="h5"
                          fontWeight="bold"
                          align="start"
                        >
                          {clientDetailsMapping[key]}
                        </Typography>
                      </TableCell>
                      <TableCell xs={{ width: "100%" }}>
                        <Typography
                          align="start"
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          {key.toLowerCase().includes("document") ? (
                            renderDocumentLink(key, clientData[key])
                          ) : key === "isActive" ? (
                            <>
                              {clientData[key] ? "Active" : "Inactive"}
                              <FormControlLabel
                                style={{
                                  paddingLeft: "20%",
                                }}
                                control={
                                  <IOSSwitch
                                    checked={clientData.isActive ?? false}
                                    onChange={handleActiveState}
                                  />
                                }
                              />
                            </>
                          ) : typeof clientData[key] === "boolean" ? (
                            clientData[key] ? (
                              "True"
                            ) : (
                              "False"
                            )
                          ) : (
                            clientData[key] || "N/A"
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export const Permissions = () => {
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const { id } = useParams();
  const [state, setState] = useState({
    merchandise: false,
    discount: false,
  });
  const dispatch = useDispatch();

  // Fetch data from backend
  const getData = () => {
    setLoading(true);
    dispatch(
      getClientById(
        id,
        (data) => {
          console.log(data.data.permission);
          setLoading(false);
          setPermissions(data.data.permission);
        },
        (error) => {
          console.log("Error fetching client data:", error);
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    getData();
  }, [dispatch, id]);

  useEffect(() => {
    if (permissions.length > 0) {
      setState({
        merchandise: permissions.includes("merchandise"),
        discount: permissions.includes("discount"),
      });
    }
  }, [permissions]);

  const handleApplyPermissions = (updatedState) => {
    const selectedPermissions = Object.keys(updatedState).filter(
      (permission) => updatedState[permission]
    );

    const payload = {
      businessAccountId: id,
      permissions: selectedPermissions, // Send the full list of permissions
    };

    dispatch(
      applyPermissions(
        payload,
        (data) => {
          console.log("Permissions updated successfully:", data);
          enqueueSnackbar("Permissions updated successfully!", {
            variant: "success",
          });
        },
        (err) => {
          console.error("Error updating permissions:", err);
          enqueueSnackbar("Error updating permissions!", { variant: "error" });
        }
      )
    );
  };

  const handleChange = (event) => {
    const updatedState = {
      ...state,
      [event.target.name]: event.target.checked,
    };
    setState(updatedState);

    // Call the API with updated permissions
    handleApplyPermissions(updatedState);
  };

  return (
    <FormControl component="fieldset">
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.merchandise}
              onChange={handleChange}
              name="merchandise"
            />
          }
          label="Merchandise"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={state.discount}
              onChange={handleChange}
              name="discount"
            />
          }
          label="Discount for Employees"
        />
      </FormGroup>
    </FormControl>
  );
};
