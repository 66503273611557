import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: '#fff', // White background
    boxShadow:
      'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function SelectDropdown({
  buttonText,
  menuItems,
  selectedItem,
  setSelectedItem,
  height,
  borderRadius,
  buttonWidth,
  ref,
  onChange, // New prop
}) {
  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedItem(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const resetDropdown = () => {
    setSelectedItem('');
  };

  React.useEffect(() => {
    if (ref) {
      ref.current = {
        reset: resetDropdown,
      };
    }
  }, [ref]);

  return (
    <div>
      <StyledSelect
        value={selectedItem}
        onChange={handleChange}
        displayEmpty
        fullWidth
        inputProps={{
          'aria-label': 'Select',
        }}
      >
        <MenuItem
          onClick={() => handleChange({ target: { value: '' } })}
          disableRipple
        >
          {buttonText}
        </MenuItem>
        {Array.isArray(menuItems) &&
          menuItems.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
      </StyledSelect>
    </div>
  );
}
