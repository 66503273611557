import React, { useEffect, useRef, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Stack,
  Grid,
  CircularProgress,
  Box,
  Autocomplete,
  TextField,
} from "@mui/material";
import HomeLayout from "../../asset/layout/HomeLayout";
import { Add, Terminal } from "@mui/icons-material";

import CustomButton from "../../components/mui/CustomButton";

import { useDispatch, useSelector } from "react-redux";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import DropDownMenu from "../../components/mui/DropDownMenu";

import { FaEye } from "react-icons/fa";
import SearchBar from "../../components/mui/SearchBar";
import DatePickerComponent from "../../components/DatePickerComponent";
import { MasterCard } from "../../components/Cards/MasterCard";

import { getAllRewards, postTheReward } from "../../actions/ticketAuth";

import ViewRewardModal from "../../components/ViewRewardModal";
import RModal from "../../components/mui/RModal";
import InputField1 from "../../components/mui/InputField1";
import { getAllUsers, getAllUsersClient } from "../../actions/userAuth";
import { enqueueSnackbar } from "notistack";
import ResponsiveDatePickers from "../../components/mui/PichDate";
import * as XLSX from "xlsx";
const columns = [
  "Reward Points",
  "From",
  "To",
  "Created on ",
  "Status ",
  "Remark",
  "Action",
];

const iconData = [
  {
    src: "/CardTransfer.svg",

    alt: "Number of Reward",
    name: "Number of Reward",
    bgColor: "#FFF5D9",
    count: 0,
  },
  {
    src: "/CardSend.svg",
    alt: " reward point debited",
    name: " reward point debited",
    bgColor: "#FFE0EB",
    count: 0,
  },
  {
    src: "/ClipboardRemove.svg",

    alt: " total transactions",
    name: " total transactions",

    bgColor: "#E7EDFF",
    count: 0,
  },

  {
    src: "/CardRecive.svg",
    alt: "total no of users created",
    name: "total no of users created",
    bgColor: "#DCFAF8",
    count: 0,
  },
];

const rewardStatus = ["CREDIT", "DEBIT"];

export const RewardManagement = () => {
  const [open, setOpen] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [allUserData, setAllUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);
  const [allBackendUser, setAllBackendUser] = useState(25);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [selectedRewards, setSelectedRewards] = useState(null);
  const { user } = useSelector((state) => ({ ...state }));

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const allUser = [];
  console.log("user is", allUser);

  const userId = user.id;
  console.log(userId);
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      Navigate("/");
    }
  }, [user]);

  useEffect(() => {
    console.log("User ID:", user);
  }, [user]);

  console.log(userId);
  const allUserPhoneNumbers = [];

  for (const userId in allUser) {
    const user = allUser[userId];
    if (user && user.phone) {
      allUserPhoneNumbers.push(user.id);
    }
  }

  const dropdownRef = useRef(null);

  const [rewardOption, setRewardOption] = useState("");
  const [rewardOptionSerach, setRewardOptionSerach] = useState("");

  const [filterValue, setFilterValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [amountReward, setAmountReward] = useState();
  const [rewardTo, setRewardTo] = useState([]);
  const [remark, setRemark] = useState([]);

  const [totalTransactions, setTotalTransactions] = useState();
  const [pendingTransactions, setPendingTransactions] = useState();
  const [failedTransactions, setFailedTransactions] = useState();
  const [thisWeekTransactions, setThisWeekTransactions] = useState();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const roleDropdownRef = useRef(null);
  const handleCloseSecondModal = () => {
    setOpenSecondModal(false);
  };

  const [getReward, setGetReward] = useState([]);

  const getData = () => {
    console.log("Fetching data...");
    setLoading(true);
    dispatch(
      getAllRewards(
        { page: currentPage, perPage: "" },
        (data) => {
          console.log("data is ", data.data.data.transactions);
          const filter = data.data.data.transactions.filter((item) => {
            const matchQuery =
              item?.point
                ?.toString()
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              item?.from?.username
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              item?.to?.phone
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              item?.createdAt
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              item?.action?.toLowerCase().includes(searchTerm.toLowerCase());

            const matchDate =
              (!selectedDateFrom ||
                new Date(item.updatedAt) >= new Date(selectedDateFrom)) &&
              (!selectedDateTo ||
                new Date(item.updatedAt) <= new Date(selectedDateTo));

            return matchQuery && matchDate;
          });
  console.log("data is ",filter);
          setGetReward(filter);
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      )
    );
  };

  const getUser = () => {
    setLoading(true);
    dispatch(
      getAllUsersClient(
        { page: 1, perPage: "" },
        (data) => {
          console.log("Sorted data:", data.data.data.users);

          setAllUserData(data.data.data.users);

          setLoading(false);
        },
        (error) => {
          console.log("getting error ");
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    getData();
    getUser();
  }, [searchTerm, selectedDateFrom, selectedDateTo]);
  const autoCompleteOptions = allUserData.map((user) => {
    if (user.username && user.username !== "") {
      return {
        label: `${user.username} (${user.phone || "No phone"})`,
        value: user.username,
      };
    } else if (user.email && user.email !== "") {
      return {
        label: `${user.email} (${user.phone || "No phone"})`,
        value: user.email,
      };
    } else if (user.phone && user.phone !== "") {
      return { label: user.phone, value: user.phone };
    } else {
      return { label: "Unknown", value: "Unknown" };
    }
  });

  const formDataAdd = {
    from: userId,
    to: rewardTo,
    point: Number(amountReward),
    action: rewardOption,
    remark: remark,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submit modal");

    setOpenWarningModal(true);
  };

  console.log("autoCompleteOptions is", autoCompleteOptions);

  const handleOpenSecondModal = () => {
    setOpenSecondModal(true);
  };

  const handleCloseWarningModal = () => {
    setOpenWarningModal(false);
  };
  const handleWarningModalOpen = () => {
    setOpenWarningModal(false);
  };
  const [viewOpen, setViewOpen] = useState(false);

  const handleOpenModalView = (reward) => {
    console.log("hello i am here");
    setSelectedRewards(reward);
    setViewOpen(true);
  };
  const handleCloseModalVIew = () => {
    setViewOpen(false);
    setSelectedRewards(null);
  };

  const handleReset = () => {
    setAmountReward("");
    setRewardTo([]);
    setRewardOption("");
    setFilterValue("");
    setSearchQuery("");
    setRemark("");
  };

  const handleSubmitWarningModal = (e) => {
    e.preventDefault();
    dispatch(
      postTheReward(
        formDataAdd,
        (response) => {
          enqueueSnackbar("Data submitted successfully", {
            variant: "success",
          });

          setLoading(false);
          handleCloseSecondModal();
          handleCloseWarningModal();
          getData();
        },
        (error) => {
          enqueueSnackbar(error?.response?.data?.message, {
            variant: "error",
          });
        }
      )
    );
  };

  const handleCloseAllModal = () => {
    handleCloseWarningModal();
    handleCloseSecondModal();
  };
  const handleSearchClear = () => {
    setSearchTerm("");
    setSelectedDateFrom(null);
    setSelectedDateTo(null);
    setSearchQuery("");
  };

  const handleExport = () => {
    const workbook = XLSX.utils.book_new();
    const worksheetData = [
      ["Points", "From", "To", "Created on", "Status", "Action"],
      ...getReward.map((row) => [
        row.point,
        row.from?.username || row.from?.email,
        row.to?.phone,
        row.createdAt,
        row.status,
        row.action,
      ]),
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Rewards");
    XLSX.writeFile(workbook, "rewards_data.xlsx");
  };

  const paginatedData = getReward.slice(
    (currentPage - 1) * countPerPage,
    currentPage * countPerPage
  );

  return (
    <HomeLayout>
      <Stack
        alignItems="center"
        direction={"row"}
        justifyContent={"space-between"}
      >
        <Typography
          variant="h4"
          my={3}
          flexGrow={1}
          fontSize={{ xl: "22px", sm: "1rem", xs: "1rem" }}
        >
          Reward Management
        </Typography>
      </Stack>
      {/* <Grid container spacing={2} my={4}>
        {iconData?.map((data, index) => (
          <Grid
            item
            key={index}
            md={4}
            xs={6}
            xl={3}
            sm={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <MasterCard
              src={data.src}
              alt={data.alt}
              name={data.name}
              bgColor={data.bgColor}
              count={
                index === 0
                  ? totalTransactions
                  : index === 1
                  ? pendingTransactions
                  : index === 2
                  ? failedTransactions
                  : thisWeekTransactions
              }
            />
          </Grid>
        ))}
      </Grid> */}
      <Stack alignItems="center" direction={"row"} mb={3}>
        <Typography
          variant="h4"
          flexGrow={1}
          fontSize={{ xl: "22px", sm: "1rem", xs: "1rem" }}
        ></Typography>
        <Box pr={2}>
          {" "}
          <CustomButton
            variant="contained"
            onClick={handleExport}
            fontSize={{ xl: "1rem", sm: ".7rem", xs: ".7rem" }}
          >
            export Rewards
          </CustomButton>
        </Box>

        <CustomButton
          variant="contained"
          onClick={handleOpenSecondModal}
          fontSize={{ xl: "1rem", sm: ".7rem", xs: ".7rem" }}
        >
          {" "}
          <Add sx={{ mr: { xl: 1, sm: 0.2 } }} />
          Manage Reward
        </CustomButton>
      </Stack>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          <SearchBar onSearch={handleSearch} searchTerm={searchTerm} />
        </Grid>

        <Grid item md={3} xs={12}>
          <ResponsiveDatePickers
            selectedDate={selectedDateFrom}
            setSelectedDate={setSelectedDateFrom}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <ResponsiveDatePickers
            selectedDate={selectedDateTo}
            setSelectedDate={setSelectedDateTo}
          />
        </Grid>
        <Grid item md={2} xs={12}>
          {" "}
          <CustomButton
            variant="contained"
            onClick={handleSearchClear}
            width={"100%"}
            height={"2.5rem"}
          >
            clear
          </CustomButton>
        </Grid>
      </Grid>

      {loading ? (
        <Box align="center" my={5}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "25px",
            my: 3,
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {columns?.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#11409B",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      textAlign: "center",
                    }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                whiteSpace: "nowrap",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              {!loading && paginatedData?.length > 0 ? (
                paginatedData?.map((reward) => (
                  <TableRow key={reward?.id}>
                    <TableCell>{reward?.point}</TableCell>

                    <TableCell>
                      {reward?.from?.username ||
                        reward?.from?.phone ||
                        reward?.from?.email ||
                        "Unknown"}
                    </TableCell>
                    <TableCell>
                      {reward?.to?.username ||
                        reward?.to?.phone ||
                        reward?.to?.email ||
                        "Unknown"}
                    </TableCell>
                    <TableCell>
                      {reward?.createdAt
                        ? new Date(reward.createdAt)
                            .toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            })
                            .replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$1-$2-$3")
                        : "N/A"}
                    </TableCell>

                    <TableCell
                      sx={{
                        color: reward?.action === "DEBIT" ? "red" : "green",
                      }}
                    >
                      {reward?.action ? reward?.action.toLowerCase() : "--"}
                    </TableCell>
                    <TableCell>{reward?.remark}</TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <IconButton onClick={() => handleOpenModalView(reward)}>
                        <FaEye />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    {loading ? <CircularProgress /> : "No points found."}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {/* <Paginations /> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography>
            {(currentPage - 1) * countPerPage + 1} -{" "}
            {Math.min(currentPage * countPerPage, getReward.length)} of{" "}
            {getReward.length}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>Rows per page:</Typography>
          <select
            style={{
              marginTop: "-5px",
              background: "transparent",
              border: "none",
            }}
            value={countPerPage}
            onChange={(e) => setCountPerPage(e.target.value)}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
          </select>
          <Box sx={{ ml: 2 }}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <IconButton
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1 || countPerPage > getReward.length}
                sx={{
                  border: "1px solid",
                  borderRadius: "10px",
                  borderColor: "mute.main",
                  bgcolor: "white.main",
                  boxShadow: "2px 2px 0px #999",
                  background: "#fff",
                }}
              >
                <KeyboardArrowLeftIcon
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color:
                      currentPage === 1 || countPerPage > getReward.length
                        ? "#999"
                        : "#000",
                  }}
                />
              </IconButton>
              <Typography variant="text" component="span">
                {currentPage}/{Math.ceil(getReward.length / countPerPage)}
              </Typography>
              <IconButton
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={
                  countPerPage > getReward.length ||
                  currentPage === Math.ceil(getReward.length / countPerPage)
                }
                sx={{
                  border: "1px solid",
                  borderRadius: "10px",
                  borderColor: "mute.main",
                  bgcolor: "white.main",
                  boxShadow: "2px 2px 0px #999",
                  background: "#fff",
                }}
              >
                <KeyboardArrowRightIcon
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "#000",
                  }}
                />
              </IconButton>
            </Stack>
          </Box>
        </Box>
      </Box>
      {viewOpen && (
        <ViewRewardModal
          open={handleOpenModalView}
          handleClose={handleCloseModalVIew}
          reward={selectedRewards}
        />
      )}
      {openSecondModal && (
        <RModal
          open={openSecondModal}
          handleClose={handleCloseSecondModal}
          width={{ md: "60%", xs: "80%" }}
          height={"auto"}
        >
          <form onSubmit={handleSubmit}>
            <Typography variant="h4">Manage Points</Typography>
            <Grid container spacing={{ md: 1, sm: 0, xs: 0 }}>
              <Grid item md={6} xs={12} sm={12}>
                <InputField1
                  placeholderColor=" #8D98AA"
                  label={"amount"}
                  placeholder={"Enter amount"}
                  spanP={true}
                  value={amountReward}
                  onChange={(e) => setAmountReward(e?.target?.value)}
                  required
                  type={"number"}
                  maxLength={20}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Typography variant="h5" my={1.5}>
                  To
                </Typography>

                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={autoCompleteOptions}
                  value={rewardTo}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => {
                    const transformedValue = newValue
                      .map((item) => {
                        const user = allUserData.find(
                          (user) =>
                            user.username === item.value ||
                            user.phone === item.value ||
                            user.email === item.value
                        );
                        return user ? user.id : null;
                      })
                      .filter((id) => id !== null);
                    setRewardTo(transformedValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Assigned To"
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "2.5rem",
                          background: "#FFFFFF",
                          borderRadius: "7px",
                          paddingRight: "8px",
                          py: "auto",
                        },
                        "& .MuiInputBase-input": {
                          mt: "-12px",
                        },
                        "& .MuiAutocomplete-tag": {
                          mt: "-7px !important",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12} sm={12}>
                <Typography variant="h5" my={2}>
                  Action
                </Typography>
                <DropDownMenu
                  buttonText="Reward action"
                  menuItems={rewardStatus?.map((item) => {
                    return { label: item };
                  })}
                  selectedItem={rewardOption}
                  setSelectedItem={setRewardOption}
                  buttonWidth={"100%"}
                />
              </Grid>
              <Grid item md={6} xs={12} sm={12}>
                <InputField1
                  placeholderColor=" #8D98AA"
                  label={"Remark"}
                  placeholder={"Enter Remark"}
                  spanP={true}
                  value={remark}
                  onChange={(e) => setRemark(e?.target?.value)}
                  required
                />
              </Grid>
            </Grid>

            <Stack
              direction={"row"}
              spacing={{ xl: 6, md: 6, sm: 1, xs: 1 }}
              mt={4}
            >
              {" "}
              <CustomButton
                variant="contained"
                width={{ md: "20%", sm: "50%", xs: "60%" }}
                height={"3rem"}
                type="submit"
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : rewardOption === "DEBIT" ? (
                  "Revoke Points"
                ) : (
                  "Add Points"
                )}
              </CustomButton>
              <CustomButton
                variant="outlined"
                width={{ md: "20%", sm: "50%", xs: "40%" }}
                height={"3rem"}
                type="button"
                onClick={handleReset}
              >
                Reset
              </CustomButton>
            </Stack>
          </form>
        </RModal>
      )}

      {openWarningModal && (
        <RModal
          open={openWarningModal}
          handleClose={handleCloseWarningModal}
          width={"40%"}
          height={"auto"}
        >
          <Typography variant="h4"> Are you sure</Typography>
          <Typography mt={3} variant="h5">
            {" "}
            This action can not be reverted once done, are you sure ?
          </Typography>
          <Stack
            direction={"row"}
            spacing={{ xl: 6, md: 6, sm: 1, xs: 1 }}
            mt={4}
          >
            {" "}
            <CustomButton
              variant="contained"
              width={{ md: "20%", sm: "50%", xs: "60%" }}
              height={"3rem"}
              onClick={handleSubmitWarningModal}
            >
              Yes
            </CustomButton>
            <CustomButton
              variant="outlined"
              width={{ md: "20%", sm: "50%", xs: "40%" }}
              height={"3rem"}
              type="button"
              onClick={handleCloseAllModal}
            >
              No
            </CustomButton>
          </Stack>
        </RModal>
      )}
    </HomeLayout>
  );
};
