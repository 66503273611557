// UsersTab.js
import React from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { useState } from 'react';
import { BackendUser } from './BackendUser';
import SuperAdmin from './SuperAdmin';
import HomeLayout from '../../asset/layout/HomeLayout';

const UsersTab = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    return (
      
      <HomeLayout>
          
      <Tabs value={value} onChange={handleChange} aria-label="user management tabs" > 
        <Tab label="Managers" sx={{fontSize:"1.3rem",fontWeight:"bold"}}/>
        <Tab label="Super Admin" sx={{fontSize:"1.3rem",fontWeight:"bold"}} />
      </Tabs>
      {value === 0 && <BackendUser/>} 
      {value === 1 && <SuperAdmin/>}
    </HomeLayout>
  );
};

export default UsersTab;
