import React from 'react';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { secondaryColor } from '../../style/color';

export const BannerCard = ({ username, image, dis, onEdit, onDelete }) => {
  return (
    <Card
      sx={{
        borderRadius: '1.25rem',
        height: '13.125rem',
        minHeight: '13.69rem',
        width: { xl: '14rem' },
        boxShadow: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <CardContent
        className='textArea'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          // width: '100%',
          // height: '100%',
          // border: '2px solid green',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '50%',
          }}
        >
          <img
            src={image}
            alt='Banner'
            style={{
              maxWidth: 'calc(95vh - 2rem)',
              maxHeight: '7rem',
              objectFit: 'cover',
            }}
          />
        </Box>

        <Stack mt={'0.313rem'}>
          <Typography
            variant='medium'
            component={'h3'}
            mx={'auto'}
            sx={{
              fontSize: { xl: '17.27', md: '17px', xs: '10px', sm: '10px' },
              fontWeight: 'bold',
            }}
          >
            {username}
          </Typography>
        </Stack>
        <Stack>
          <Typography
            variant='small'
            color={secondaryColor.light}
            mx={'auto'}
            textAlign={'center'}
          >
            {dis}
          </Typography>
        </Stack>

        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <IconButton onClick={onEdit}>
            <img src='/liEdit.svg' alt='Edit' />
          </IconButton>
          <IconButton onClick={onDelete}>
            <img src='/liDelet.svg' alt='Delete' />
          </IconButton>
        </Stack>
      </CardContent>
    </Card>
  );
};
