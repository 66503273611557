import {
  Box,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CustomButton from '../../components/mui/CustomButton';
import { Add } from '@mui/icons-material';
import { useState } from 'react';
import InputField1 from '../../components/mui/InputField1';
import DropDownMenu from '../../components/mui/DropDownMenu';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { uploadDocument } from '../../actions/clientsAuth';
import RModal from '../../components/mui/RModal';

export const Transactions = ({ data = [] }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    balance: '',
    rechargeAction: '',
    transactionId: '',
    transactionDate: '',
    remark: '',
    paymentMode: '',
    referenceDocument: null,
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    console.log(formData);

   
    setTimeout(() => {
      setLoading(false);
      handleClose();
    }, 2000);
  };

  const [fileUrl, setFileUrl] = useState('');
  const dispatch = useDispatch();
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      addressProofDocument: file,
    });

    handleFileUpload(file);
  };
  const handleFileUpload = (file) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      dispatch(
        uploadDocument(
          formData,
          (response) => {
            console.log(
              'File uploaded successfully:',
              response.data
            );
            setFileUrl(response.data);
            enqueueSnackbar('File uploaded successfully.', {
              variant: 'success',
            });
          },
          (error) => {
            console.error('Error uploading file:', error);
            enqueueSnackbar('Error uploading file.', {
              variant: 'error',
            });
          }
        )
      );
    }
  };

  return (
    <>
      <Stack alignItems='center' direction={'row'} mt={1}>
        <Typography
          variant='h4'
          my={1}
          flexGrow={1}
          fontSize={{ xl: '22px', sm: '1rem', xs: '1rem' }}
        ></Typography>

        {/* <CustomButton
          variant='contained'
          onClick={handleOpen}
          fontSize={{ xl: '1rem', sm: '.7rem', xs: '.7rem' }}
        >
          <Add sx={{ mr: { xl: 1, sm: 0.2 } }} />
          Recharge Wallet
        </CustomButton> */}
      </Stack>
      <RModal
        open={open}
        handleClose={handleClose}
        width={{ md: '60%', xs: '80%' }}
        height={{ md: '80%', xs: '80%' }}
      >
        <Box>
          <Typography
            id='modal-title'
            variant='h6'
            component='h2'
          >
            Recharge Wallet
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={0.5}>
              <Grid item md={6} xs={12}>
                <InputField1
                  placeholderColor='#8D98AA'
                  label='⁠Action'
                  placeholder='Enter ⁠Action'
                  spanP={true}
                  required
                  maxLength={20}
                  name='rechargeAction'
                  value={formData.rechargeAction}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField1
                  placeholderColor='#8D98AA'
                  label='Balance'
                  placeholder='Enter Balance'
                  spanP={true}
                  required
                  maxLength={20}
                  name='balance'
                  value={formData.balance}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField1
                  placeholderColor='#8D98AA'
                  label='Transaction Id'
                  placeholder='Enter Transaction Id'
                  spanP={true}
                  required
                  maxLength={20}
                  name='transactionId'
                  value={formData.transactionId}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography variant='h6' pt={2} pb={1}>
                  Payment Mode
                </Typography>
                <DropDownMenu
                  buttonText={
                    formData.paymentMode || 'Select Payment Mode'
                  }
                  menuItems={[
                    { label: 'Imps/Rtgs/NEFT' },
                    { label: 'UPI' },
                    { label: 'Virtual Account' },
                    { label: 'OffLine Mode' },
                  ]}
                  selectedItem={formData.paymentMode}
                  setSelectedItem={(item) =>
                    setFormData({
                      ...formData,
                      paymentMode: item.label,
                    })
                  }
                  buttonWidth='100%'
                  name='paymentMode'
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField1
                  placeholderColor='#8D98AA'
                  label='Transaction Date'
                  spanP={true}
                  name='transactionDate'
                  value={formData.transactionDate}
                  onChange={handleChange}
                  type={'date'}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography
                  variant='h6'
                  component={'p'}
                  mt={2}
                  mb={1}
                >
                  Upload Proof Document
                </Typography>
                <TextField
                  fileUpload={true}
                  onChange={handleFileChange}
                  py={'10px'}
                  type={'file'}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6' my={1}>
                  Remark
                </Typography>
                <TextField
                  multiline
                  fullWidth
                  placeholder='Enter remark here ......'
                  name='remark'
                  value={formData.remark}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Box mt={3} textAlign='right'>
              <CustomButton
                variant='contained'
                width='10rem'
                color='primary'
                type='submit'
                disabled={loading}
              >
                {loading ? 'Submitting...' : 'Recharge Wallet'}
              </CustomButton>
            </Box>
          </form>
        </Box>
      </RModal>

      {/* Uncomment and use your TableContainer here */}
      {/* <TableContainer component={Paper} style={{ width: "100%" }}>
        <Typography variant="h6" component="div" style={{ padding: "16px" }}>
          Transactions
        </Typography>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow style={{ width: "100%" }}>
              <TableCell style={{ width: "20%" }}>ID</TableCell>
              <TableCell style={{ width: "20%" }} align="right">
                Date
              </TableCell>
              <TableCell style={{ width: "20%" }} align="right">
                Amount
              </TableCell>
              <TableCell style={{ width: "20%" }} align="right">
                Type
              </TableCell>
              <TableCell style={{ width: "20%" }} align="right">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell align="right">{row.date}</TableCell>
                  <TableCell align="right">{row.amount}</TableCell>
                  <TableCell align="right">{row.type}</TableCell>
                  <TableCell align="right">{row.status}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No data found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer> */}
    </>
  );
};
