import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
  Box,
  Stack,
  Modal,
} from "@mui/material";
import HomeLayout from "../../asset/layout/HomeLayout";
import SearchBar from "../../components/mui/SearchBar";
import { useDispatch } from "react-redux";
import { getAllOrders } from "../../actions/ordersAuth";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";
import ResponsiveDatePickers from "../../components/mui/PichDate";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import CustomButton from "../../components/mui/CustomButton";
import dayjs from "dayjs";

const columns = [
  "External Payment ID",
  "External Voucher ID",
  "Product GUID",
  "Phone",
  "Order Status",
  "Amount",
  "Quantity",
  "Date",
  "Brand Product Code",
];

export const OderManagement = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [downloadOrders, setDownloadOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);
  const [allOrders, setAllOrders] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      Navigate("/");
    }
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [searchTerm, selectedDateFrom, selectedDateTo, currentPage, countPerPage]);

  const fetchOrders = () => {
    setLoading(true);
    dispatch(
      getAllOrders(
        { page: 1, perPage: 100000 },
        (data) => {
          const filteredOrders = applyFilters(data.data.data.Orders);
          setAllOrders(filteredOrders);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          console.log(err);
        }
      )
    );
  };

  const applyFilters = (ordersData) => {
    let filteredOrders = ordersData;

    if (searchTerm) {
      filteredOrders = filteredOrders.filter((item) =>
        [
          item.denomination,
          item.externalOrderIdPayment,
          item.externalOrderIdVoucher,
          item.quantity.toString(),
          item.brandProductCode,
          item.resultType,
          item.user?.phone,
        ].some((field) =>
          field?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    if (selectedDateFrom || selectedDateTo) {
      filteredOrders = filteredOrders.filter((item) => {
        const createdAt = new Date(item.createdAt);

        let isAfterStartDate = true;
        if (selectedDateFrom) {
          isAfterStartDate = createdAt >= selectedDateFrom;
        }

        let isBeforeEndDate = true;
        if (selectedDateTo) {
          const endOfDay = new Date(selectedDateTo);
          endOfDay.setHours(23, 59, 59, 999);
          isBeforeEndDate = createdAt <= endOfDay;
        }

        return isAfterStartDate && isBeforeEndDate;
      });
    }

    return filteredOrders;
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const exportToExcel = () => {
    const header = [
      "External Payment ID",
      "External Voucher ID",
      "Product GUID",
      "Phone",
      "Order Status",
      "Amount",
      "Quantity",
      "Date",
      "Brand Product Code",
    ];

    const dataToExport = allOrders.map((order) => [
      order?.externalOrderIdPayment,
      order?.externalOrderIdVoucher,
      order?.pullVouchers[0]?.ProductGuid,
      order?.user?.phone,
      order?.resultType,
      order?.denomination,
      order?.quantity,
      order?.createdAt,
      order?.brandProductCode,
    ]);

    dataToExport.unshift(header);

    const worksheet = XLSX.utils.aoa_to_sheet(dataToExport);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Orders Data");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });

    saveAs(blob, "Orders_data.xlsx");
  };

  // Pagination
  const indexOfLastOrder = currentPage * countPerPage;
  const indexOfFirstOrder = indexOfLastOrder - countPerPage;
  const currentOrders = allOrders.slice(indexOfFirstOrder, indexOfLastOrder);

  const handleSearchClear = () => {
    setSearchTerm("");
    setSelectedDateFrom(null);
    setSelectedDateTo(null);
  };
  const handlePhoneClick = (order) => {
    setSelectedOrder(order);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedOrder(null);
  };
  return (
    <HomeLayout>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={3}
      >
        <Typography variant="h4">Order Management</Typography>
        <CustomButton
          variant="contained"
          width={{ md: "22%", sm: "50%", xs: "40%" }}
          height={"rem"}
          type="button"
          onClick={exportToExcel}
        >
          Export Orders
        </CustomButton>
      </Box>
      <Box>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <SearchBar onSearch={handleSearch} searchTerm={searchTerm} />
          </Grid>

          <Grid item md={3} xs={12}>
            <ResponsiveDatePickers
              selectedDate={selectedDateFrom}
              setSelectedDate={setSelectedDateFrom}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ResponsiveDatePickers
              selectedDate={selectedDateTo}
              setSelectedDate={setSelectedDateTo}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            {" "}
            <CustomButton
              variant="contained"
              onClick={handleSearchClear}
              width={"100%"}
              height={"2.5rem"}
            >
              clear
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
      {loading ? (
        <Typography textAlign={"center"} my={2}>
          <CircularProgress size={24} />
        </Typography>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "25px",
            overflowX: "scroll",
            my: 3,
          }}
        >
          <Table sx={{ maxWidth: "auto" }}>
            <TableHead>
              <TableRow>
                {columns?.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#11409B",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                whiteSpace: "nowrap",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              {currentOrders.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row?.externalOrderIdPayment}</TableCell>
                  <TableCell>{row?.externalOrderIdVoucher}</TableCell>
                  <TableCell>
                    {row?.pullVouchers[0]?.ProductGuid || "--"}
                  </TableCell>
                  <TableCell
                    onClick={() => handlePhoneClick(row)}
                    style={{ cursor: "pointer", color: "blue" }}
                  >
                    +91 {row?.user?.phone}
                  </TableCell>
                  <TableCell
                    sx={{
                      color:
                        row?.resultType === "SUCCESS"
                          ? "#16DBCC"
                          : row.resultType === "PENDING"
                          ? "#FFBB38"
                          : "#FF82AC",
                    }}
                  >
                    {row?.resultType || "--"}
                  </TableCell>
                  <TableCell>{row?.denomination}</TableCell>
                  <TableCell>{row?.quantity}</TableCell>
                  <TableCell>
                    {row?.createdAt
                      ? dayjs(row?.createdAt).format("MM-DD-YYYY ")
                      : ""}
                  </TableCell>
                  <TableCell>{row?.brandProductCode}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box
        sx={{
          bgcolor: "mute.dark",
          borderRadius: 1,
          color: "dark.text",
          p: 2,
          mt: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography>
            {(currentPage - 1) * countPerPage + 1} -{" "}
            {Math.min(currentPage * countPerPage, allOrders.length)} of{" "}
            {allOrders.length}
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography> Rows per page:</Typography>
          <select
            style={{
              marginTop: "-5px",
              background: "transparent",
              border: "none",
            }}
            value={countPerPage}
            onChange={(e) => setCountPerPage(parseInt(e.target.value))}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="30">30</option>
            <option value="50">50</option>
          </select>
          <Box sx={{ ml: 2 }}>
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box>
                <IconButton
                  onClick={() =>
                    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
                  }
                  disabled={currentPage === 1}
                  sx={{
                    border: "1px solid",
                    borderRadius: "10px",
                    borderColor: "mute.main",
                    bgcolor: "white.main",
                    boxShadow: "2px 2px 0px #999",
                    background: "#fff",
                  }}
                >
                  <KeyboardArrowLeftIcon
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: currentPage === 1 ? "#999" : "#000",
                    }}
                  />
                </IconButton>
              </Box>
              <Box>
                <Typography variant="text" component={"span"}>
                  {currentPage}/{Math.ceil(allOrders.length / countPerPage)}
                </Typography>
              </Box>
              <Box>
                <IconButton
                  onClick={() =>
                    setCurrentPage((prevPage) =>
                      Math.min(
                        prevPage + 1,
                        Math.ceil(allOrders.length / countPerPage)
                      )
                    )
                  }
                  disabled={
                    currentPage === Math.ceil(allOrders.length / countPerPage)
                  }
                  sx={{
                    border: "1px solid",
                    borderRadius: "10px",
                    borderColor: "mute.main",
                    bgcolor: "white.main",
                    boxShadow: "2px 2px 0px #999",
                    background: "#fff",
                  }}
                >
                  <KeyboardArrowRightIcon
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: "#000",
                    }}
                  />
                </IconButton>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="order-modal-title"
        aria-describedby="order-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { md: "40%", xs: "80%" },
            bgcolor: "background.paper",
            borderRadius: "2rem",
            boxShadow: 24,
            px: 4,
            py: 2,
            outline: "none",
          }}
        >
          <Typography
            textAlign={"center"}
            pb={4}
            id="order-modal-title"
            variant="h6"
            component="h2"
          >
            Order Details
          </Typography>
          {selectedOrder && (
            <>
              {" "}
              <Box sx={{ textAlign: "center", width: "100%" }}>
                {" "}
                <img
                  src={selectedOrder.user?.pictureUrl}
                  alt="User"
                  height={100}
                  width={100}
                  style={{ borderRadius: "50%" }}
                />
              </Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Phone</TableCell>
                      <TableCell>{selectedOrder?.user?.phone}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>{selectedOrder?.user?.username}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell>{selectedOrder?.user?.email}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Reward Point</TableCell>
                      <TableCell>
                        {selectedOrder?.user?.rewardPoint?.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
      </Modal>
    </HomeLayout>
  );
};
