import { combineReducers } from 'redux';
import { allUser, userReducer } from './userReducer';

const rootReducer = combineReducers({
  
  user: userReducer,
  allUser: allUser,
  
});

export default rootReducer;
