import React, { useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import HomeLayout from "../../asset/layout/HomeLayout";
import { BrandManagement } from "./BrandManagement";
import PremiumCardsManagement from "./PremiumCardsManagement";

export default function Brand() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <HomeLayout>
      <Typography variant="h4" my={3}>
        Brand Management
      </Typography>
      <BrandManagement />
    </HomeLayout>
  );
}
