import React, { useEffect, useState } from "react";
import HomeLayout from "../../asset/layout/HomeLayout";
import {
  Box,
  Grid,
  Stack,
  Typography,
  Card,
  CircularProgress,
  IconButton,
  Autocomplete,
  TextField,
} from "@mui/material";
import CustomButton from "../../components/mui/CustomButton";
import { Add, SignalWifiStatusbarNullTwoTone } from "@mui/icons-material";
import { BannerCard } from "../../components/Cards/BannerCard";
import RModal from "../../components/mui/RModal";
import InputField1 from "../../components/mui/InputField1";
import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import {
  addBanner,
  deleteBanner,
  getAllAdminBanner,
  updateBanner,
} from "../../actions/bannerAuth";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import DropDownMenu from "../../components/mui/DropDownMenu";
import { getAllCategory } from "../../actions/brandsAuth";
import { imageUpload } from "../../actions/userAuth";
const TicketStatus = ["Home Page", "Category Page"];

export const BannerManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [getBanner, setGetBanner] = useState([]);
  const { user } = useSelector((state) => ({ ...state }));
  const dispatch = useDispatch();
  const [bannerId, setBannerId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);
  const [allBanner, setAllBanner] = useState(10);
  const [uploadedImage, setUploadedImage] = useState("");
  const [uploadedMobileImage, setUploadedMobileImage] = useState("");
  const [ticketStatusEdit, setTicketStatusEdit] = useState("");
  const [allCategories, setAllCategories] = useState([]);

  const [formData, setFormData] = useState({
    file: null,
    bannerName: "",
    description: "",
    location: "",
    redirectUrl: "",
  });

  const [editFormData, setEditFormData] = useState({
    file: null,
    bannerName: "",
    description: "",
    location: "",
    redirectUrl: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    console.log("User ID:", user);
  }, [user]);

  const userId = user.id;

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFormData({
      file: null,
      bannerName: "",
      description: "",
      location: "",
      redirectUrl: "",
    });
    setUploadedImage(null);
    setUploadedMobileImage(null);
  };

  const openEditModal = (banner) => {
    setIsEditModalOpen(true);
    setBannerId(banner._id);
    setUploadedImage(banner.pictureUrl);

    setEditFormData({
      file: null,
      bannerName: banner.bannerName,
      description: banner.description,
      location: banner.location,
      redirectUrl: banner.redirectUrl,
    });
    setUploadedImage(banner.desktopBannerUrl);
    setUploadedMobileImage(banner.mobileBannerUrl);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setUploadedImage(null);
    setUploadedMobileImage(null);
  };

  const getAllBanner = () => {
    setLoading(true);
    dispatch(
      getAllAdminBanner(
        { page: currentPage, perPage: countPerPage },
        userId,
        (data) => {
          console.log(data.data.data);
          setGetBanner(data.data.data.banners);
          setAllBanner(data.data.results);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          console.log(err);
        }
      )
    );
  };

  useEffect(() => {
    setLoading(true);
    getAllBanner();
  }, [dispatch, countPerPage, currentPage]);

  // const handleFileChange = (e, isEdit = false) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       if (isEdit) {
  //         setUploadedImage(reader.result);
  //         setEditFormData((prevState) => ({
  //           ...prevState,
  //           file,
  //         }));
  //       } else {
  //         setUploadedImage(reader.result);
  //         setFormData((prevState) => ({
  //           ...prevState,
  //           file,
  //         }));
  //       }
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };
  const uploadImage = (e, setState) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append("file", file);
      dispatch(
        imageUpload(
          formData,
          (data) => {
            console.log(data?.data?.data);

            setState(data.data.data.publicUrl);
          },
          (err) => {
            enqueueSnackbar("Error Occured! Please try again", {
              variant: "error",
            });
          }
        )
      );
    }
  };

  const handleDataChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEditDataChange = (name, value) => {
    setEditFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    // const data = new FormData();
    // // data.append("file", formData.file);
    // data.append("bannerName", formData.bannerName);
    // data.append("description", formData.description);
    // data.append("location", formData.location);
    // data.append("redirectUrl", formData.redirectUrl);

    const payload = {
      bannerName: formData.bannerName,
      description: formData.description,
      location: formData.location,
      redirectUrl: formData.redirectUrl,
      mobileBannerUrl: uploadedMobileImage,
      desktopBannerUrl: uploadedImage,
    };
    dispatch(
      addBanner(
        userId,
        payload,
        (Response) => {
          enqueueSnackbar("Data submitted successfully", {
            variant: "success",
          });
          console.log("The response of the post request is", Response);
          setLoading(false);
          closeModal();
          getAllBanner();
        },
        (error) => {
          enqueueSnackbar("An error occurred", {
            variant: "error",
          });
          setLoading(false);
        }
      )
    );
  };

  const editHandleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    // const data = new FormData();
    // data.append("file", editFormData.file);
    // data.append("bannerName", editFormData.bannerName);
    // data.append("description", editFormData.description);
    // data.append("location", editFormData.location);
    // data.append("redirectUrl", editFormData.redirectUrl);
    const payload = {
      bannerName: editFormData.bannerName,
      description: editFormData.description,
      location: editFormData.location,
      redirectUrl: editFormData.redirectUrl,
      mobileBannerUrl: uploadedMobileImage,
      desktopBannerUrl: uploadedImage,
    };

    dispatch(
      updateBanner(
        userId,
        bannerId,
        payload,
        (Response) => {
          enqueueSnackbar("Data submitted successfully", {
            variant: "success",
          });
          console.log("The response of the post request is", Response);
          setLoading(false);
          closeEditModal();
          getAllBanner();
        },
        (error) => {
          enqueueSnackbar("An error occurred", {
            variant: "error",
          });
          setLoading(false);
        }
      )
    );
  };
  useEffect(() => {
    dispatch(
      getAllCategory(
        (data) => {
          console.log(data);
          setAllCategories(data.data.data.categories);
          console.log("data.data.data.categories", data.data.data.categories);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }, []);
  const handleDelete = (id) => {
    dispatch(
      deleteBanner(
        userId,
        id,
        {},
        (Response) => {
          enqueueSnackbar("Banner deleted successfully", {
            variant: "success",
          });
          console.log("The response of the post request is", Response);
          setLoading(false);
          getAllBanner();
        },
        (error) => {
          enqueueSnackbar("An error occurred", {
            variant: "error",
          });
          setLoading(false);
        }
      )
    );
  };

  const [secondaryOptions, setSecondaryOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleCategoryChange = (categoryLabel) => {
    console.log("Selected Category:", categoryLabel);
    setSelectedCategory(categoryLabel);
  };

  const renderFormFields = () => {
    if (selectedCategory === "Home Page") {
      return (
        <>
          <Grid item xs={12} sm={12}>
            <Typography variant="h5" mb={2} mt={1}>
              Select Home Banner
            </Typography>
            <DropDownMenu
              buttonText="Home Page Location"
              menuItems={[
                { label: "Top" },
                { label: "Below Category" },
                { label: "Below New Arrivals" },
              ]}
              selectedItem={formData.location}
              setSelectedItem={(label) => handleDataChange("location", label)}
              buttonWidth={"100%"}
            />
          </Grid>
        </>
      );
    } else if (selectedCategory === "Category Page") {
      return (
        <Grid item xs={12} sm={12}>
          <Typography variant="h5" mb={2} mt={1}>
            Select Category
          </Typography>
          <Autocomplete
            id="combo-box-demo"
            value={formData.category}
            onChange={(event, newValue) => {
              handleDataChange("category", newValue);
            }}
            options={allCategories}
            getOptionLabel={(option) => option.CategoryName}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Category"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      );
    } else {
      return null;
    }
  };

  const renderEditFormFields = () => {
    if (selectedCategory === "Home Page") {
      return (
        <>
          <Grid item xs={12} sm={12}>
            <Typography variant="h5" mb={2} mt={1}>
              Select Home Banner
            </Typography>
            <DropDownMenu
              buttonText="Home Page Location"
              menuItems={[
                { label: "Top" },
                { label: "Below Category" },
                { label: "Below New Arrivals" },
              ]}
              selectedItem={editFormData.location}
              setSelectedItem={(label) =>
                handleEditDataChange("location", label)
              }
              buttonWidth={"100%"}
            />
          </Grid>
        </>
      );
    } else if (selectedCategory === "Category Page") {
      return (
        <Grid item xs={12} sm={12}>
          <Typography variant="h5" mb={2} mt={1}>
            Select Category
          </Typography>
          <Autocomplete
            id="combo-box-demo"
            value={editFormData.category}
            onChange={(event, newValue) => {
              handleEditDataChange("category", newValue);
            }}
            options={allCategories.map((category) => category.categoryName)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Category"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      );
    } else {
      return null;
    }
  };

  return (
    <HomeLayout>
      {/* button & typo section */}
      <Stack alignItems="center" direction={"row"}>
        <Typography
          variant="h4"
          my={3}
          flexGrow={1}
          fontSize={{ xl: "22px", sm: "1rem", xs: "1rem" }}
        >
          Banner Management
        </Typography>

        <CustomButton
          onClick={openModal}
          variant="contained"
          fontSize={{ xl: "1rem", sm: ".7rem", xs: ".7rem" }}
        >
          {loading ? (
            <CircularProgress size={24} sx={{ color: "#FFFFFF" }} />
          ) : (
            <>
              <Add sx={{ mr: { xl: 1, sm: 0.2 } }} />
              Add Banner
            </>
          )}
        </CustomButton>
      </Stack>

      {/* card for banner img */}
      {loading ? (
        <Box align="center" my={5}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Grid container spacing={3}>
            {getBanner.map((banner) => (
              <Grid item key={banner.id} xl={2.4} md={4} sm={4} xs={6}>
                <BannerCard
                  username={banner?.bannerName}
                  image={banner?.desktopBannerUrl}
                  dis={banner?.description}
                  onEdit={() => openEditModal(banner)}
                  onDelete={() => handleDelete(banner._id)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      <Box
        sx={{
          bgcolor: "mute.dark",
          borderRadius: 1,
          color: "dark.text",
          p: 2,
          mt: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography>
            {(currentPage - 1) * countPerPage + 1} -{" "}
            {countPerPage > allBanner ? allBanner : countPerPage * currentPage}{" "}
            of {allBanner}
          </Typography>
        </Box>

        <Box>
          <IconButton
            onClick={() => {
              setCurrentPage((page) => Math.max(page - 1, 1));
            }}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setCurrentPage((page) => page + 1);
            }}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Box>
      </Box>

      <RModal
        open={isModalOpen}
        handleClose={closeModal}
        width={"80%"}
        height={{ xl: "80%", md: "80%", sm: "80%", xs: "80%" }}
      >
        <Box>
          <Typography variant="h6" gutterBottom>
            Add New Banner
          </Typography>
          <form onSubmit={handleSubmit} style={{}}>
            <Stack direction={"row"} spacing={2}>
              <input
                accept="image/*"
                type="file"
                onChange={(e) => uploadImage(e, setUploadedImage)}
                id="changDesktop"
                style={{
                  display: "none",
                  width: "20%",
                }}
              />
              <Box sx={{ width: "8.5rem", textAlign: "center" }}>
                {" "}
                <label htmlFor="changDesktop">
                  {uploadedImage ? (
                    <Card
                      sx={{
                        borderRadius: "1.25rem",
                        height: "8rem",
                        width: "8rem",
                        boxShadow: "none",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        bgcolor: "rgba(237, 242, 246, 1)",
                        mt: 2,
                        cursor: "pointer",
                      }}
                      style={{
                        border: "1px solid red !important",
                      }}
                    >
                      <img
                        src={uploadedImage}
                        alt="Preview"
                        style={{
                          width: "100%",
                          maxHeight: "200px",
                        }}
                      />
                    </Card>
                  ) : (
                    <Box
                      sx={{
                        width: "8rem",
                      }}
                    >
                      {" "}
                      <Card
                        sx={{
                          borderRadius: "1.25rem",
                          height: "8rem",
                          width: "8rem",
                          boxShadow: "none",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          bgcolor: "rgba(237, 242, 246, 1)",
                          mt: 2,
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img alt="Banner" src="/addimgcard.svg" />
                        </Box>
                        <Typography
                          mx={1}
                          textAlign={"center"}
                          variant="p"
                          sx={{
                            fontSize: ".9rem",
                          }}
                        >
                          Upload your Banner image
                        </Typography>
                      </Card>
                    </Box>
                  )}
                </label>
                <Typography variant="p" sx={{ mt: 2, textAlign: "center" }}>
                  Desktop Banner
                </Typography>
              </Box>
              <input
                accept="image/*"
                type="file"
                onChange={(e) => uploadImage(e, setUploadedMobileImage)}
                id="changMobile"
                style={{
                  display: "none",
                  width: "20%",
                }}
              />
              <Box sx={{ width: "8.5rem", textAlign: "center" }}>
                {" "}
                <label htmlFor="changMobile">
                  {uploadedMobileImage ? (
                    <Card
                      sx={{
                        borderRadius: "1.25rem",
                        height: "8rem",
                        width: "8rem",
                        boxShadow: "none",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        bgcolor: "rgba(237, 242, 246, 1)",
                        mt: 2,
                        cursor: "pointer",
                      }}
                      style={{
                        border: "1px solid red !important",
                      }}
                    >
                      <img
                        src={uploadedMobileImage}
                        alt="Preview"
                        style={{
                          width: "100%",
                          maxHeight: "200px",
                        }}
                      />
                    </Card>
                  ) : (
                    <Box
                      sx={{
                        width: "8rem",
                      }}
                    >
                      {" "}
                      <Card
                        sx={{
                          borderRadius: "1.25rem",
                          height: "8rem",
                          width: "8rem",
                          boxShadow: "none",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          bgcolor: "rgba(237, 242, 246, 1)",
                          mt: 2,
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img alt="Banner" src="/addimgcard.svg" />
                        </Box>
                        <Typography
                          mx={1}
                          textAlign={"center"}
                          variant="p"
                          sx={{
                            fontSize: ".9rem",
                          }}
                        >
                          Upload your Banner image
                        </Typography>
                      </Card>
                    </Box>
                  )}
                </label>{" "}
                <Typography variant="p">Mobile Banner</Typography>
              </Box>
            </Stack>
            <Grid container spacing={{ md: 2, sm: 0, xs: 0 }} mt={2}>
              <Grid item md={6} xs={12} sm={12}>
                <InputField1
                  label="Banner Name"
                  value={formData.bannerName}
                  placeholder={"Enter Banner Name"}
                  onChange={(e) =>
                    handleDataChange("bannerName", e.target.value)
                  }
                  required
                />
              </Grid>
              <Grid item md={6} xs={12} sm={12}>
                {" "}
                <InputField1
                  label="Description"
                  value={formData.description}
                  placeholder={"Enter description"}
                  onChange={(e) =>
                    handleDataChange("description", e.target.value)
                  }
                  required
                />
              </Grid>
              {/* <Grid item md={6} xs={12} sm={12}>
                <InputField1
                  label="Location"
                  placeholder={"Enter Location"}
                  value={formData.location}
                  onChange={(e) => handleDataChange("location", e.target.value)}
                  required
                />
              </Grid>{" "} */}
              <Grid item md={6} xs={12} sm={12}>
                <InputField1
                  label="Redirect URL"
                  value={formData.redirectUrl}
                  onChange={(e) =>
                    handleDataChange("redirectUrl", e.target.value)
                  }
                  required
                />
              </Grid>
              <Grid item md={6} xs={12} sm={12}>
                <Typography variant="h5" mb={2} mt={1} pl={2}>
                  Banner Category
                </Typography>
                <DropDownMenu
                  sx={{ ml: 2 }}
                  buttonText="Category"
                  menuItems={TicketStatus.map((status) => ({
                    label: status,
                  }))}
                  selectedItem={selectedCategory}
                  setSelectedItem={handleCategoryChange}
                  buttonWidth={"100%"}
                />
              </Grid>
              <Grid item md={6} xs={12} sm={12}>
                {" "}
                {renderFormFields()}
              </Grid>
              {/* <Grid item md={6} xs={12} sm={12}>
                {ticketStatusEdit && (
                  <DropDownMenu
                    buttonText='Secondary Options'
                    menuItems={secondaryOptions.map(
                      (option) => ({
                        label: option,
                      })
                    )}
                    selectedItem={null}
                    setSelectedItem={() => {}}
                    buttonWidth={'100%'}
                  />
                )}
              </Grid> */}
            </Grid>

            <Box mt={2}>
              <CustomButton
                type="submit"
                variant="contained"
                fullWidth
                width={"9rem"}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "#FFFFFF" }} />
                ) : (
                  "Submit"
                )}
              </CustomButton>
            </Box>
          </form>
        </Box>
      </RModal>

      <RModal
        open={isEditModalOpen}
        handleClose={closeEditModal}
        width={"80%"}
        height={{ xl: "80%", md: "80%", sm: "80%", xs: "80%" }}
      >
        <Box>
          <Typography variant="h6" gutterBottom>
            update Banner
          </Typography>{" "}
          <Typography mt={0.5} variant="h5" color={"rgba(76, 83, 95, 1)"}>
            Upload Banner Image
          </Typography>
          <form onSubmit={editHandleSubmit}>
            <Stack direction={"row"} spacing={2}>
              <input
                accept="image/*"
                type="file"
                onChange={(e) => uploadImage(e, setUploadedImage)}
                id="changDesktop"
                style={{
                  display: "none",
                  width: "20%",
                }}
              />
              <Box sx={{ width: "8.5rem", textAlign: "center" }}>
                {" "}
                <label htmlFor="changDesktop">
                  {uploadedImage ? (
                    <Card
                      sx={{
                        borderRadius: "1.25rem",
                        height: "8rem",
                        width: "8rem",
                        boxShadow: "none",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        bgcolor: "rgba(237, 242, 246, 1)",
                        mt: 2,
                        cursor: "pointer",
                      }}
                      style={{
                        border: "1px solid red !important",
                      }}
                    >
                      <img
                        src={uploadedImage}
                        alt="Preview"
                        style={{
                          width: "100%",
                          maxHeight: "200px",
                        }}
                      />
                    </Card>
                  ) : (
                    <Box
                      sx={{
                        width: "8rem",
                      }}
                    >
                      {" "}
                      <Card
                        sx={{
                          borderRadius: "1.25rem",
                          height: "8rem",
                          width: "8rem",
                          boxShadow: "none",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          bgcolor: "rgba(237, 242, 246, 1)",
                          mt: 2,
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img alt="Banner" src="/addimgcard.svg" />
                        </Box>
                        <Typography
                          mx={1}
                          textAlign={"center"}
                          variant="p"
                          sx={{
                            fontSize: ".9rem",
                          }}
                        >
                          Upload your Banner image
                        </Typography>
                      </Card>
                    </Box>
                  )}
                </label>
                <Typography variant="p" sx={{ mt: 2, textAlign: "center" }}>
                  Desktop Banner
                </Typography>
              </Box>
              <input
                accept="image/*"
                type="file"
                onChange={(e) => uploadImage(e, setUploadedMobileImage)}
                id="changMobile"
                style={{
                  display: "none",
                  width: "20%",
                }}
              />
              <Box sx={{ width: "8.5rem", textAlign: "center" }}>
                {" "}
                <label htmlFor="changMobile">
                  {uploadedMobileImage ? (
                    <Card
                      sx={{
                        borderRadius: "1.25rem",
                        height: "8rem",
                        width: "8rem",
                        boxShadow: "none",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        bgcolor: "rgba(237, 242, 246, 1)",
                        mt: 2,
                        cursor: "pointer",
                      }}
                      style={{
                        border: "1px solid red !important",
                      }}
                    >
                      <img
                        src={uploadedMobileImage}
                        alt="Preview"
                        style={{
                          width: "100%",
                          maxHeight: "200px",
                        }}
                      />
                    </Card>
                  ) : (
                    <Box
                      sx={{
                        width: "8rem",
                      }}
                    >
                      {" "}
                      <Card
                        sx={{
                          borderRadius: "1.25rem",
                          height: "8rem",
                          width: "8rem",
                          boxShadow: "none",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          bgcolor: "rgba(237, 242, 246, 1)",
                          mt: 2,
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img alt="Banner" src="/addimgcard.svg" />
                        </Box>
                        <Typography
                          mx={1}
                          textAlign={"center"}
                          variant="p"
                          sx={{
                            fontSize: ".9rem",
                          }}
                        >
                          Upload your Banner image
                        </Typography>
                      </Card>
                    </Box>
                  )}
                </label>{" "}
                <Typography variant="p">Mobile Banner</Typography>
              </Box>
            </Stack>

            <Grid container spacing={{ md: 1, sm: 0, xs: 0 }}>
              <Grid item md={6} xs={12}>
                {" "}
                <InputField1
                  label="Banner Name"
                  value={editFormData.bannerName}
                  onChange={(e) =>
                    handleEditDataChange("bannerName", e.target.value)
                  }
                  required
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {" "}
                <InputField1
                  label="Description"
                  value={editFormData.description}
                  onChange={(e) =>
                    handleEditDataChange("description", e.target.value)
                  }
                  required
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {" "}
                <InputField1
                  label="Position"
                  value={editFormData.location}
                  onChange={(e) =>
                    handleEditDataChange("location", e.target.value)
                  }
                  required
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {" "}
                <InputField1
                  label="Redirect URL"
                  value={editFormData.redirectUrl}
                  onChange={(e) =>
                    handleEditDataChange("redirectUrl", e.target.value)
                  }
                  required
                />
              </Grid>
            </Grid>

            <Box mt={2}>
              <CustomButton
                type="submit"
                variant="contained"
                fullWidth
                width={"9rem"}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "#FFFFFF" }} />
                ) : (
                  "Submit"
                )}
              </CustomButton>
            </Box>
          </form>
        </Box>
      </RModal>
    </HomeLayout>
  );
};
