import React from "react";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";

const data = [
  {
    id: 1,
    description: "deposite",
    transactionId: "123456",
    type: "Debit",
    card: "123456789",
    date: "2022-01-01",
    amount: "$50.00",
    receipt: "Link to Receipt",
  },
  {
    id: 2,
    description: "deposite",
    transactionId: "123456",
    type: "Debit",
    card: "123456789",
    date: "2022-01-01",
    amount: "$50.00",
    receipt: "Link to Receipt",
  },
  {
    id: 3,
    description: "Purchase",
    transactionId: "123456",
    type: "Debit",
    card: "123456789",
    date: "2022-01-01",
    amount: "$50.00",
    receipt: "Link to Receipt",
  },
  {
    id: 4,
    description: "Purchase",
    transactionId: "123456",
    type: "Debit",
    card: "123456789",
    date: "2022-01-01",
    amount: "$50.00",
    receipt: "Link to Receipt",
  },
  {
    id: 5,
    description: "withdraw",
    transactionId: "123456",
    type: "Debit",
    card: "123456789",
    date: "2022-01-01",
    amount: "$50.00",
    receipt: "Link to Receipt",
  },
  {
    id: 6,
    description: "withdraw",
    transactionId: "123456",
    type: "Debit",
    card: "123456789",
    date: "2022-01-01",
    amount: "$50.00",
    receipt: "Link to Receipt",
  },
  {
    id: 7,
    description: "withdraw",
    transactionId: "123456",
    type: "Debit",
    card: "123456789",
    date: "2022-01-01",
    amount: "$50.00",
    receipt: "Link to Receipt",
  },
];

const columns = [
  "Transaction ID",
  "Customer Email",
  "Customer Number",
  "Date",
  "Status",
  "Amount",
  // 'Receipt',
];

const MyTable = ({ transactions }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ borderRadius: "25px", overflow: "auto" }}
    >
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column}
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#11409B",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            whiteSpace: "nowrap",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          {transactions?.map((row) => (
            <TableRow
              key={row.id}
              sx={{
                whiteSpace: "nowrap",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              <TableCell>{row?.orderId}</TableCell>
              <TableCell>
                {row?.customerDetails?.customerId?.email
                  ? row?.customerDetails?.customerId?.email
                  : "--"}
              </TableCell>
              <TableCell>
                +91{row?.customerDetails?.customerId?.phone
                  ? row?.customerDetails?.customerId?.phone
                  : "--"}
              </TableCell>
              <TableCell>
                {row?.createdAt
                  ? dayjs(row?.createdAt).format("MM-DD-YYYY ")
                  : ""}
              </TableCell>
              <TableCell>
                <Typography
                  sx={{
                    color:
                      row?.orderStatus === "PAID"
                        ? "green"
                        : row?.orderStatus === "ACTIVE"
                        ? "#D6B85A"
                        : row?.orderStatus === "FAILED"
                        ? "red"
                        : "inherit",
                    textTransform: "capitalize",
                  }}
                >
                  {row?.orderStatus?.toLowerCase()}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  color: row.description === "withdraw" ? "red" : "green",
                }}
              >
                {row.description === "withdraw" ? "-" : "+"}
                {row.orderAmount / 100}
              </TableCell>
              {/* <TableCell>
                <Button
                  variant='outlined'
                  sx={{ borderRadius: '50px', textTransform: 'none' }}
                >
                  Download
                </Button>
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MyTable;
