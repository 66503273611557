import axios from "axios";
import { deleteAPI, getAPI, patchAPI, postAPI, putAPI } from "../utils/api";
import endpoints from "../utils/endpoints";

export const login = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoints?.SIGNIN,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const verifyOtp = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoints?.VERIFY_OTP,
      { otp: payload.otp },
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      payload.token
    );
  };
};

export const getAllUsers = (callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endpoints?.GET_ALL_USERS,
      {},
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem("token")
    );
  };
};
export const getAllAdminUsers = (callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endpoints?.GET_ALL_USERS,
      {},
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem("token")
    );
  };
};
export const getAllCampaignLeads = (callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endpoints?.GET_ALL_CAMPAIGN_LEADS,
      {},
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem("token")
    );
  };
};
export const getAllBudgetreeLeads = (callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endpoints?.GET_ALL_BUDGETREE_LEADS,
      {},
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem("token")
    );
  };
};

export const updateUserStatusById = (id, payload, callBack, errorCallback) => {
  return (dispatch) => {
    patchAPI(
      endpoints?.GET_ALL_USERS + "/" + id,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      localStorage.getItem("token")
    );
  };
};

export const userLogout = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoints?.LOGOUT,
      {},
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      payload.token
    );
  };
};
export const getUserByToken = (callBack, errorCallback, manualToken) => {
  return (dispatch) => {
    getAPI(
      endpoints?.GET_USER_BY_TOKEN,
      {},
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      manualToken
    );
  };
};
export const getAllUsersClient = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      payload.page && payload.perPage
        ? `${endpoints?.GET_ALL_USERS}?page=${payload.page}&perPage=${payload.perPage}`
        : endpoints?.GET_ALL_USERS,

      {},
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem("token")
    );
  };
};
export const applyPermissions = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    patchAPI(
      endpoints?.APPLY_PERMISSIONS,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      localStorage.getItem("token")
    );
  };
};
export const addBrands = (id, payload, callBack, errorCallback) => {
  return (dispatch) => {
    patchAPI(
      endpoints?.ADD_BRANDS + "/" + id,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      localStorage.getItem("token")
    );
  };
};
export const imageUpload = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoints?.IMAGE_UPLOAD,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};
