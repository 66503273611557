import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  FormControlLabel,
  Box,
  Stack,
  IconButton,
  CircularProgress,
  Grid,
} from "@mui/material";
import HomeLayout from "../../asset/layout/HomeLayout";
import IOSSwitch from "../../style/globalStyles";
import SearchBar from "../../components/mui/SearchBar";
import { useDispatch } from "react-redux";
import {
  getAllUsers,
  getAllUsersClient,
  updateUserStatusById,
} from "../../actions/userAuth";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/mui/CustomButton";
import ResponsiveDatePickers from "../../components/mui/PichDate";
import * as XLSX from "xlsx";
import { getClientByToken } from "../../actions/clientsAuth";

const columns = [
  "Name",
  "Email",
  "GST Status",
  "Company Name",
  "GST Number",
  "Company Type",
  "CIN Number",
  "Company Pan Card Number",
  "Trade Name",
  "Director Pan Card Number",
  "Director Aadhaar Number",
  "Office Address",
];

export const ClientManagement = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [allUserData, setAllUserData] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const getData = () => {
    setLoading(true);
    dispatch(
      getClientByToken(
        (data) => {
          const filteredData = data.data.data.filter((item) => {
            const matchQuery =
              item?.firstName
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase()) ||
              item?.lastName
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase()) ||
              item?.phone?.includes(searchTerm) ||
              item?.email?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
              item?.companyName
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase()) ||
              item?.companyType
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase()) ||
              item?.cinNumber
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase()) ||
              item?.officeAddress
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase()) ||
              item?.directorAdhaarNumber
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase()) ||
              item?.directorPanCardNumber
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase()) ||
              item?.tradeName
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase()) ||
              item?.companyPanCardNumber
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase()) ||
              item?.gst?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
              item?.gstStatus
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase());

            const matchDateRange =
              (!selectedDateFrom ||
                new Date(item.updatedAt) >= new Date(selectedDateFrom)) &&
              (!selectedDateTo ||
                new Date(item.updatedAt) <= new Date(selectedDateTo));

            return matchQuery && matchDateRange;
          });

          filteredData.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          dispatch({ type: "ALL_USER", payload: filteredData });
          setAllUserData(filteredData);

          setLoading(false);
        },
        (error) => {
          console.log("getting error ");
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    getData();
  }, [searchTerm, countPerPage, currentPage]);

  const updateUserStatus = (id, status) => {
    let payload = {
      active: status,
    };
    dispatch(
      updateUserStatusById(
        id,
        payload,
        (data) => {
          enqueueSnackbar("Status Updated!", {
            variant: "success",
          });
          getData();
        },
        (error) => {
          enqueueSnackbar(error?.response?.data?.message, {
            variant: "error",
          });
          console.log(error);
        }
      )
    );
  };

  const handleSearchClear = () => {
    setSearchTerm("");
    setSelectedDateFrom(null);
    setSelectedDateTo(null);
  };

  const paginatedData = allUserData.slice(
    (currentPage - 1) * countPerPage,
    currentPage * countPerPage
  );

  const handleExport = () => {
    const formattedData = allUserData.map((user) => ({
      Username: user.username,
      Email: user.email,
      Phone: user.phone,
      Role: user.role,
      "Last Login Date": new Date(user.lastLogin).toLocaleDateString("en-IN"),
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Users");

    XLSX.writeFile(wb, "Clients.xlsx");
  };

  const handleClientNameClick = (id) => {
    navigate(`/client-detail/${id}`);
  };

  return (
    <HomeLayout>
      <Stack alignItems="center" direction={"row"}>
        <Typography
          variant="h4"
          my={3}
          flexGrow={1}
          fontSize={{ xl: "22px", sm: "1rem", xs: "1rem" }}
        >
          Client Management
        </Typography>

        <Box pr={2}>
          <CustomButton
            variant="contained"
            fontSize={{ xl: "1rem", sm: ".7rem", xs: ".7rem" }}
            onClick={handleExport}
          >
            Export Clients
          </CustomButton>
        </Box>
      </Stack>
      <Box>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Grid item xs={4}>
            <SearchBar onSearch={handleSearch} searchTerm={searchTerm} />
          </Grid>

          {/* <Grid item md={3} xs={12}>
            <ResponsiveDatePickers
              selectedDate={selectedDateFrom}
              setSelectedDate={setSelectedDateFrom}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ResponsiveDatePickers
              selectedDate={selectedDateTo}
              setSelectedDate={setSelectedDateTo}
            />
          </Grid> */}
          <Grid item md={2} xs={12}>
            <CustomButton
              variant="contained"
              onClick={handleSearchClear}
              width={"100%"}
              height={"2.5rem"}
            >
              Clear
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : paginatedData.length > 0 ? (
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "25px",
            overflowX: "scroll",
            my: 3,
            cursor: "pointer",
            // "&::-webkit-scrollbar": {
            //   display: "none",
            // },
            // "-ms-overflow-style": "none",
            // "scrollbar-width": "none",
          }}
        >
          <Table sx={{ maxWidth: "auto" }}>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#11409B",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                whiteSpace: "nowrap",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              {paginatedData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    onClick={() => handleClientNameClick(row?._id)}
                    sx={{ cursor: "pointer", color: "blue" }}
                  >
                    {row?.firstName ? row?.firstName : "--"}{" "}
                    {row?.lastName ? row?.lastName : "--"}
                  </TableCell>
                  <TableCell>{row?.email ? row?.email : "--"}</TableCell>
                  <TableCell>
                    {row?.gstStatus ? row?.gstStatus : "--"}
                  </TableCell>
                  <TableCell>
                    {row?.companyName ? row?.companyName : "--"}
                  </TableCell>
                  <TableCell>{row?.gst ? row?.gst : "--"}</TableCell>
                  <TableCell>
                    {row?.companyType ? row?.companyType : "--"}
                  </TableCell>

                  <TableCell>
                    {row?.cinNumber ? row?.cinNumber : "--"}
                  </TableCell>
                  <TableCell>
                    {row?.companyPanCardNumber
                      ? row?.companyPanCardNumber
                      : "--"}
                  </TableCell>
                  <TableCell>{row?.tradeName}</TableCell>
                  <TableCell>{row?.directorPanCardNumber}</TableCell>
                  <TableCell>{row?.directorAdhaarNumber}</TableCell>
                  <TableCell>{row?.officeAddress}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1" sx={{ textAlign: "center", my: 3 }}>
          No results found.
        </Typography>
      )}
      <Box
        sx={{
          bgcolor: "mute.dark",
          borderRadius: 1,
          color: "dark.text",
          p: 2,
          mt: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography>
            {(currentPage - 1) * countPerPage + 1} -{" "}
            {Math.min(currentPage * countPerPage, allUserData.length)} of{" "}
            {allUserData.length}
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography> Rows per page:</Typography>
          <select
            style={{
              marginTop: "-5px",
              background: "transparent",
              border: "none",
            }}
            value={countPerPage}
            onChange={(e) => setCountPerPage(parseInt(e.target.value))}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="30">30</option>
            <option value="50">50</option>
          </select>
          <Box sx={{ ml: 3 }}>
            <IconButton
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <IconButton
              disabled={
                currentPage === Math.ceil(allUserData.length / countPerPage)
              }
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </HomeLayout>
  );
};

export default ClientManagement;
