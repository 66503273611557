import React, { useState } from 'react';
import Section from '../../components/Section';
import { Link } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { VisibilityOutlined } from '@mui/icons-material';

import LogoImg from '../../media/image/logo.png';

import { primaryColor, secondaryColor } from '../../style/color';
import InputField1 from '../../components/mui/InputField1';
import CustomButton from '../../components/mui/CustomButton';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordsMatchError, setPasswordsMatchError] = useState('');
  const [passwordValidationError, setPasswordValidationError] = useState('');
  // const [passwordsMatch, setPasswordsMatch] = useState(true);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    newEmail.trim() === ''
      ? setIsEmailValid(true)
      : (() => {
          const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          const isValidEmail = emailRegex.test(newEmail);
          setIsEmailValid(isValidEmail);
        })();
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);

    password !== e.target.value && password !== '' && e.target.value !== ''
      ? setPasswordsMatchError('Passwords do not match')
      : setPasswordsMatchError('');
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    newPassword.trim() !== ''
      ? handlePasswordValidation(newPassword)
      : setPasswordValidationError('');
  };

  const handlePasswordValidation = (password) => {
    const isValid =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
        password
      ) && password.length >= 8;

    isValid
      ? setPasswordValidationError('')
      : setPasswordValidationError(
          'Password must be at least 8 characters and contain at least one special character.'
        );

    return isValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!handlePasswordValidation(password)) {
      console.log('Error: Password validation failed');
      return;
    }
    const doPasswordsMatch = password === confirmPassword;

    if (isEmailValid || doPasswordsMatch) {
      console.log('Email:', email);
      console.log('Password:', password);
      console.log('Confirm Password:', confirmPassword);
    } else {
      console.error('Email or passwords do not match');
    }
  };

  return (
    <Section>
      <Container maxWidth={'xl'}>
        <Box mt={4}>
          {' '}
          <img
            src={LogoImg}
            sx={{
              height: { xl: '41.01px', md: '41.01px', xs: '20px' },
              width: '199px',
            }}
            alt='logo'
          />
        </Box>
        <Grid
          container
          spacing={1}
          alignItems={{
            xl: 'center',
            xs: 'flex-start',
            md: 'center',
            sm: 'flex-start',
          }}
          sx={{
            minHeight: 'calc(100vh - 100px)',
            marginTop: { xl: '-5%', xs: '-10%', md: '-10%', sm: '-5%' },
          }}
        >
          <Grid item md={5} xs={12}>
            <Box
              my={7}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                mx: {
                  xs: 0,
                  sm: 0,
                  md: 6,
                  lg: 6,
                  xl: 6,
                },
              }}
            >
              <Box sx={{ pb: 4 }}>
                <Typography variant='h1' component={'h2'}>
                  Sign Up
                </Typography>
                <Typography
                  variant='h5'
                  component={'p'}
                  mt={1}
                  sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                >
                  Please fill your detail to create your account.
                </Typography>
              </Box>
              <Box maxWidth={600} mx={'auto'}>
                <form onSubmit={handleSubmit}>
                  <Stack sx={{ width: '100%', padding: '0' }}>
                    <Stack>
                      <InputField1
                        label={'Email'}
                        placeholder={'Your Email'}
                        value={email}
                        onChange={handleEmailChange}
                        required
                        maxLength={50}
                      />
                    </Stack>
                    {!isEmailValid && (
                      <Stack>
                        {' '}
                        <Typography sx={{ color: '#d32f2f' }}>
                          Please enter a valid Email ID
                        </Typography>
                      </Stack>
                    )}
                    <Stack>
                      <InputField1
                        label={'Password'}
                        placeholder={'Enter your password'}
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={handlePasswordChange}
                        required
                        maxLength={20}
                        endIcon={
                          <InputAdornment position='end'>
                            <IconButton
                              onClick={handleTogglePasswordVisibility}
                            >
                              {showPassword ? (
                                <VisibilityOffOutlinedIcon />
                              ) : (
                                <VisibilityOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </Stack>
                    {passwordValidationError && (
                      <Stack>
                        <Typography color='error'>
                          {passwordValidationError}
                        </Typography>
                      </Stack>
                    )}
                    <Stack>
                      <InputField1
                        label={'Confirm Password'}
                        placeholder={'Enter your password'}
                        type={showConfirmPassword ? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        required
                        maxLength={20}
                        endIcon={
                          <InputAdornment position='end'>
                            <IconButton
                              onClick={handleToggleConfirmPasswordVisibility}
                            >
                              {showConfirmPassword ? (
                                <VisibilityOffOutlinedIcon />
                              ) : (
                                <VisibilityOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </Stack>
                    {passwordsMatchError && (
                      <Stack>
                        <Typography color='error'>
                          {passwordsMatchError}
                        </Typography>
                      </Stack>
                    )}
                    <Stack mt={4}>
                      <CustomButton
                        type='submit'
                        variant='contained'
                        fullWidth
                        color='primary'
                      >
                        Sign up
                      </CustomButton>
                    </Stack>
                    <Stack mt={4}>
                      <Typography
                        variant='p'
                        flexGrow={1}
                        sx={{
                          textAlign: 'center',
                          color: secondaryColor.main,
                          fontSize: 18,
                        }}
                      >
                        Already have an account?{' '}
                        <Link
                          to='/'
                          style={{
                            color: primaryColor.main,
                            textDecoration: 'none',
                          }}
                        >
                          Sign in
                        </Link>
                      </Typography>
                    </Stack>{' '}
                  </Stack>
                </form>
              </Box>
            </Box>
          </Grid>
          <Grid item md={7} sx={{ display: { md: 'block', xs: 'none' } }}>
            <Box
              sx={{
                my: 2,
                px: 5,
                py: 4,
                minHeight: 'calc(100vh - 200px)',

                display: 'flex',
                alignItems: 'center',
                img: { width: '100%', height: 'auto' },
                borderRadius: 3,
              }}
            >
              <Box>
                <img
                  src={'/signUp.svg'}
                  style={{
                    height: '100%',
                    width: '100%',
                    borderRadius: '3px',
                  }}
                  alt='Products'
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

export default SignUp;
