import React, { useState, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

const CustomDatePickerInput = forwardRef(({ value, onClick, width }, ref) => (
  <TextField
    ref={ref}
    value={value}
    sx={{
      '& .MuiInputBase-root': {
        height: '2.5rem',
        background: '#FFFFFF',
        color: '#878790',
        borderRadius: '7px',
        width: width || 'auto',
      },
    }}
    InputProps={{
      endAdornment: (
        <InputAdornment position='end'>
          <IconButton onClick={onClick} edge='end'>
            <CalendarTodayIcon />
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
));

const DatePickerComponent = ({ selectedDate, setSelectedDate }) => {
  const inputRef = React.createRef();
  return (
    <DatePicker
      ref={inputRef}
      dateFormat='MMMM d, yyyy'
      selected={selectedDate}
      onChange={(date) => setSelectedDate(date)}
      customInput={<CustomDatePickerInput ref={inputRef} />}
    />
  );
};

export default DatePickerComponent;
