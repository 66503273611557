import React, { useEffect, useState } from 'react';
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Button,
  Stack,
  FormGroup,
  FormControlLabel,
  Grid,
  Avatar,
  Badge,
} from '@mui/material';
import HomeLayout from '../../asset/layout/HomeLayout';
import IOSSwitch from '../../style/globalStyles';
import InputField1 from '../../components/mui/InputField1';
import { Container } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
const EditProfile = () => {
  const [value, setValue] = React.useState(0);
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [passwordValidationError, setPasswordValidationError] = useState('');
  const Navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      Navigate('/');
    }
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePasswordValidation = (password) => {
    const isValid =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
        password
      ) && password.length >= 8;

    isValid
      ? setPasswordValidationError('')
      : setPasswordValidationError(
          'Password must be at least 8 characters and contain at least one special character.'
        );

    return isValid;
  };

  const handleNewPasswordChange = (e) => {
    const newPasswordValue = e.target.value;

    setNewPassword(newPasswordValue);

    newPasswordValue.trim() !== ''
      ? handlePasswordValidation(newPasswordValue)
      : setPasswordValidationError('');
  };

  const handleCurrentPasswordChange = (e) => {
    const currentPasswordValue = e.target.value;
    setCurrentPassword(currentPasswordValue);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    console.log('Current Password:', currentPassword);
    console.log('New Password:', newPassword);
  };

  return (
    <Box>
      <HomeLayout>
        <Container
          p={2}
          sx={{
            borderRadius: '20px',
            background: 'white',
          }}
        >
          <form onSubmit={handleFormSubmit}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label='edit-profile-tabs'
            >
              <Tab label='Edit Profile' />
              <Tab label='Security' />
            </Tabs>

            {/* Edit Profile Content  */}
            <TabPanel value={value} index={0}>
              <Box py={3}>
                <Grid container spacing={2}>
                  <Grid
                    xl={2}
                    md={12}
                    sm={12}
                    xs={12}
                    item
                    sx={{
                      display: 'flex',

                      justifyContent: 'center',
                    }}
                  >
                    <Badge
                      badgeContent={
                        <Avatar
                          alt='Profile Edit'
                          src='/profileEditButton.svg'
                          sx={{
                            width: 40,
                            height: 40,
                            position: 'relative',
                            marginTop: {
                              xl: '4vh',
                              lg: '10vh',
                              md: '8vh',
                              xs: '12vh',
                              sm: '10vh',
                            },
                            right: '-1vh',
                          }}
                        />
                      }
                      overlap='circular'
                    >
                      <Avatar
                        alt='User Avatar'
                        src='/userAvatar.jpg'
                        sx={{ width: 130, height: 130 }}
                      />
                    </Badge>
                  </Grid>
                  <Grid xl={5} md={12} sm={12} xs={12} item>
                    <InputField1
                      label='Your name'
                      placeholder='Enter your name'
                      required
                      placeholderColor=' #718EBF '
                      maxLength={20}
                    ></InputField1>
                    <InputField1
                      label='Email'
                      placeholder='Enter your Email'
                      required
                      placeholderColor=' #718EBF '
                      maxLength={20}
                    ></InputField1>
                    <InputField1
                      label='Date of Birth'
                      placeholder='Enter your Date of Birth'
                      required
                      placeholderColor=' #718EBF '
                      maxLength={20}
                    ></InputField1>
                    <InputField1
                      label='Permanent Address'
                      placeholder='Enter your Permanent Address'
                      required
                      placeholderColor=' #718EBF '
                      maxLength={20}
                    ></InputField1>
                    <InputField1
                      label='Postal Code'
                      placeholder='Enter your Postal Code'
                      required
                      placeholderColor=' #718EBF '
                      maxLength={20}
                    ></InputField1>
                  </Grid>
                  <Grid xl={5} md={12} sm={12} xs={12} item>
                    {' '}
                    <InputField1
                      label='User Name'
                      placeholder='Enter your User Name'
                      required
                      placeholderColor=' #718EBF '
                      maxLength={20}
                    ></InputField1>{' '}
                    <InputField1
                      label='Password'
                      placeholder='Enter your Password'
                      required
                      type={'password'}
                      placeholderColor=' #718EBF '
                      maxLength={20}
                    ></InputField1>{' '}
                    <InputField1
                      label='Present Address'
                      placeholder='Enter your Present Address'
                      required
                      placeholderColor=' #718EBF '
                      maxLength={20}
                    ></InputField1>{' '}
                    <InputField1
                      label='City'
                      placeholder='Enter your City'
                      required
                      placeholderColor=' #718EBF '
                      maxLength={20}
                    ></InputField1>{' '}
                    <InputField1
                      label='Country'
                      placeholder='Enter your Country'
                      required
                      placeholderColor=' #718EBF '
                      maxLength={20}
                    ></InputField1>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: { xl: 'end', md: 'center' },
                    alignItems: 'center',
                    flex: '1',
                    width: '100%',
                    marginTop: '1rem',
                    marginBottom: '3rem',
                  }}
                >
                  <Button
                    type='submit'
                    variant='contained'
                    sx={{
                      textTransform: 'none',
                      borderRadius: '10px',
                      width: { xl: '8rem', md: '100%', xs: '100%', sm: '100%' },
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </TabPanel>

            {/* security tab */}
            <TabPanel value={value} index={1}>
              <Box py={4}>
                <Typography variant='h4' mt={3} color={'#333B69'}>
                  Two-Factor Identification
                </Typography>
                <Box
                  mt={3}
                  sx={{
                    width: {
                      xl: '50%',
                      lg: '50%',
                      md: '100%',
                      sm: '100%',
                      xs: '100%',
                    },
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                      label={
                        <Typography variant='p'>
                          Enable or disable two-factor authentication
                        </Typography>
                      }
                    />
                    <Typography variant='h6' my={1} color={'#333B69'}>
                      Change Password
                    </Typography>
                    <Stack>
                      <InputField1
                        type={'Password'}
                        label='Current Password'
                        placeholder='Enter your password'
                        required
                        placeholderColor=' #718EBF '
                        maxLength={20}
                        onChange={(e) => handleCurrentPasswordChange(e)}
                      />
                    </Stack>
                    <Stack>
                      <InputField1
                        label='New Password'
                        placeholder='Enter your new password'
                        required
                        placeholderColor=' #718EBF '
                        maxLength={20}
                        onChange={(e) => handleNewPasswordChange(e)}
                      />
                      {passwordValidationError && (
                        <Typography variant='p' color='error'>
                          {passwordValidationError}
                        </Typography>
                      )}
                    </Stack>
                  </FormGroup>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: { xl: 'end', md: 'center' },
                    alignItems: 'center',
                    flex: '1',
                    width: '100%',
                    marginTop: '1rem',
                    marginBottom: '3rem',
                  }}
                >
                  <Button
                    type='submit'
                    variant='contained'
                    sx={{
                      textTransform: 'none',
                      borderRadius: '10px',
                      width: { xl: '8rem', md: '100%', xs: '100%', sm: '100%' },
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </TabPanel>
          </form>
        </Container>
      </HomeLayout>
    </Box>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`edit-profile-tabpanel-${index}`}
      aria-labelledby={`edit-profile-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

export default EditProfile;
