import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
  Grid,
  Typography,
  CircularProgress,
  Autocomplete,
  TextField,
  Box,
  Stack,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import HomeLayout from "../../asset/layout/HomeLayout";
import { ArrowDropDownCircleOutlined, Check, Edit } from "@mui/icons-material";

import DropDownMenu from "../../components/mui/DropDownMenu";
import SearchBar from "../../components/mui/SearchBar";
import Paginations from "../../components/mui/Pagination";
import { useDispatch } from "react-redux";
import {
  getAllBrands,
  getAllCategory,
  updateBrand,
  updateCategory,
  uploadDocument,
} from "../../actions/brandsAuth";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { enqueueSnackbar } from "notistack";
import IOSSwitch from "../../style/globalStyles";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/mui/CustomButton";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
const columns = [
  " Brand Image",
  "Brand Name",
  "Siripay Discount",
  "Denomination List",
  "Brand Product Code",
  "Brand Type",
  "Stock Availability",
  "Date Added",

  "Category Name",
  "Active Staus",
  "Premium Status",
];

const menuItems = [{ label: "Deposit" }, { label: "Withdraw" }];
export const BrandManagement = () => {
  const [allbrands, setAllBrands] = useState(10);

  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);
  const [allcurrentPage, setAllCurrentPage] = useState(1);
  const [allcountPerPage, setAllCountPerPage] = useState();
  const [categories, setAllCategories] = useState([]);
  const [tempDiscountIndex, setTempDiscountIndex] = useState("");
  const [tempDiscount, setTempDiscount] = useState("");
  const [getAllData, setGetAllData] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const handleSearch = (term) => {
    setSearchTerm(term);
  };
  const dispatch = useDispatch();
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState(Array(allbrands.length).fill([]));
  const [selectedCategory, setSelectedCategory] = useState("");
  const [tempImages, setTempImages] = useState([]);
  const Navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      Navigate("/");
    }
  }, []);

  const getDataForExcel = () => {
    setLoading(true);
    dispatch(
      getAllBrands(
        { page: allcurrentPage, perPage: allcountPerPage },
        (data) => {
          console.log("data is ", data.data.data.brands);

          setGetAllData(data.data.data.brands);
          setLoading(false);
        },
        (err) => {
          console.error(err);
          setLoading(false);
        }
      )
    );
  };
  const fetchBrands = () => {
    setLoading(true);
    dispatch(
      getAllBrands(
        { page: currentPage, perPage: countPerPage, searchText: searchTerm },
        (data) => {
          console.log("data is ", data.data.data.brands);
          const fetchedBrands = data.data.data.brands;

          setBrands(fetchedBrands);
          setAllBrands(data.data.results);
          setLoading(false);
        },
        (err) => {
          console.error(err);
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchBrands();
    getDataForExcel();
  }, [searchTerm, countPerPage, currentPage, selectedCategory, dispatch]);

  useEffect(() => {
    dispatch(
      getAllCategory(
        (data) => {
          setAllCategories(data.data.data.categories);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }, []);

  const exportToExcel = () => {
    const header = [
      "Brand Name",
      "Siripay Discount",
      "Denomination List",
      "Brand Product Code",
      "Brand Type",
      "Stock Availability",
      "Date Added",
      "Category Name",
      "Active Status",
      "Premium Status",
    ];

    const truncateString = (str, maxLength = 32767) => {
      if (typeof str !== "string") {
        return str;
      }
      return str.length > maxLength
        ? str.substring(0, maxLength - 3) + "..."
        : str;
    };

    const dataToExport = getAllData.map((brand) => [
      truncateString(brand.BrandName || ""),
      brand.discount || "",
      truncateString(
        brand.denominationList ? brand.denominationList.join(", ") : "--"
      ),
      truncateString(brand.BrandProductCode || ""),
      truncateString(brand.BrandType || ""),
      brand.stockAvailable ? "Available" : "Out of stock",
      brand.createdAt ? new Date(brand.createdAt).toLocaleDateString() : "--",
      truncateString(brand.Category ? brand.Category.join(", ") : "--"),
      brand.isActive ? "Active" : "Inactive",
      brand.isPremium ? "Active" : "Inactive",
    ]);

    dataToExport.unshift(header);

    const worksheet = XLSX.utils.aoa_to_sheet(dataToExport);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Brands Data");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });

    saveAs(blob, "brands_data.xlsx");
  };
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const [isEditing, setIsEditing] = useState(null);
  const [tempImage, setTempImage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  const handleEditClick = (row, index) => {
    if (isEditing === index) {
      if (selectedImage) {
        uploadImage(selectedImage, index, row);
      }
      setIsEditing(null);
    } else {
      setTempImage(row.BrandImage);
      setIsEditing(index);
      document.getElementById(`file-input-${index}`).click();
    }
  };

  const handleFileChange = (e, index) => {
    if (e.target.files && e.target.files[0]) {
      const fileName = e.target.files[0].name.toLowerCase();
      if (
        fileName.includes("jpg") ||
        fileName.includes("jpeg") ||
        fileName.includes("png")
      ) {
        setSelectedImage(e.target.files[0]);
        setTempImage(URL.createObjectURL(e.target.files[0]));
        setIsEditing(index);
      } else {
        enqueueSnackbar("Please upload a valid image", { variant: "warning" });
      }
    }
  };

  const uploadImage = (file, index, row) => {
    let formData = new FormData();
    formData.append("file", file);
    dispatch(
      uploadDocument(
        formData,
        (data) => {
          const newImageUrl = data?.data?.data?.publicUrl;
          updateBrandStatus(
            row._id,
            row.isActive,
            row.Category,
            row.discount,
            newImageUrl
          );
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  const updateBrandStatus = (
    id,
    status,
    Category,
    discount,
    imageUrl,
    isActive,
    isPremium,
    field
  ) => {
    let payload = {
      category: Category,
      discount,
      BrandImage: imageUrl,
      isActive: field === "isActive" ? status : isActive,
      isPremium: field === "isPremium" ? status : isPremium,
    };
    dispatch(
      updateBrand(
        id,
        payload,
        (data) => {
          enqueueSnackbar("Brand Updated Successfully", { variant: "success" });
          fetchBrands();
        },
        (error) => {
          enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
          console.log(error);
        }
      )
    );
  };

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Grid item md={6} xs={12}>
            <SearchBar onSearch={handleSearch} sWidth={"100%"} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: { md: "end", xs: "start" },
                width: "100%",
              }}
            >
              {" "}
              <CustomButton
                variant="contained"
                width={{ md: "22%", sm: "50%", xs: "40%" }}
                height={"rem"}
                type="button"
                onClick={exportToExcel}
              >
                Export All
              </CustomButton>
            </Box>
          </Grid>
        </Grid>
        {loading ? (
          <Typography textAlign={"center"} my={2}>
            <CircularProgress size={24} />
          </Typography>
        ) : (
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: "25px",
              overflowX: "scroll",
              my: 3,
            }}
          >
            <Table sx={{ maxWidth: "auto" }}>
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#11409B",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  whiteSpace: "nowrap",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                {brands.length > 0 ? (
                  brands.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Box sx={{ border: "1px solid #E5E4E2" }}>
                          <img
                            src={
                              isEditing === index ? tempImage : row.BrandImage
                            }
                            alt={row.BrandName}
                            style={{
                              width: "7rem",
                              height: "4rem",
                              objectFit: "fill",
                            }}
                          />
                          <input
                            type="file"
                            id={`file-input-${index}`}
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={(e) => handleFileChange(e, index)}
                          />
                          <IconButton
                            onClick={() => handleEditClick(row, index)}
                          >
                            {isEditing === index ? <Check /> : <Edit />}
                          </IconButton>
                        </Box>
                      </TableCell>

                      <TableCell>{row?.BrandName}</TableCell>
                      <TableCell>
                        <TextField
                          placeholder="Enter discount"
                          value={
                            tempDiscountIndex === index
                              ? tempDiscount
                              : row?.discount
                          }
                          onChange={(e) => setTempDiscount(e.target.value)}
                          disabled={tempDiscountIndex !== index}
                          InputProps={{
                            endAdornment:
                              tempDiscountIndex === index ? (
                                <Check
                                  sx={{
                                    fontSize: "16px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    updateBrandStatus(
                                      row?._id,
                                      row?.isActive,
                                      row?.Category,
                                      tempDiscount,
                                      row?.BrandImage
                                    )
                                  }
                                />
                              ) : (
                                <Edit
                                  sx={{
                                    fontSize: "16px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setTempDiscount(row?.discount);
                                    setTempDiscountIndex(index);
                                  }}
                                />
                              ),
                          }}
                        />
                      </TableCell>

                      <TableCell>
                        {row?.denominationList?.length > 0 ? (
                          <>
                            {showMore
                              ? row.denominationList.join(", ")
                              : row.denominationList.slice(0, 4).join(", ")}

                            {row.denominationList.length > 4 && (
                              <Box
                                component="div"
                                sx={{
                                  cursor: "pointer",
                                  display: "block",
                                  marginTop: 1,
                                }}
                                onClick={toggleShowMore}
                              >
                                <Typography variant="body2">
                                  {showMore ? "Show less" : "Show more"}
                                </Typography>
                              </Box>
                            )}
                          </>
                        ) : (
                          "--"
                        )}
                      </TableCell>
                      <TableCell>{row?.BrandProductCode}</TableCell>
                      <TableCell>{row?.Brandtype}</TableCell>
                      <TableCell>
                        {row?.stockAvailable ? (
                          <span style={{ color: "green" }}>Available</span>
                        ) : (
                          <span style={{ color: "red" }}>Out of stock</span>
                        )}
                      </TableCell>
                      <TableCell>
                        {row?.createdAt
                          ? dayjs(row?.createdAt).format("MM-DD-YYYY ")
                          : ""}
                      </TableCell>

                      <TableCell>
                        <Autocomplete
                          id="combo-box-demo"
                          value={row?.Category}
                          multiple
                          onChange={(event, newValue) => {
                            updateBrandStatus(
                              row._id,
                              row.isActive,
                              newValue,
                              row?.discount,
                              row?.BrandImage
                            );
                          }}
                          options={
                            categories?.length > 0
                              ? categories.map((item) => item?.CategoryName)
                              : []
                          }
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField {...params} label="Category" />
                          )}
                        />
                      </TableCell>

                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                checked={row.isActive}
                                onChange={(e) => {
                                  updateBrandStatus(
                                    row._id,
                                    e.target.checked,
                                    row.Category,
                                    row?.discount,
                                    row?.BrandImage,
                                    row.isActive, // Current isActive value
                                    row.isPremium, // Current isPremium value
                                    "isActive"
                                  );
                                }}
                              />
                            }
                          />
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                checked={row.isPremium}
                                onChange={(e) => {
                                  updateBrandStatus(
                                    row._id,
                                    e.target.checked,
                                    row.Category,
                                    row?.discount,
                                    row?.BrandImage,
                                    row.isActive, // Current isActive value
                                    row.isPremium, // Current isPremium value
                                    "isPremium"
                                  );
                                }}
                              />
                            }
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <Stack
                    sx={{
                      margin: "auto",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="h2"
                      my={4}
                      mx={"100%"}
                      textAlign={"center"}
                    >
                      No data found
                    </Typography>
                  </Stack>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Box
          sx={{
            bgcolor: "mute.dark",
            borderRadius: 1,
            color: "dark.text",
            p: 2,
            mt: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography>
              {(currentPage - 1) * countPerPage + 1} -{" "}
              {countPerPage > allbrands
                ? allbrands
                : currentPage * countPerPage > allbrands
                ? allbrands
                : currentPage * countPerPage}{" "}
              of {allbrands}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography> Rows per page:</Typography>
            <select
              style={{
                marginTop: "-5px",
                background: "transparent",
                border: "none",
              }}
              value={countPerPage}
              onChange={(e) => setCountPerPage(e.target.value)}
            >
              <option value="25">25</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <Box sx={{ ml: 2 }}>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1 || countPerPage > allbrands}
                    sx={{
                      border: "1px solid",
                      borderRadius: "10px",
                      borderColor: "mute.main",
                      bgcolor: "white.main",
                      boxShadow: "2px 2px 0px #999",
                      background: "#fff",
                    }}
                  >
                    <KeyboardArrowLeftIcon
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color:
                          currentPage === 1 || countPerPage > allbrands
                            ? "#999"
                            : "#000",
                      }}
                    />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="text" component={"span"}>
                    {currentPage}/{Math.ceil(allbrands / countPerPage)}
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={
                      countPerPage > allbrands ||
                      currentPage == Math.ceil(allbrands / countPerPage)
                    }
                    sx={{
                      border: "1px solid",
                      borderRadius: "10px",
                      borderColor: "mute.main",
                      bgcolor: "white.main",
                      boxShadow: "2px 2px 0px #999",
                      background: "#fff",
                    }}
                  >
                    <KeyboardArrowRightIcon
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "#000",
                      }}
                    />
                  </IconButton>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
