import {
  deleteAPI,
  getAPI,
  patchAPI,
  postAPI,
} from '../utils/api';
import endpoints from '../utils/endpoints';

export const getAllTicket = (
  payload,
  userId,
  callBack,
  errorCallback
) => {
  return (dispatch) => {
    getAPI(
      endpoints.TICKET+'/all',

      {},
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem('token')
    );
  };
};
export const postTheTicket = (
  payload,
  userId,
  callBack,
  errorCallback
) => {
  return (dispatch) => {
    postAPI(
      endpoints.TICKET+'/create',

      payload,
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem('token')
    );
  };
};

export const UpdateTheTicket = (
  payload,
  userId,
  ticketId,
  callBack,
  errorCallback
) => {
  return (dispatch) => {
    patchAPI(
      endpoints.EDIT_TICKET.replace(':id', userId) +
        '/' +
        ticketId,
      payload,
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem('token')
    );
  };
};

export const deleteTicket = (
  userId,
  ticketId,
  callBack,
  errorCallback
) => {
  return (dispatch) => {
    deleteAPI(
      endpoints.EDIT_TICKET.replace(':id', userId) +
        '/' +
        ticketId,
      {},
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      localStorage.getItem('token')
    );
  };
};


export const getAllRewards = (
  payload,
  callBack,
  errorCallback
) => {
  return (dispatch) => {
    getAPI(
      endpoints.GET_WALLET,

      {},
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem('token')
    );
  };
};

export const postTheReward = (
  payload,
  callBack,
  errorCallback
) => {
  return (dispatch) => {
    postAPI(
      endpoints.GET_WALLET,

      payload,
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem('token')
    );
  };
};
export const getAllComments = (
  ticketId,
  callBack,
  errorCallback
) => {
  return (dispatch) => {
    getAPI(
      `${endpoints.COMMENTS}${ticketId}`,
      {},
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem('token')
    );
  };
};

export const createComments = (
  payload,
  ticketId,
  callBack,
  errorCallback
) => {
  return (dispatch) => {
    postAPI(
      `${endpoints.COMMENTS}${ticketId}`,
      payload,
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem('token')
    );
  };
};
