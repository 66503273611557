import React, { useEffect, useRef, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Stack,
  Grid,
  CircularProgress,
  Box,
  Autocomplete,
  TextField,
} from "@mui/material";
import HomeLayout from "../../asset/layout/HomeLayout";
import { Add } from "@mui/icons-material";

import CustomButton from "../../components/mui/CustomButton";
import RModal from "../../components/mui/RModal";
import InputField1 from "../../components/mui/InputField1";
import { enqueueSnackbar } from "notistack";
import ResponsiveDatePickers from "../../components/mui/PichDate";
import { useDispatch, useSelector } from "react-redux";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import DropDownMenu from "../../components/mui/DropDownMenu";
import SelectDropdown from "../../components/mui/SelectDropdown";

import { FaEye } from "react-icons/fa";
import SearchBar from "../../components/mui/SearchBar";
import DatePickerComponent from "../../components/DatePickerComponent";
import { MasterCard } from "../../components/Cards/MasterCard";
import * as XLSX from "xlsx";
import {
  UpdateTheTicket,
  deleteTicket,
  getAllTicket,
  postTheTicket,
} from "../../actions/ticketAuth";
import {
  getAllUsers,
  getAllUsersClient,
  getAllticketGetClient,
} from "../../actions/userAuth";
import AddTicketModal from "../../components/AddTicketModal";
import ViewTicketModal from "../../components/ViewTIcketModal";
import { getAllAdminUsers } from "../../actions/backEndUserAuth";

const columns = [
  "Ticket Name",
  "Assigned To",
  "Created On",
  "Created By ",
  "Priority ",
  "Status ",
  "Action",
];

const iconData = [
  {
    src: "/CardTransfer.svg",

    alt: "Number of Ticket",
    name: "Number of Ticket",
    bgColor: "#FFF5D9",
    count: 0,
  },
  {
    src: "/ClipboardRemove.svg",

    alt: " open Ticket",
    name: " open Ticket",

    bgColor: "#E7EDFF",
    count: 0,
  },
  {
    src: "/CardSend.svg",
    alt: " Close Ticket",
    name: " Close Ticket",
    bgColor: "#FFE0EB",
    count: 0,
  },
  {
    src: "/CardRecive.svg",
    alt: "On Hold ",
    name: "On Hold ",
    bgColor: "#DCFAF8",
    count: 0,
  },
];

const TicketStatus = ["open", "close", "hold"];
const PriorityStatus = ["high", "low", "moderate"];

export const TicketManagement = () => {
  const [open, setOpen] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [ticketGet, setticketGet] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);
  const [allBackendUser, setAllBackendUser] = useState(10);

  const { user } = useSelector((state) => ({ ...state }));
  const { allUser } = useSelector((state) => state);

  const allUserPhoneNumbers = [];

  for (const userId in allUser) {
    const user = allUser[userId];
    if (user && user.phone) {
      allUserPhoneNumbers.push(user.id);
    }
  }

  const dropdownRef = useRef(null);
  const [value, setValue] = useState("");
  const [range, setRange] = useState([1, 30]);
  const [ticketName, setTicketName] = useState("");
  const [ticketDescription, setTicketDescription] = useState("");
  const [assignedToAdd, setAssignedToAdd] = useState([]);
  const [raisedForAdd, setRaisedForAdd] = useState([]);
  const [raisedTo, setRaisedTo] = useState("");
  const [raisedToEdit, setRaisedToEdit] = useState("");
  const [ticketStatus, setTicketStatus] = useState("");
  const [allotedTime, setAllotedTime] = useState("");
  const [priority, setPriority] = useState("");
  const [commentEdit, setCommentEdit] = useState("");
  const [number, setNumber] = useState("");
  const [unit, setUnit] = useState("hr");
  const [error, setError] = useState("");
  const [ticketNameEdit, setTicketNameEdit] = useState("");
  const [ticketDescriptionEdit, setTicketDescriptionEdit] = useState("");
  const [assignedToAddEdit, setAssignedToAddEdit] = useState([]);
  const [ticketStatusEdit, setTicketStatusEdit] = useState("");
  const [allotedTimeEdit, setAllotedTimeEdit] = useState("");
  const [priorityEdit, setPriorityEdit] = useState("");
  const [numberEdit, setNumberEdit] = useState("");
  const [unitEdit, setUnitEdit] = useState("hr");
  const [comment, setComment] = useState("");

  const [errorEdit, setErrorEdit] = useState("");
  const [ticketId, setTicketId] = useState("");
  const [createdId, setCreatedId] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [totalTicket, setTotalTicket] = useState();
  const [openTicket, setopenTicket] = useState();
  const [closeTicket, setcloseTicket] = useState();
  const [backendUser, setBackendUser] = useState([]);
  const [onHold, setonHold] = useState();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const roleDropdownRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      Navigate("/");
    }
  }, []);

  const userId = user.id;

  const [user_new, setUser_new] = useState([]);

  const getData = () => {
    setLoading(true);
    dispatch(
      getAllUsersClient(
        { page: 1, perPage: "" },
        (data) => {
          const filteredData = data.data.data.users.filter((item) => {});
          filteredData.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setUser_new(data.data.data.users);

          setLoading(false);
        },
        (error) => {
          setLoading(false);
        }
      )
    );
  };

  const autoCompleteOptions = user_new.map((user) => ({
    id: user.id,
    displayValue: `${user.username || user.email || ""} (${user.phone || ""})`,
  }));

  const getTicket = () => {
    setLoading(true);
    dispatch(
      getAllTicket(
        { page: currentPage, perPage: countPerPage },
        userId,
        (data) => {
          const filteredTickets = data.data.data.filter((ticket) => {
            const matchQuery =
              ticket.ticketName
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              ticket.createdAt
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              ticket.createdBy.username

                ?.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              ticket.assignedTo.some(
                (assigned) =>
                  assigned.username
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                  assigned.phone
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase())
              ) ||
              ticket.priority
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              ticket.createdBy.phone
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              ticket.status?.toLowerCase().includes(searchTerm.toLowerCase());

            const matchDate =
              (!selectedDateFrom ||
                new Date(ticket.updatedAt) >= new Date(selectedDateFrom)) &&
              (!selectedDateTo ||
                new Date(ticket.updatedAt) <= new Date(selectedDateTo));

            return matchQuery && matchDate;
          });

          filteredTickets.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          setticketGet(filteredTickets);
          setLoading(false);
          setTotalTicket(filteredTickets.length);
          const openTickets = filteredTickets.filter(
            (ticket) => ticket.status === "open"
          );
          setopenTicket(openTickets.length);
          const closeTickets = filteredTickets.filter(
            (ticket) => ticket.status === "close"
          );
          setcloseTicket(closeTickets.length);

          const holdTickets = filteredTickets.filter(
            (ticket) => ticket.status === "onHold"
          );
          setonHold(holdTickets.length);
        },
        (err) => {
          console.log(err);
          setLoading(false);
        }
      )
    );
  };
  const getFilteredticketGet = () => {
    if (!filterValue) return ticketGet;
    return ticketGet.filter((user) =>
      user.assignedTo.some(
        (assigned) =>
          assigned.username.includes(filterValue) ||
          assigned.phone.includes(filterValue)
      )
    );
  };

  const filteredticketGet = getFilteredticketGet();
  const paginatedData = filteredticketGet?.slice(
    (currentPage - 1) * countPerPage,
    currentPage * countPerPage
  );
  const fetchAdminUsers = () => {
    setLoading(true);
    dispatch(
      getAllAdminUsers(
        { page: currentPage, perPage: countPerPage },
        userId,
        (data) => {
          setLoading(false);
          setBackendUser(data.data.data.users);
        },
        (err) => {
          console.log(err);
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    getData();
    getAllTicket();
    getTicket();
    fetchAdminUsers();
  }, [searchTerm, selectedDateFrom, selectedDateTo, dispatch]);

  const assignedTo = allUserPhoneNumbers?.map((phoneNumber) => ({
    label: phoneNumber,
  }));

  const handleOpen = (user, userId) => {
    console.log("user on opening", user);
    setOpen(true);
    if (user && userId === user?.id) {
      setTicketNameEdit(user?.ticketName);
      setTicketDescriptionEdit(user?.ticketDescription);
      setAssignedToAddEdit(user?.assignedTo.map((item) => item.id)); // Use IDs here
      setRaisedToEdit(user?.raisedFor.id); // Assuming raisedFor is an object with an ID
      setTicketStatusEdit(user?.status);
      setAllotedTimeEdit(user?.allotedTime);
      setPriorityEdit(user?.priority);
      setNumberEdit(user?.allotedTime?.number);
      setUnitEdit(user?.allotedTime?.unit);
      setTicketId(user?.createdBy?.id);
      setCreatedId(user?._id);
      setCommentEdit(user?.comment || "");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenSecondModal = () => {
    setOpenSecondModal(true);
  };

  const handleCloseSecondModal = () => {
    setOpenSecondModal(false);
  };

  const formDataEdit = {
    ticketName: ticketNameEdit,
    ticketDescription: ticketDescriptionEdit,
    assignedTo: assignedToAddEdit,
    raisedFor: raisedToEdit,
    number: numberEdit,
    status: ticketStatusEdit,
    priority: priorityEdit.toLowerCase(),
    unit: unitEdit,
    comment: commentEdit,
  };

  const handleEdit = (event) => {
    event.preventDefault();
    setLoading(true);

    const updatedFormDataEdit = {
      ...formDataEdit,
    };

    dispatch(
      UpdateTheTicket(
        updatedFormDataEdit,
        ticketId,
        createdId,
        (response) => {
          enqueueSnackbar("Data submitted successfully", {
            variant: "success",
          });

          getTicket();

          handleClose();
          setLoading(false);
        },
        (error) => {
          enqueueSnackbar("Error submitting data", {
            variant: "error",
          });
          console.error(error);
        }
      )
    );
  };

  const handleNumberChange = (event) => {
    const value = event.target.value;
    let isValid = true;

    setNumber(value);
    setError("");
  };

  const handleUnitChange = (event) => {
    if (event && event.target) {
      setUnit(event.target.value);
      setNumber("");
      setError("");
    }
  };

  const formDataAdd = {
    ticketName: ticketName,
    ticketDescription: ticketDescription,
    assignedTo: assignedToAdd,
    raisedFor: raisedTo,
    number: number,
    status: ticketStatus,
    priority: priority.toLowerCase(),
    unit: unit,
    comment: comment,
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let isValid = true;
    let errorMessage = "";

    if (unit === "day") {
      isValid = number >= 1 && number <= 30;
      errorMessage = "Please enter a value between 1 and 30 for days.";
    } else if (unit === "hr") {
      isValid = number >= 1 && number <= 8;
      errorMessage = "Please enter a value between 1 and 8 for hours.";
    } else if (unit === "min") {
      isValid = number >= 1 && number <= 60;
      errorMessage = "Please enter a value between 1 and 60 for minutes.";
    }

    if (isValid) {
      dispatch(
        postTheTicket(
          formDataAdd,
          userId,
          (response) => {
            enqueueSnackbar("Data submitted successfully", {
              variant: "success",
            });

            setLoading(false);
            setNumber("");
            handleCloseSecondModal();
            getTicket();
          },
          (error) => {
            enqueueSnackbar("Error submitting data", {
              variant: "error",
            });
            console.error(error);
          }
        )
      );
    } else {
      setError(errorMessage);
    }
  };

  const handleReset = () => {
    setTicketName("");
    setTicketDescription("");
    setAssignedToAdd([]);
    setRaisedTo([]);
    setTicketStatus("");
    setAllotedTime("");
    setPriority("");
    setNumber("");
  };

  const menuItemsAllotedTime = [
    { value: "day", label: "Day" },
    { value: "hr", label: "Hour" },
    { value: "min", label: "Minute" },
  ];

  // const handleDelete = (id, createdId) => {
  //   dispatch(
  //     deleteTicket(
  //       id,
  //       createdId,
  //       (data) => {
  //         enqueueSnackbar("Deleted Successfully", {
  //           variant: "success",
  //         });
  //         getTicket();
  //       },
  //       (err) => {
  //         enqueueSnackbar("Error Occured! Please try again", {
  //           variant: "error",
  //         });
  //       }
  //     )
  //   );
  // };

  const [viewOpen, setViewOpen] = useState(false);

  const handleOpenModalView = (ticket) => {
    setSelectedTicket(ticket);
    setViewOpen(true);
  };
  const handleCloseModalVIew = () => {
    setViewOpen(false);
    setSelectedTicket(null);
  };

  const handleSearch = (query) => {
    setSearchTerm(query);
  };

  const handleExport = () => {
    const workbook = XLSX.utils.book_new();
    const worksheetData = [
      [
        "Ticket Name",
        "Assigned To",
        "Created At",
        "Created By",
        "Priority",
        "Status",
      ],
      ...filteredticketGet?.map((ticket) => [
        ticket?.ticketName,
        ticket?.assignedTo
          .map((assigned) => assigned?.username || assigned?.phone)
          .join(", "),
        ticket.createdAt
          ? new Date(ticket?.createdAt).toLocaleDateString("en-CA")
          : "N/A",
        ticket?.createdBy?.username || ticket?.createdBy?.phone,
        ticket?.priority || "Unknown",
        ticket?.status || "Unknown",
      ]),
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Tickets");
    XLSX.writeFile(workbook, "tickets_data.xlsx");
  };

  const handleSearchClear = () => {
    setSearchTerm("");
    setSelectedDateFrom(null);
    setSelectedDateTo(null);
    setSearchQuery("");
  };

  return (
    <HomeLayout>
      <Stack alignItems="center" direction={"row"}>
        <Typography
          variant="h4"
          my={3}
          flexGrow={1}
          fontSize={{ xl: "22px", sm: "1rem", xs: "1rem" }}
        >
          Ticket Management
        </Typography>
      </Stack>
      <Grid container spacing={2} my={4}>
        {iconData?.map((data, index) => (
          <Grid
            item
            key={index}
            md={4}
            xs={6}
            xl={3}
            sm={6}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <MasterCard
              src={data.src}
              alt={data.alt}
              name={data.name}
              bgColor={data.bgColor}
              count={
                index === 0
                  ? totalTicket
                  : index === 1
                  ? openTicket
                  : index === 2
                  ? closeTicket
                  : onHold
              }
            />
          </Grid>
        ))}
      </Grid>
      <Stack alignItems="center" direction={"row"} mt={3}>
        <Typography
          variant="h4"
          my={3}
          flexGrow={1}
          fontSize={{ xl: "22px", sm: "1rem", xs: "1rem" }}
        >
          Ticket
        </Typography>
        <Box pr={2}>
          <CustomButton
            variant="contained"
            onClick={handleExport}
            fontSize={{ xl: "1rem", sm: ".7rem", xs: ".7rem" }}
          >
            Export Tickets
          </CustomButton>
        </Box>
        <CustomButton
          variant="contained"
          onClick={handleOpenSecondModal}
          fontSize={{ xl: "1rem", sm: ".7rem", xs: ".7rem" }}
        >
          {" "}
          <Add sx={{ mr: { xl: 1, sm: 0.2 } }} />
          Add Ticket
        </CustomButton>
      </Stack>
      <Grid container spacing={1} alignItems="center">
        {/* <Grid item xs={5} md={5} lg={2} xl={2}>
          <DatePickerComponent
            selectedDate={selectedDateFrom}
            setSelectedDate={setDateFrom}
          />
        </Grid> */}
        {/* <Grid item xs={2} md={2} lg={0.5} xl={0.5}>
          <IconButton>
            <img src='/halfArrow.svg' alt='halfArrow' />
          </IconButton>
        </Grid> */}
        {/* <Grid item xs={5} md={5} lg={2} xl={2}>
          <DatePickerComponent
          //   selectedDate={dateTo}
          //   setSelectedDate={setDateTo}
          />
        </Grid> */}

        <Grid item md={4} xs={12}>
          <SearchBar onSearch={handleSearch} searchTerm={searchTerm} />
        </Grid>
        <Grid item md={3} xs={12}>
          <ResponsiveDatePickers
            selectedDate={selectedDateFrom}
            setSelectedDate={setSelectedDateFrom}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <ResponsiveDatePickers
            selectedDate={selectedDateTo}
            setSelectedDate={setSelectedDateTo}
          />
        </Grid>
        <Grid item md={2} xs={12}>
          {" "}
          <CustomButton
            variant="contained"
            onClick={handleSearchClear}
            width={"100%"}
            height={"2.5rem"}
          >
            clear
          </CustomButton>
        </Grid>
        {/* <Grid item xs={12} md={7} lg={2.2} xl={2.2}>
          <Autocomplete
            id='tags-filled'
            options={autoCompleteOptions}
            onChange={(event, newValue) => {
              setFilterValue(newValue || '');
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder='Assigned To'
                sx={{
                  '& .MuiInputBase-root': {
                    background: '#FFFFFF',
                    borderRadius: '7px',
                    paddingRight: '8px',
                    py: 'auto',
                  },
                  '& .MuiInputBase-input': {
                    padding: '0 !important',
                  },
                }}
              />
            )}
          />
        </Grid> */}
        {/* <Grid item xs={12} lg={2.2} xl={2.2}>
          <DropDownMenu
            buttonText='Ticket Status'
            menuItems={TicketStatus?.map((item) => {
              return { label: item };
            })}
            selectedItem={ticketStatus}
            setSelectedItem={setTicketStatus}
            buttonWidth={'100%'}
          />
        </Grid> */}
      </Grid>

      {loading ? (
        <Box align="center" my={5}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "25px",
            my: 3,
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {columns?.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#11409B",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      textAlign: "center",
                    }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                whiteSpace: "nowrap",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              {!loading && paginatedData?.length > 0 ? (
                paginatedData?.map((user) => (
                  <TableRow key={user?.id}>
                    <TableCell>{user?.ticketName}</TableCell>

                    <TableCell>
                      {user?.assignedTo.map((item, index) => (
                        <span key={index}>
                          {item.username || item.phone}
                          {index !== user.assignedTo.length - 1 && ", "}
                        </span>
                      ))}
                    </TableCell>

                    <TableCell>
                      {user?.createdAt
                        ? new Date(user.createdAt)
                            .toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            })
                            .replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$1-$2-$3")
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {user?.createdBy?.username || user?.createdBy?.phone}
                    </TableCell>
                    <TableCell>{user?.priority || "Unknown"}</TableCell>
                    <TableCell>{user?.status || "Unknown"}</TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <IconButton onClick={() => handleOpen(user, user?.id)}>
                        <img src="/liEdit.svg" alt="liEdit" />
                      </IconButton>
                      {/* <IconButton
                        onClick={() => handleDelete(user?.id, user?._id)}
                      >
                        <img src="/liDelet.svg" alt="liDelet" />
                      </IconButton> */}
                      <IconButton onClick={() => handleOpenModalView(user)}>
                        <FaEye />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    {loading ? <CircularProgress /> : "No tickets found."}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {/* <Paginations /> */}
      <Box
        sx={{
          bgcolor: "mute.dark",
          borderRadius: 1,
          color: "dark.text",
          p: 2,
          mt: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography>
            {(currentPage - 1) * countPerPage + 1} -{" "}
            {Math.min(currentPage * countPerPage, filteredticketGet.length)} of{" "}
            {filteredticketGet.length}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>Rows per page:</Typography>
          <select
            style={{
              marginTop: "-5px",
              background: "transparent",
              border: "none",
            }}
            value={countPerPage}
            onChange={(e) => setCountPerPage(e.target.value)}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
          </select>
          <Box sx={{ ml: 2 }}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <IconButton
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={
                  currentPage === 1 || countPerPage > filteredticketGet.length
                }
                sx={{
                  border: "1px solid",
                  borderRadius: "10px",
                  borderColor: "mute.main",
                  bgcolor: "white.main",
                  boxShadow: "2px 2px 0px #999",
                  background: "#fff",
                }}
              >
                <KeyboardArrowLeftIcon
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color:
                      currentPage === 1 ||
                      countPerPage > filteredticketGet.length
                        ? "#999"
                        : "#000",
                  }}
                />
              </IconButton>
              <Typography variant="text" component="span">
                {currentPage}/
                {Math.ceil(filteredticketGet.length / countPerPage)}
              </Typography>
              <IconButton
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={
                  countPerPage > filteredticketGet.length ||
                  currentPage ===
                    Math.ceil(filteredticketGet.length / countPerPage)
                }
                sx={{
                  border: "1px solid",
                  borderRadius: "10px",
                  borderColor: "mute.main",
                  bgcolor: "white.main",
                  boxShadow: "2px 2px 0px #999",
                  background: "#fff",
                }}
              >
                <KeyboardArrowRightIcon
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "#000",
                  }}
                />
              </IconButton>
            </Stack>
          </Box>
        </Box>
      </Box>
      {viewOpen && (
        <ViewTicketModal
          open={handleOpenModalView}
          handleClose={handleCloseModalVIew}
          ticket={selectedTicket}
        />
      )}

      {open && (
        <RModal
          open={open}
          handleClose={handleClose}
          width={{ md: "60%", xs: "80%" }}
          height={{ md: "80%", xs: "80%" }}
        >
          <form onSubmit={handleEdit}>
            <Typography variant="h4">Update Ticket </Typography>

            <Grid container spacing={{ xl: 1, sm: 0, xs: 0 }}>
              <Grid item md={6} xs={12} sm={12}>
                <InputField1
                  placeholderColor=" #8D98AA"
                  label={"Ticket Name"}
                  placeholder={"Enter User Name"}
                  spanP={true}
                  value={ticketNameEdit}
                  onChange={(e) => setTicketNameEdit(e.target.value)}
                  required
                  maxLength={20}
                  disabled
                />
              </Grid>
              <Grid item md={6} xs={12} sm={12}>
                <InputField1
                  placeholderColor=" #8D98AA"
                  label={"Ticket Description"}
                  placeholder={"Enter Ticket Description"}
                  spanP={true}
                  value={ticketDescriptionEdit}
                  onChange={(e) => setTicketDescriptionEdit(e.target.value)}
                  required
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Grid container spacing={2}>
                  <Grid item md={8} xs={6}>
                    <InputField1
                      placeholderColor=" #8D98AA"
                      label={"Alloted Time"}
                      placeholder={"Enter Alloted Time"}
                      spanP={true}
                      required
                      type="number"
                      value={numberEdit}
                      onChange={(e) => setNumberEdit(e.target.value)}
                      disabled={unit === ""}
                    />
                    {error && (
                      <Typography color="error" variant="body2">
                        {error}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <Box mt={6}>
                      {" "}
                      <SelectDropdown
                        buttonText="Select Time Unit"
                        menuItems={menuItemsAllotedTime}
                        selectedItem={unitEdit}
                        // setSelectedItem={setUnit}
                        onChange={handleUnitChange}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6} xs={12} sm={12}>
                <Typography variant="h5" my={1.5}>
                  Raised For
                </Typography>

                <Autocomplete
                  id="tags-filled"
                  options={autoCompleteOptions}
                  getOptionLabel={(option) => option.displayValue}
                  value={
                    autoCompleteOptions.find(
                      (opt) => opt.id === raisedToEdit
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setRaisedToEdit(newValue.id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Raised for"
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "2.5rem",
                          background: "#FFFFFF",
                          borderRadius: "7px",
                          paddingRight: "8px",
                          py: "auto",
                        },
                        "& .MuiInputBase-input": {
                          mt: "-12px",
                        },
                        "& .MuiAutocomplete-tag": {
                          mt: "-7px !important",
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12} sm={12}>
                <Typography variant="h5" my={1.5}>
                  Assigned to
                </Typography>

                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={backendUser}
                  getOptionLabel={(option) => option.username}
                  value={backendUser.filter((user) =>
                    assignedToAddEdit.includes(user.id)
                  )}
                  onChange={(event, newValue) => {
                    setAssignedToAddEdit(newValue.map((user) => user.id));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Assigned To"
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "2.5rem",
                          background: "#FFFFFF",
                          borderRadius: "7px",
                          paddingRight: "8px",
                          py: "auto",
                        },
                        "& .MuiInputBase-input": {
                          mt: "-12px",
                        },
                        "& .MuiAutocomplete-tag": {
                          mt: "-7px !important",
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12} sm={12}>
                <Typography variant="h5" mb={2} mt={1}>
                  Ticket Status
                </Typography>
                <DropDownMenu
                  buttonText="Ticket Status"
                  menuItems={TicketStatus.map((item) => {
                    return { label: item };
                  })}
                  selectedItem={
                    ticketStatusEdit ? { label: ticketStatusEdit } : null
                  }
                  setSelectedItem={setTicketStatusEdit}
                  buttonWidth={"100%"}
                />
              </Grid>
              <Grid item md={6} xs={12} sm={12}>
                <Typography variant="h5" my={2}>
                  Priority
                </Typography>
                <DropDownMenu
                  buttonText="Priority"
                  menuItems={PriorityStatus.map((item) => {
                    return { label: item };
                  })}
                  selectedItem={priorityEdit ? { label: priorityEdit } : null}
                  setSelectedItem={setPriorityEdit}
                  buttonWidth={"100%"}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="h5" my={2}>
                  Comment
                </Typography>
                <TextField
                  multiline
                  rows={1}
                  fullWidth
                  placeholder="Enter comment here ......"
                  value={commentEdit}
                  onChange={(e) => setCommentEdit(e.target.value)}
                />
              </Grid>
            </Grid>
            <Stack
              direction={"row"}
              spacing={{ xl: 6, md: 6, sm: 1, xs: 1 }}
              mt={4}
            >
              {" "}
              <CustomButton
                variant="contained"
                width={{
                  xl: "20vh",
                  md: "50%",
                  sm: "50%",
                  xs: "60%",
                }}
                height={"3rem"}
                type="submit"
              >
                {loading ? <CircularProgress size={24} /> : "Update "}
              </CustomButton>
              {/* <CustomButton
                    variant='outlined'
                    width={{ xl: '20vh', md: '50%', sm: '50%', xs: '40%' }}
                    height={'3rem'}
                    type='reset'
                  >
                    Reset
                  </CustomButton> */}
            </Stack>
          </form>
        </RModal>
      )}

      {openSecondModal && (
        <RModal
          open={openSecondModal}
          handleClose={handleCloseSecondModal}
          width={{ md: "80%", xs: "80%" }}
          height={{ md: "80%", xs: "80%" }}
        >
          <form onSubmit={handleSubmit}>
            <Typography variant="h4">Add Ticket</Typography>
            <Grid container spacing={{ xl: 1, sm: 0, xs: 0 }}>
              <Grid item xl={6} xs={12} sm={12}>
                <InputField1
                  placeholderColor=" #8D98AA"
                  label={"Ticket Name"}
                  placeholder={"Enter Ticket Name"}
                  spanP={true}
                  onChange={(e) => setTicketName(e?.target?.value)}
                  required
                  maxLength={20}
                />
              </Grid>
              <Grid item xl={6} xs={12} sm={12}>
                <InputField1
                  placeholderColor=" #8D98AA"
                  label={"Ticket Description"}
                  placeholder={"Enter Ticket Description"}
                  spanP={true}
                  onChange={(e) => setTicketDescription(e.target.value)}
                  required
                />
              </Grid>

              <Grid item xl={6} xs={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item md={8} xs={6}>
                    <InputField1
                      placeholderColor=" #8D98AA"
                      label={"Alloted Time"}
                      placeholder={"Enter Alloted Time"}
                      spanP={true}
                      type="number"
                      value={number}
                      onChange={handleNumberChange}
                      disabled={unit === ""}
                    />
                    {error && (
                      <Typography color="error" variant="body2">
                        {error}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <Box mt={6}>
                      {" "}
                      <SelectDropdown
                        buttonText="Select Time Unit"
                        menuItems={menuItemsAllotedTime}
                        selectedItem={unit}
                        setSelectedItem={setUnit}
                        onChange={handleUnitChange}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={6} xs={12} sm={12}>
                <Typography variant="h5" my={1.5}>
                  Raised For
                </Typography>

                <Autocomplete
                  id="tags-filled"
                  options={autoCompleteOptions}
                  getOptionLabel={(option) => option.displayValue}
                  value={
                    autoCompleteOptions.find((opt) => opt.id === raisedTo) ||
                    null
                  }
                  onChange={(event, newValue) => {
                    if (!newValue) {
                      setRaisedTo(null);
                      return;
                    }
                    setRaisedTo(newValue.id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Raised for"
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "2.5rem",
                          background: "#FFFFFF",
                          borderRadius: "7px",
                          paddingRight: "8px",
                          py: "auto",
                        },
                        "& .MuiInputBase-input": {
                          mt: "-12px",
                        },
                        "& .MuiAutocomplete-tag": {
                          mt: "-7px !important",
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xl={6} xs={12} sm={12}>
                <Typography variant="h5" my={1.5}>
                  Assigned to
                </Typography>

                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={backendUser}
                  getOptionLabel={(option) => option.username}
                  value={backendUser.filter((user) =>
                    assignedToAdd.includes(user.id)
                  )}
                  onChange={(event, newValue) => {
                    setAssignedToAdd(newValue.map((user) => user.id));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Assigned To"
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "2.5rem",
                          background: "#FFFFFF",
                          borderRadius: "7px",
                          paddingRight: "8px",
                          py: "auto",
                        },
                        "& .MuiInputBase-input": {
                          mt: "-12px",
                        },
                        "& .MuiAutocomplete-tag": {
                          mt: "-7px !important",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xl={6} xs={12} sm={12}>
                <Typography variant="h5" my={2}>
                  Ticket Status
                </Typography>
                <DropDownMenu
                  buttonText="Ticket Status"
                  menuItems={TicketStatus.map((item) => {
                    return { label: item };
                  })}
                  selectedItem={ticketStatus}
                  setSelectedItem={setTicketStatus}
                  buttonWidth={"100%"}
                />
              </Grid>

              <Grid item xl={6} xs={12} sm={12}>
                <Typography variant="h5" my={2}>
                  Priority
                </Typography>
                <DropDownMenu
                  buttonText="Priority"
                  menuItems={PriorityStatus.map((item) => {
                    return { label: item };
                  })}
                  selectedItem={priority}
                  setSelectedItem={setPriority}
                  buttonWidth={"100%"}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="h5" my={2}>
                  Comment
                </Typography>
                <TextField
                  multiline
                  rows={1}
                  fullWidth
                  placeholder="Enter comment here ......"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </Grid>
            </Grid>

            <Stack
              direction={"row"}
              spacing={{ xl: 6, md: 6, sm: 1, xs: 1 }}
              mt={4}
            >
              {" "}
              <CustomButton
                variant="contained"
                width={{
                  xl: "20vh",
                  md: "50%",
                  sm: "50%",
                  xs: "60%",
                }}
                height={"3rem"}
                type="submit"
              >
                {loading ? <CircularProgress size={24} /> : "Add"}
              </CustomButton>
              <CustomButton
                variant="outlined"
                width={{
                  xl: "20vh",
                  md: "50%",
                  sm: "50%",
                  xs: "40%",
                }}
                height={"3rem"}
                type="reset"
                onClick={handleReset}
              >
                Reset
              </CustomButton>
            </Stack>
          </form>
        </RModal>
      )}
    </HomeLayout>
  );
};
