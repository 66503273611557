import React, { useEffect, useState } from "react";
import { format } from "date-fns-tz";

import HomeLayout from "../asset/layout/HomeLayout";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Stack,
  Grid,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import SearchBar from "../components/mui/SearchBar";
import IOSSwitch from "../style/globalStyles";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ConfirmationModal from "../components/ConfirmationModal";
import { getAllUsersClient, updateUserStatusById } from "../actions/userAuth";
import CustomButton from "../components/mui/CustomButton";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import ResponsiveDatePickers from "../components/mui/PichDate";
import dayjs from "dayjs";

const columns = [
  "Image",
  "User Name",
  "Email",
  "Phone Number",
  "Gender",
  "Reward Points",
  "Last Login",
  "Active",
];

const UserProfile = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUserData, setFilteredUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [userToUpdate, setUserToUpdate] = useState(null);
  const [newStatus, setNewStatus] = useState(false);
  const Navigate = useNavigate();
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      Navigate("/");
    }
  }, [Navigate]);

  useEffect(() => {
    getData();
  }, [searchTerm, countPerPage, currentPage, selectedDateFrom, selectedDateTo]);

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const getData = () => {
    setLoading(true);
    dispatch(
      getAllUsersClient(
        { page: 1, perPage: 100000 },
        (data) => {
          const filteredData = data.data.data.users.filter((item) => {
            const matchQuery =
              item?.username
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase()) ||
              item?.gender
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase()) ||
              item?.phone?.includes(searchTerm) ||
              item?.email?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
              item?.lastLogin
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase());

            const matchDateRange =
              (!selectedDateFrom ||
                new Date(item.updatedAt) >= new Date(selectedDateFrom)) &&
              (!selectedDateTo ||
                new Date(item.updatedAt) <= new Date(selectedDateTo));
            const isNotAdminOrSuperAdmin =
              item.role !== "admin" && item.role !== "superAdmin";
            return matchQuery && matchDateRange && isNotAdminOrSuperAdmin;
          });

          filteredData.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          setFilteredUserData(filteredData);

          setLoading(false);
        },
        (error) => {
          setLoading(false);
        }
      )
    );
  };

  const handleConfirmationOpen = (user, status) => {
    if (!status) {
      setUserToUpdate(user);
      setNewStatus(status);
      setConfirmationOpen(true);
    } else {
      updateUserStatus(user.id, status, "");
    }
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const updateUserStatus = (id, status, remark) => {
    const payload = {
      active: status,
      updateRemark: remark,
    };
    dispatch(
      updateUserStatusById(
        id,
        payload,
        () => {
          enqueueSnackbar("Status Updated!", {
            variant: "success",
          });
          getData();
        },
        (error) => {
          enqueueSnackbar(error?.response?.data?.message, {
            variant: "error",
          });
        }
      )
    );
  };

  const handleConfirmUpdate = (remark) => {
    if (userToUpdate) {
      updateUserStatus(userToUpdate.id, newStatus, remark);
    }
    setConfirmationOpen(false);
  };

  const formatDateIndian = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd/MM/yyyy", {
      timeZone: "Asia/Kolkata",
    });
  };

  const exportToExcel = () => {
    const header = [
      "User Name",
      "Email",
      "Phone Number",
      "Gender",
      "Reward Points",
      "Last Login",
      "Active",
    ];

    const dataToExport = filteredUserData.map((user) => [
      user?.username,
      user?.email,
      user?.phone,
      user?.gender,
      user?.rewardPoint,
      user?.updatedAt ? formatDateIndian(user?.updatedAt) : "--",
      user?.active ? "Yes" : "No",
    ]);

    dataToExport.unshift(header);

    const worksheet = XLSX.utils.aoa_to_sheet(dataToExport);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users Data");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });

    saveAs(blob, "Users_data.xlsx");
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(filteredUserData.length / countPerPage);

  const paginatedData = filteredUserData.slice(
    (currentPage - 1) * countPerPage,
    currentPage * countPerPage
  );

  const handleSearchClear = () => {
    setSearchTerm("");
    setSelectedDateFrom(null);
    setSelectedDateTo(null);
  };

  return (
    <HomeLayout>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={3}
      >
        <Typography variant="h4" my={3}>
          User Management
        </Typography>
        <CustomButton
          variant="contained"
          width={{ md: "22%", sm: "50%", xs: "40%" }}
          height={"rem"}
          type="button"
          onClick={exportToExcel}
        >
          Export Users
        </CustomButton>
      </Box>

      <Box>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <SearchBar onSearch={handleSearch} searchTerm={searchTerm} />
          </Grid>

          <Grid item md={3} xs={12}>
            <ResponsiveDatePickers
              selectedDate={selectedDateFrom}
              setSelectedDate={setSelectedDateFrom}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ResponsiveDatePickers
              selectedDate={selectedDateTo}
              setSelectedDate={setSelectedDateTo}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            {" "}
            <CustomButton
              variant="contained"
              onClick={handleSearchClear}
              width={"100%"}
              height={"2.5rem"}
            >
              clear
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : paginatedData?.length > 0 ? (
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "25px",
            overflowX: "scroll",
            my: 3,
          }}
        >
          <Table sx={{ maxWidth: "auto" }}>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#11409B",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                whiteSpace: "nowrap",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              {paginatedData?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <img
                      src={row.pictureUrl}
                      alt="UserImage"
                      style={{ width: "50px" }}
                    />
                  </TableCell>
                  <TableCell>{row?.username ? row?.username : "--"}</TableCell>
                  <TableCell>{row?.email ? row?.email : "--"}</TableCell>
                  <TableCell>+91{row?.phone}</TableCell>
                  <TableCell>{row?.gender}</TableCell>
                  <TableCell>{row?.rewardPoint}</TableCell>
                  <TableCell>
                    {row.updatedAt
                      ? dayjs(row?.updatedAt).format("MM-DD-YYYY ")
                      : ""}
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={row.active}
                          onChange={(e) =>
                            handleConfirmationOpen(row, e.target.checked)
                          }
                        />
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1" sx={{ textAlign: "center", my: 3 }}>
          No results found.
        </Typography>
      )}
      <ConfirmationModal
        open={confirmationOpen}
        handleClose={handleConfirmationClose}
        handleConfirm={handleConfirmUpdate}
      />
      {/* <Box
        sx={{
          bgcolor: 'mute.dark',
          borderRadius: 1,
          color: 'dark.text',
          p: 2,
          mt: 4,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography>
            {(currentPage - 1) * countPerPage + 1} -{' '}
            {countPerPage > allClientManagement
              ? allClientManagement
              : currentPage * countPerPage > allClientManagement
              ? allClientManagement
              : currentPage * countPerPage}{' '}
            of {allClientManagement}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography> Rows per page:</Typography>
          <select
            style={{
              marginTop: '-5px',
              background: 'transparent',
              border: 'none',
            }}
            value={countPerPage}
            onChange={(e) =>
              setCountPerPage(Number(e.target.value))
            }
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
          <Box sx={{ ml: 2 }}>
            <Stack
              direction={'row'}
              spacing={2}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Box>
                <IconButton
                  onClick={() =>
                    handlePageChange(currentPage - 1)
                  }
                  disabled={currentPage === 1}
                  sx={{
                    border: '1px solid',
                    borderRadius: '10px',
                    borderColor: 'mute.main',
                    bgcolor: 'white.main',
                    boxShadow: '2px 2px 0px #999',
                    background: '#fff',
                  }}
                >
                  <KeyboardArrowLeftIcon
                    sx={{
                      fontSize: '15px',
                      fontWeight: 'bold',
                      color: currentPage === 1 ? '#999' : '#000',
                    }}
                  />
                </IconButton>
              </Box>
              <Box>
                <Typography variant='text' component={'span'}>
                  {currentPage}/
                  {Math.ceil(allClientManagement / countPerPage)}
                </Typography>
              </Box>
              <Box>
                <IconButton
                  onClick={() =>
                    handlePageChange(currentPage + 1)
                  }
                  disabled={currentPage === totalPages}
                  sx={{
                    border: '1px solid',
                    borderRadius: '10px',
                    borderColor: 'mute.main',
                    bgcolor: 'white.main',
                    boxShadow: '2px 2px 0px #999',
                    background: '#fff',
                  }}
                >
                  <KeyboardArrowRightIcon
                    sx={{
                      fontSize: '15px',
                      fontWeight: 'bold',
                      color: '#000',
                    }}
                  />
                </IconButton>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography>
            {(currentPage - 1) * countPerPage + 1} -{" "}
            {Math.min(currentPage * countPerPage, filteredUserData.length)} of{" "}
            {filteredUserData.length}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>Rows per page:</Typography>
          <select
            style={{
              marginTop: "-5px",
              background: "transparent",
              border: "none",
            }}
            value={countPerPage}
            onChange={(e) => setCountPerPage(e.target.value)}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
          </select>
          <Box sx={{ ml: 2 }}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <IconButton
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={
                  currentPage === 1 || countPerPage > filteredUserData.length
                }
                sx={{
                  border: "1px solid",
                  borderRadius: "10px",
                  borderColor: "mute.main",
                  bgcolor: "white.main",
                  boxShadow: "2px 2px 0px #999",
                  background: "#fff",
                }}
              >
                <KeyboardArrowLeftIcon
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color:
                      currentPage === 1 ||
                      countPerPage > filteredUserData.length
                        ? "#999"
                        : "#000",
                  }}
                />
              </IconButton>
              <Typography variant="text" component="span">
                {currentPage}/
                {Math.ceil(filteredUserData.length / countPerPage)}
              </Typography>
              <IconButton
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={
                  countPerPage > filteredUserData.length ||
                  currentPage ===
                    Math.ceil(filteredUserData.length / countPerPage)
                }
                sx={{
                  border: "1px solid",
                  borderRadius: "10px",
                  borderColor: "mute.main",
                  bgcolor: "white.main",
                  boxShadow: "2px 2px 0px #999",
                  background: "#fff",
                }}
              >
                <KeyboardArrowRightIcon
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "#000",
                  }}
                />
              </IconButton>
            </Stack>
          </Box>
        </Box>
      </Box>
    </HomeLayout>
  );
};

export default UserProfile;
