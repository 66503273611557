import * as React from "react";
import { styled } from "@mui/system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import { TabContext } from "@mui/lab";
import HomeLayout from "../../asset/layout/HomeLayout";
import { GeneralCoupons } from "./generalCoupons";
import { Typography } from "@mui/material";
import { SpecialCoupons } from "./secialCoupons";

const TabsList = styled("div")`
  min-width: 400px;
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  box-shadow: 0px 4px 6px
    ${({ theme }) =>
      theme.palette.mode === "dark" ? "rgba(0,0,0, 0.4)" : "rgba(0,0,0, 0.2)"};
`;

export const DiscountCoupon = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <HomeLayout>
      <Typography
        variant="h4"
        my={3}
        flexGrow={1}
        fontSize={{ xl: "22px", sm: "1rem", xs: "1rem" }}
      >
        Discount Coupons
      </Typography>
      <TabContext value={value}>
        <Tabs value={value} onChange={handleChange} sx={{ my: 6 }}>
          <Tab label="General Coupons" value="1" />
          <Tab label="Special Coupons" value="2" />
        </Tabs>
        <TabPanel value="1">
          <GeneralCoupons />
        </TabPanel>
        <TabPanel value="2">
          <SpecialCoupons />
        </TabPanel>
      </TabContext>
    </HomeLayout>
  );
};
