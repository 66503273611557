import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Stack,
} from '@mui/material';

const ConfirmationModal = ({
  open,
  handleClose,
  handleConfirm,
}) => {
  const [remark, setRemark] = useState('');

  const handleConfirmClick = () => {
    handleConfirm(remark);
    setRemark('');
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography
          id='modal-modal-title'
          variant='h6'
          component='h2'
        >
          Confirm Deactivation
        </Typography>
        <Typography id='modal-modal-description' sx={{ mt: 2 }}>
          Please provide a remark for deactivation:
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={4}
          variant='outlined'
          value={remark}
          onChange={(e) => setRemark(e.target.value)}
          sx={{ mt: 2 }}
        />
        <Stack
          direction='row'
          spacing={2}
          justifyContent='flex-end'
          sx={{ mt: 2 }}
        >
          <Button onClick={handleClose} variant='outlined'>
            Cancel
          </Button>
          <Button
            onClick={handleConfirmClick}
            variant='contained'
          >
            Confirm
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
