import { getAPI, patchAPI } from "../utils/api"
import endpoints from "../utils/endpoints"

export const getAllSales = (payload, callback, errorcallback) => {
    return (dispatch) => {
        getAPI(
            payload.page && payload.perPage
        ? `${endpoints?.GET_ALL_SALES}?page=${payload.page}&perPage=${payload.perPage}`
        : endpoints?.GET_ALL_SALES,
            {},
            (data) => {
                callback(data);
            }, (err) => {
                errorcallback(err);
            },localStorage.getItem('token')
        )
    }
}

export const updateSales = (id, payload, callBack, errorCallback) => {
    return (dispatch) => {
      patchAPI(
        `${endpoints?.UPDATE_SALES}/${id}`,
        payload,
        (response) => {
          callBack(response);
        },
        (error) => {
          errorCallback(error);
        },
        localStorage.getItem('token')
        )
    }
}