import { Box, Container } from '@mui/material';
import { useInView } from 'framer-motion';
import React, { useEffect, useRef } from 'react';

export default function Section({ mt, mb, children, maxWidth, compActive }) {
  const ref = useRef(null);
  const isInView = useInView(ref);

  useEffect(() => {
    if (typeof compActive === 'function') {
      compActive(isInView);
    }
  }, [isInView, compActive]);
  return (
    <Box component={'section'} mt={mt || 7} pb={mb || 0} ref={ref}>
      <Container maxWidth={maxWidth || 'xl'}>{children}</Container>
    </Box>
  );
}
