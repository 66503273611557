import React, { useState } from 'react';
import Section from '../../components/Section';
import { Box, Container, Grid, List, Stack, Typography } from '@mui/material';
import SectionImg from '../../media/image/section2img.png';
import LogoImg from '../../media/image/logo.png';
import { primaryColor, secondaryColor } from '../../style/color';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import InputField1 from '../../components/mui/InputField1';
import CustomButton from '../../components/mui/CustomButton';
import OTPInput from 'react-otp-input';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [otp, setOtp] = useState('');
  const [otpInputActive, setOtpInputActive] = useState(false);
  const Navigate = useNavigate();
  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
    setIsEmailValid(validateEmail(enteredEmail));
  };
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };
  const handleContinue = (e) => {
    e.preventDefault();
    if (isEmailValid) {
      setOtpInputActive(true);
      console.log('Sending OTP to:', email);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (otp) {
      Navigate('/reset-password');
    }
  };

  const otpStyle = {
    input: {
      minHeight: 40,
      fontSize: 16,
      flexGrow: 1,
      '&+input': { ml: 2 },

      appearance: 'textfield',
    },
    '& input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
      display: 'none',
    },
    '& input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
      display: 'none',
    },
  };
  const inputStyle = {
    ...otpStyle.input,
    width: '15%',
    height: '4rem',
    margin: '1%',
    borderRadius: '8px',
    border: '1px solid gray',
    background: '#fff',
    textAlign: 'center',
    '&:hover, &:active, &:focus': {
      '& .otp-input-arrow': {
        display: 'none',
        border: '1px solid gray',
      },
    },
  };
  return (
    <Section>
      <Container maxWidth={'xl'}>
        <Box mt={4}>
          <img
            src={LogoImg}
            sx={{
              height: { xl: '41.01px', md: '41.01px', xs: '20px' },
              width: '199px',
            }}
            alt='logo'
          />
        </Box>
        <Grid
          container
          spacing={1}
          alignItems={{
            xl: 'center',
            xs: 'flex-start',
            md: 'center',
            sm: 'flex-start',
          }}
          sx={{
            minHeight: 'calc(100vh - 100px)',
            marginTop: { xl: '-5%', xs: '-10%', md: '-10%', sm: '-5%' },
          }}
        >
          <Grid item md={5} xs={12}>
            <Box
              my={7}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                mx: {
                  xs: 0,
                  sm: 0,
                  md: 6,
                  lg: 6,
                  xl: 6,
                },
              }}
            >
              <Box sx={{ pb: 4 }}>
                <Typography variant='h1' component={'h2'}>
                  {otpInputActive ? 'Enter OTP' : 'Forgot Password'}
                </Typography>
                <Typography
                  variant='h5'
                  component={'p'}
                  mt={1}
                  sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                >
                  {otpInputActive
                    ? `We’ve sent a code to  ${email}`
                    : "Don’t worry! It happens. Please enter the email, and we'll send the OTP."}
                </Typography>
              </Box>
              <Box maxWidth={600} mx={'auto'}>
                <form onSubmit={handleSubmit}>
                  <Stack sx={{ marginBottom: '2%' }}>
                    <InputField1
                      label={'Enter Email'}
                      placeholder={'Your Email'}
                      value={email}
                      required
                      maxLength={50}
                      disabled={otpInputActive}
                      onChange={handleEmailChange}
                    />
                  </Stack>
                  {!isEmailValid && (
                    <Stack>
                      <Typography sx={{ color: 'red' }}>
                        Please enter a valid Email ID
                      </Typography>
                    </Stack>
                  )}
                  {!otpInputActive ? (
                    <>
                      <Box sx={{ opacity: '.1' }} mt={3}>
                        <Stack>
                          <Typography variant='p'>Enter OTP</Typography>
                        </Stack>
                        <Stack>
                          <OTPInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            inputType='number'
                            renderSeparator={<span> </span>}
                            renderInput={(props) => (
                              <input
                                {...props}
                                style={inputStyle}
                                disabled={!otpInputActive}
                              />
                            )}
                            isInputNumValid={() => false}
                          />
                        </Stack>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Stack mt={3}>
                        <Typography variant='p'>Enter OTP</Typography>
                      </Stack>
                      <Stack>
                        <Box>
                          <OTPInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            inputType='number'
                            renderSeparator={<span> </span>}
                            renderInput={(props) => (
                              <input
                                {...props}
                                style={inputStyle}
                                disabled={!otpInputActive}
                              />
                            )}
                            isInputNumValid={() => false}
                          />
                        </Box>
                      </Stack>
                    </>
                  )}
                  {!otpInputActive ? (
                    <Stack mt={4}>
                      <CustomButton
                        onClick={handleContinue}
                        variant='contained'
                        fullWidth
                        color='primary'
                        disabled={!validateEmail(email) || email.length === 0}
                      >
                        continue
                      </CustomButton>
                    </Stack>
                  ) : (
                    <Stack mt={4}>
                      <CustomButton
                        onClick={handleSubmit}
                        variant='contained'
                        fullWidth
                        color='primary'
                        disabled={otpInputActive && otp.length !== 6}
                      >
                        Submit
                      </CustomButton>
                    </Stack>
                  )}
                  <Stack mt={3}>
                    <Typography
                      variant='p'
                      flexGrow={1}
                      sx={{
                        textAlign: 'center',
                        color: secondaryColor.main,
                        fontSize: 18,
                      }}
                    >
                      Remember Password?{' '}
                      <Link
                        to='/'
                        style={{
                          color: primaryColor.main,
                          textDecoration: 'none',
                        }}
                      >
                        Sign in
                      </Link>
                    </Typography>
                  </Stack>
                </form>
              </Box>
            </Box>
          </Grid>
          <Grid item md={7} sx={{ display: { md: 'block', xs: 'none' } }}>
            <Box
              sx={{
                my: 2,
                px: 5,
                py: 4,
                minHeight: 'calc(100vh - 200px)',
                display: 'flex',
                alignItems: 'center',
                img: { width: '100%', height: 'auto' },
                borderRadius: 3,
              }}
            >
              <Box>
                <img
                  src={SectionImg}
                  style={{
                    height: '100%',
                    width: '100%',
                    borderRadius: '3px',
                  }}
                  alt='Products'
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};
export default ForgotPassword;
