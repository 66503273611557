import { getAPI } from "../utils/api"
import endpoints from "../utils/endpoints"


export const getAllTransactions = (callBack, errorCallback) => {
    return (dispatch) => {
        getAPI(
            endpoints?.GET_ALL_TRANSACTIONS,
            {},
            (data) => {
                callBack(data);
            },
            (err) => {
                errorCallback(err);
            },localStorage.getItem('token')
        );
    }
}