import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

export const otpStyle = {
  div: { justifyContent: 'space-between' },
  input: {
    border: '1px solid gray',
    minHeight: 40,
    fontSize: 16,
    flexGrow: 1,
    '&+input': { ml: 2 },
    '-moz-appearance': 'textfield',
    appearance: 'textfield',
  },
  '& input::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
    display: 'none',
  },
  '& input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
    display: 'none',
  },
};
// SwitchStyles.js

const IOSSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor:  '#16DBCC',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default IOSSwitch;

export const editProfileStyles = {
  container: {
    borderRadius: '20px',
    background: 'white',
  },
  switchContainer: {
    width: {
      xl: '50%',
      lg: '50%',
      md: '100%',
      sm: '100%',
      xs: '100%',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: { xl: 'end', md: 'center' },
    alignItems: 'center',
    flex: '1',
    width: '100%',
    marginTop: '1rem',
    marginBottom: '3rem',
  },
  saveButton: {
    textTransform: 'none',
    borderRadius: '10px',
    width: { xl: '8rem', md: '100%', xs: '100%', sm: '100%' },
  },
};
