import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { secondaryColor } from "../../style/color";

export const ProfileCard = ({
  username,
  phone,
  image,
  dis,
  showMessageButton = true,
  email,
}) => {
  const handleNoContact = (type) => {
    alert(`No ${type} found`);
  };

  return (
    <Card
      sx={{
        borderRadius: "1.25rem",
        height: { xl: "12.64rem" },
        minHeight: "12.69rem",
        width: { xl: "13rem" },
        boxShadow: "none",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column",
      }}
    >
      <CardContent className="textArea">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {image ? (
            <Avatar
              sx={{ width: "3.938rem", height: "3.938rem", mt: 2 }}
              src={image?.pictureUrl}
              alt="image"
            />
          ) : (
            <Avatar sx={{ width: "3.938rem", height: "3.938rem", mt: 2 }} />
          )}
        </Box>
        <Stack mt={"0.313rem"}>
          <Typography
            variant="medium"
            component={"h3"}
            mx={"auto"}
            sx={{
              fontSize: { xl: "17.27", md: "17px", xs: "10px", sm: "10px" },
              fontWeight: "bold",
            }}
          >
            {username ? username : phone}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="small" color={secondaryColor.light} mx={"auto"}>
            {dis}
          </Typography>
        </Stack>

        <Stack
          mt={"0.469rem"}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            width="1.25rem"
            height="1.25rem"
            sx={{ marginRight: "0.625rem" }}
            component="a"
            href={email ? `mailto:${email}` : "#"}
            onClick={(e) => {
              if (!email) {
                e.preventDefault();
                handleNoContact("email");
              }
            }}
          >
            <img src="/Email.svg" alt="email" />
          </IconButton>
          <IconButton
            width="1.25rem"
            height="1.25rem"
            component="a"
            href={phone ? `tel:${phone}` : "#"}
            onClick={(e) => {
              if (!phone) {
                e.preventDefault();
                handleNoContact("phone");
              }
            }}
          >
            <img src="/Phone.svg" alt="phone" />
          </IconButton>

          {showMessageButton && (
            <IconButton width="1.25rem" height="1.25rem">
              <img src="/Chat.svg" alt="message" />
            </IconButton>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
