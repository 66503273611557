import React from 'react';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import { Avatar, Badge, Box, Divider, Typography } from '@mui/material';

const notificationData = [
  {
    id: 1,
    text: 'Hello, how are you?',
    date: '2024-01-24T08:30:00Z',
  },
  {
    id: 2,
    text: "I'm doing well, thank you!",
    date: '2024-01-24T09:15:00Z',
  },
  {
    id: 3,
    text: 'What are your plans for today?',
    date: '2024-01-24T10:00:00Z',
  },
  {
    id: 4,
    text: 'I have a meeting at 2 PM.',
    date: '2024-01-24T14:00:00Z',
  },
  {
    id: 5,
    text: 'Good luck with your meeting!',
    date: '2024-01-24T14:30:00Z',
  },
  {
    id: 6,
    text: "Thank you! I'll need it.",
    date: '2024-01-24T15:00:00Z',
  },
  {
    id: 7,
    text: 'Have a great day!',
    date: '2024-01-24T16:00:00Z',
  },
  {
    id: 8,
    text: 'You too!',
    date: '2024-01-24T17:00:00Z',
  },
  {
    id: 9,
    text: "Let's catch up soon.",
    date: '2024-01-24T18:00:00Z',
  },
  {
    id: 10,
    text: 'Sure, sounds good!',
    date: '2024-01-24T19:00:00Z',
  },
];

const NotificationDropdown = ({ open, onClose, anchorEl }) => {
  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchorEl={anchorEl}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      style={{ marginTop: '4%' }}
      PaperProps={{
        sx: { borderRadius: '20px' },
      }}
    >
      <List
        sx={{
          maxHeight: '60vh',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Box mx={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography>Notifications</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {' '}
            <Typography>Mark all as read</Typography>
            <img src='/outline.svg' alt='outline' />
          </Box>
        </Box>
        {notificationData.map((key, index) => (
          <React.Fragment key={key.id}>
            <ListItem>
              <ListItemIcon>
                <Badge>
                  <Avatar sx={{ width: '25px', height: '25px' }} />
                </Badge>
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant='h5'>{key.text}</Typography>}
                secondary={
                  <Typography variant='caption' color={'#A5ACB8'}>
                    {new Date(key.date).toLocaleString()}
                  </Typography>
                }
              />
            </ListItem>
            {index !== notificationData.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Popover>
  );
};

export default NotificationDropdown;
