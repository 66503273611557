import { getAPI } from "../utils/api"
import endpoints from "../utils/endpoints"

export const getAllOrders = (payload, callback, errorcallback) => {
    return (dispatch) => {
        getAPI(
            payload.page && payload.perPage
        ? `${endpoints?.GET_ALL_ORDERS}?page=${payload.page}&perPage=${payload.perPage}`
        : endpoints?.GET_ALL_ORDERS,
            {},
            (data) => {
                callback(data);
            }, (err) => {
                errorcallback(err);
            },localStorage.getItem('token')
        )
    }
}

export const getWeeklyOrders = (callback, errorcallback) => {
    return (dispatch) => {
        getAPI(
            endpoints?.GET_WEEKLY_ORDERS,
            {},
            (data) => {
                callback(data);
            }, (err) => {
                errorcallback(err);
            },localStorage.getItem('token')
        )
    }
}