import React, { useEffect, useState } from 'react'
import HomeLayout from '../../asset/layout/HomeLayout'
import { getAllCampaignLeads } from '../../actions/userAuth';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Stack,
  Grid,
} from "@mui/material";
import dayjs from 'dayjs';
import CustomButton from '../../components/mui/CustomButton';
import ResponsiveDatePickers from '../../components/mui/PichDate';
import SearchBar from '../../components/mui/SearchBar';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { format } from 'date-fns';
const columns = [
  "First Name",
  "Last Name",
  "Email",
  "Phone Number",
  "Company Name",
  "Created On",
 

];
const CampaignLeads = () => {
      const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
  
 
 
    const dispatch = useDispatch()
   
  
  
    const getData = () => {
    setLoading(true);
    dispatch(
      getAllCampaignLeads(
       
        (data) => {
          
console.log(data?.data?.data)
         
          setUserData(data?.data?.data);

          setLoading(false);
        },
        (error) => {
          console.error("Error fetching users:", error);
          setLoading(false);
        }
      )
    );
    };

     useEffect(() => {
    getData();
     }, [dispatch]);
     const handleSearch = (term) => {
    setSearchTerm(term);
  };
    const formatDateIndian = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd/MM/yyyy", {
      timeZone: "Asia/Kolkata",
    });
  };
    const exportToExcel = () => {
        const header = [
            "First Name",
  "Last Name",
  "Email",
  "Phone Number",
  "Company Name",
  "Created On",
        ];
        const dataToExport = userData?.map((user) => [
            user?.firstName,
            user?.lastName,
      user?.email,
      user?.phoneNumber,
      user?.companyName,
      user?.updatedAt ? formatDateIndian(user?.updatedAt) : "--",
      
    ]);

    dataToExport.unshift(header);

    const worksheet = XLSX.utils.aoa_to_sheet(dataToExport);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users Data");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });

    saveAs(blob, "Users_data.xlsx");
    }

  
  return (
      <HomeLayout>
           <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={3}
      >
        <Typography variant="h4" my={3}>
          Campaign Leads
        </Typography>
        <CustomButton
          variant="contained"
          width={{ md: "22%", sm: "50%", xs: "40%" }}
          height={"rem"}
          type="button"
          onClick={exportToExcel}
        >
          Export Users
        </CustomButton>
      </Box>

     
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : userData.length > 0 ? (
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "25px",
            overflowX: "scroll",
            my: 3,
          }}
        >
          <Table sx={{ maxWidth: "auto" }}>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#11409B",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                whiteSpace: "nowrap",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              {userData.map((row, index) => (
                <TableRow key={index}>
                 
                  <TableCell>{row?.firstName? row?.firstName : "--"}</TableCell>
                  <TableCell>{row?.lastName? row?.lastName : "--"}</TableCell>
                  <TableCell>{row?.email ? row?.email : "--"}</TableCell>
                  <TableCell>+91 {row?.phoneNumber}</TableCell>
                  <TableCell>{row?.companyName}</TableCell>
                  
                  <TableCell>
                    {row.updatedAt
                      ? dayjs(row?.updatedAt)?.format("MM-DD-YYYY ")
                      : ""}
                  </TableCell>
                  <TableCell>
                    {/* <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={row.active}
                          onChange={(e) =>
                            handleConfirmationOpen(row, e.target.checked)
                          }
                        />
                      }
                    /> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1" sx={{ textAlign: "center", my: 3 }}>
          No results found.
        </Typography>
      )}
    </HomeLayout>
  )
}

export default CampaignLeads
