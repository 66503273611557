import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./globalStyles.css";
import Theme from "./asset/Theme/Theme";

import { Login } from "./pages/User/Login";
import SignUp from "./pages/User/SignUp";
import ForgotPassword from "./pages/User/ForgotPassword";
import ResetPassword from "./pages/User/ResetPassword";
import { DashBoard } from "./pages/dashBoard/DashBoard";
import UserProfile from "./pages/UserProfile";
import Transactions from "./pages/transactions/Transactions";
import EditProfile from "./pages/editprofile/EditProfile";

import { OderManagement } from "./pages/orderManagement/OderManagement";

import { ClientManagement } from "./pages/clientmanagement/ClientManagement";
import { BannerManagement } from "./pages/bannermanagement/BannerManagement";
import { SalesManagement } from "./pages/salesManagement/salesManagement"
import { BackendUser } from "./pages/backenduser/BackendUser";
import { BrandManagement } from "./pages/brandmanagement/BrandManagement";
import { DiscountCoupon } from "./pages/discountCoupon/DiscountCoupon";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getUserByToken } from "./actions/userAuth";
import { TicketManagement } from "./pages/ticketManagement/TicketManagement";
import { RewardManagement } from "./pages/rewardManagement/RewardManagement";
import ClientDetails from "./pages/clientmanagement/ClientDetails";
import Lead from "./pages/Lead";
import CampaignLeads from "./pages/CampaignLeads/CampaignLeads";
import UsersTab from "./pages/backenduser/UserTab";
import BudgetreeLeads from "./pages/budgetree leads/BudgetreeLeads";
import Brand from "./pages/brandmanagement/Brand";

export default function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      dispatch(
        getUserByToken(
          (data) => {
            dispatch({
              type: "LOG_IN",
              payload: data.data.data,
            });
          },

          (err) => {
            console.log(err);
          },
          localStorage.getItem("token")
        )
      );
    }
  }, []);

  return (
    <Theme>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/user" element={<UserProfile />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/edit-profile" element={<EditProfile />} />

          <Route path="order-management" element={<OderManagement />} />
          <Route path="brand-management" element={<Brand />} />
          <Route path="discount-coupon" element={<DiscountCoupon />} />
          <Route path="/business-user" element={<ClientManagement />} />
          <Route path="Banner-management" element={<BannerManagement />} />
          <Route path="/backend-user" element={<UsersTab />} />
          <Route path="ticket-management" element={<TicketManagement />} />
          <Route path="sales-management" element={<SalesManagement />} />
          <Route path="reward-management" element={<RewardManagement />} />
          <Route path="/client-detail/:id" element={<ClientDetails />} />
          <Route path="/campaign-leads" element={<CampaignLeads />} />
          <Route path="/budgetree-leads" element={<BudgetreeLeads />} />
        </Routes>
      </BrowserRouter>
    </Theme>
  );
}
