import React, { useEffect, useState } from "react";
import HomeLayout from "../../asset/layout/HomeLayout";
import CustomButton from "../../components/mui/CustomButton";
import {
  Box,
  Container,
  IconButton,
  Typography,
  Grid,
  CircularProgress,
  Stack,
} from "@mui/material";
import MyTable from "../../components/Table";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import SearchBar from "../../components/mui/SearchBar";
import DropDownMenu from "../../components/mui/DropDownMenu";
import DatePickerComponent from "../../components/DatePickerComponent";
import { MasterCard } from "../../components/Cards/MasterCard";
import { useDispatch } from "react-redux";
import { getAllTransactions } from "../../actions/transactionsAuth";
import { useNavigate } from "react-router-dom";
import ResponsiveDatePickers from "../../components/mui/PichDate";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { format } from "date-fns-tz";

const iconData = [
  {
    src: "/CardTransfer.svg",
    alt: "Total",
    name: "Total",
    bgColor: "#FFF5D9",
    count: 0,
  },
  {
    src: "/CardRecive.svg",
    alt: " Pending",
    name: " Pending",
    bgColor: "#E7EDFF",
    count: 0,
  },
  {
    src: "/CardSend.svg",
    alt: " Failed",
    name: " Failed",
    bgColor: "#FFE0EB",
    count: 0,
  },
  {
    src: "/ClipboardRemove.svg",
    alt: "This week",
    name: "This week",
    bgColor: "#DCFAF8",
    count: 0,
  },
];
const menuItems = [{ label: "Deposit" }, { label: "Withdraw" }];

const Transactions = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);

  const [totalTransactions, setTotalTransactions] = useState();
  const [pendingTransactions, setPendingTransactions] = useState();
  const [failedTransactions, setFailedTransactions] = useState();
  const [thisWeekTransactions, setThisWeekTransactions] = useState();
  const [categorizedTransactions, setCategorizedTransactions] = useState({
    Pending: [],
    Failed: [],
    Paid: [],
  });
  const Navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      Navigate("/");
    }
  }, []);

  useEffect(() => {
    const today = new Date();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
    const endOfWeek = new Date(today);
    endOfWeek.setDate(endOfWeek.getDate() + (6 - endOfWeek.getDay()));

    dispatch(
      getAllTransactions(
        (data) => {
          console.log("Fetched transactions:", data.data.data.Transactions);

          const transactionsWithDates = data.data.data.Transactions.map(
            (item) => ({
              ...item,
              createdAt: new Date(item.createdAt),
              updatedAt: new Date(item.updatedAt),
            })
          );

          const filter = transactionsWithDates.filter((item) => {

              console.log("Customer Email:", item?.customerDetails?.customerEmail);
  console.log("Search Term:", searchTerm);
            const matchQuery =
              searchTerm !== "" &&
              (item?.orderNote
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase()) ||
                item?.orderId
                  ?.toLowerCase()
                  ?.includes(searchTerm?.toLowerCase()) ||
                item?.customerDetails?.customerId?.email
                  ?.toLowerCase()
                  ?.includes(searchTerm?.toLowerCase()) ||
                item?.customerDetails?.customerPhone
                  ?.toLowerCase()
                  ?.includes(searchTerm?.toLowerCase()));

            const dateFromUTC = selectedDateFrom
              ? new Date(selectedDateFrom).setHours(0, 0, 0, 0)
              : null;
            const dateToUTC = selectedDateTo
              ? new Date(selectedDateTo).setHours(23, 59, 59, 999)
              : null;

            const matchDateFrom = dateFromUTC
              ? item.createdAt.getTime() >= dateFromUTC
              : true;
            const matchDateTo = dateToUTC
              ? item.createdAt.getTime() <= dateToUTC
              : true;
            const matchDate = matchDateFrom && matchDateTo;

            console.log("Transaction Date:", item.createdAt);
            console.log(
              "Date From:",
              selectedDateFrom ? new Date(selectedDateFrom) : "not set"
            );
            console.log(
              "Date To:",
              selectedDateTo ? new Date(selectedDateTo) : "not set"
            );
            console.log("Match Date:", matchDate);

            if (
              searchTerm !== "" &&
              selectedDateFrom !== "" &&
              selectedDateTo !== ""
            ) {
              return matchQuery && matchDate;
            } else if (
              searchTerm === "" &&
              selectedDateFrom !== "" &&
              selectedDateTo !== ""
            ) {
              return matchDate;
            } else if (
              searchTerm !== "" &&
              selectedDateFrom === "" &&
              selectedDateTo === ""
            ) {
              return matchQuery;
            } else {
              return true;
            }
          });

          const categorizedTransactions = {
            Pending: filter.filter(
              (transaction) => transaction.orderStatus === "ACTIVE"
            ),
            Failed: filter.filter(
              (transaction) => transaction.orderStatus === "EXPIRED"
            ),
            Paid: filter.filter(
              (transaction) => transaction.orderStatus === "PAID"
            ),
            ThisWeek: filter.filter(
              (transaction) =>
                transaction.createdAt >= startOfWeek &&
                transaction.createdAt <= endOfWeek
            ),
          };

          setCategorizedTransactions(categorizedTransactions);
          setTransactions(filter);
          setLoading(false);

          setTotalTransactions(filter.length);
          setPendingTransactions(categorizedTransactions.Pending.length);
          setFailedTransactions(categorizedTransactions.Failed.length);
          setThisWeekTransactions(categorizedTransactions.ThisWeek.length);
        },
        (err) => {
          console.log("Error fetching transactions:", err);
          setLoading(false);
        }
      )
    );
  }, [searchTerm, selectedDateFrom, selectedDateTo, dispatch]);

  const handleSearchClear = () => {
    setSearchTerm("");

    setSelectedDateFrom(null);
    setSelectedDateTo(null);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);

  console.log("trans", transactions);

  const paginatedData = transactions.slice(
    (currentPage - 1) * countPerPage,
    currentPage * countPerPage
  );

  const formatDateIndian = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd/MM/yyyy", {
      timeZone: "Asia/Kolkata",
    });
  };

  const exportToExcel = () => {
    const header = [
      "Order Id",
      "Customer Email",
      "Customer Number",
      "Date",
      "Amount",
    ];

    const dataToExport = transactions.map((user) => [
      user.orderId,
      user.customerDetails?.customerId?.email,
      user.customerDetails.customerPhone,
      user.createdAt ? formatDateIndian(user.createdAt) : "--",
      user.orderAmount,
    ]);

    dataToExport.unshift(header);

    const worksheet = XLSX.utils.aoa_to_sheet(dataToExport);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users Data");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });

    saveAs(blob, "Users_data.xlsx");
  };

  return (
    <HomeLayout>
      <Container maxWidth="xl">
        <Grid container spacing={2} my={4}>
          {iconData?.map((data, index) => (
            <Grid
              item
              key={index}
              md={4}
              xs={6}
              xl={3}
              sm={6}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <MasterCard
                src={data.src}
                alt={data.alt}
                name={data.name}
                bgColor={data.bgColor}
                count={
                  index === 0
                    ? totalTransactions
                    : index === 1
                    ? pendingTransactions
                    : index === 2
                    ? failedTransactions
                    : thisWeekTransactions
                }
              />
            </Grid>
          ))}
        </Grid>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          my={3}
        >
          <Typography variant="h4">Transactions</Typography>
          <CustomButton
            variant="contained"
            width={{ md: "22%", sm: "50%", xs: "40%" }}
            height={"rem"}
            type="button"
            onClick={exportToExcel}
          >
            Export transactions
          </CustomButton>
        </Box>
        <Grid container spacing={2} alignItems="center">
          <Grid item md={4} xs={12}>
            <SearchBar
              onSearch={handleSearch}
              searchTerm={searchTerm}
              height={"2.5rem"}
            />
          </Grid>

          <Grid item md={3} xs={12}>
            <ResponsiveDatePickers
              selectedDate={selectedDateFrom}
              setSelectedDate={setSelectedDateFrom}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ResponsiveDatePickers
              selectedDate={selectedDateTo}
              setSelectedDate={setSelectedDateTo}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <CustomButton
              variant="contained"
              onClick={handleSearchClear}
              width={"100%"}
              height={"2.5rem"}
            >
              clear
            </CustomButton>
          </Grid>
        </Grid>
        <Box mt={4} sx={{ overflowX: "auto", width: "100%" }}>
          {loading ? (
            <Typography textAlign={"center"} my={2}>
              <CircularProgress size={24} />
            </Typography>
          ) : (
            <MyTable transactions={paginatedData} />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography>
              {(currentPage - 1) * countPerPage + 1} -{" "}
              {Math.min(currentPage * countPerPage, transactions.length)} of{" "}
              {transactions.length}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography>Rows per page:</Typography>
            <select
              style={{
                marginTop: "-5px",
                background: "transparent",
                border: "none",
              }}
              value={countPerPage}
              onChange={(e) => setCountPerPage(Number(e.target.value))}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
            <Box sx={{ ml: 2 }}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <IconButton
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                  disabled={currentPage === 1}
                  sx={{
                    border: "1px solid",
                    borderRadius: "10px",
                    borderColor: "mute.main",
                    bgcolor: "white.main",
                    boxShadow: "2px 2px 0px #999",
                    background: "#fff",
                  }}
                >
                  <KeyboardArrowLeftIcon
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: currentPage === 1 ? "#999" : "#000",
                    }}
                  />
                </IconButton>
                <Typography variant="text" component="span">
                  {currentPage}/{Math.ceil(transactions.length / countPerPage)}
                </Typography>
                <IconButton
                  onClick={() =>
                    setCurrentPage((prev) =>
                      Math.min(
                        prev + 1,
                        Math.ceil(transactions.length / countPerPage)
                      )
                    )
                  }
                  disabled={
                    currentPage ===
                    Math.ceil(transactions.length / countPerPage)
                  }
                  sx={{
                    border: "1px solid",
                    borderRadius: "10px",
                    borderColor: "mute.main",
                    bgcolor: "white.main",
                    boxShadow: "2px 2px 0px #999",
                    background: "#fff",
                  }}
                >
                  <KeyboardArrowRightIcon
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: "#000",
                    }}
                  />
                </IconButton>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Container>
    </HomeLayout>
  );
};

export default Transactions;
