import {
  Box,
  Modal,
  Typography,
  Grid,
  Divider,
  IconButton,
  Tab,
  Avatar,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  createComments,
  getAllComments,
} from '../actions/ticketAuth';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import InputField1 from './mui/InputField1';
import CustomButton from './mui/CustomButton';
import { enqueueSnackbar } from 'notistack';

const ViewTicketModal = ({ open, handleClose, ticket }) => {
  const [tabValue, setTabValue] = useState('1');
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [writeComment, setWriteComment] = useState('');
  const dispatch = useDispatch();
  console.log('ticket id', ticket._id);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (ticket) {
      setLoading(true);
      dispatch(
        getAllComments(
          ticket._id,
          (response) => {
            console.log(
              'comments data',
              response.data.data.comments
            );
            setComments(response.data.data.comments);
            setLoading(false);
          },
          (error) => {
            console.error('Error fetching comments:', error);
            setLoading(false);
          }
        )
      );
    }
  }, [ticket, dispatch]);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '70%', xs: '90%' },
    height: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 6,
    outLine: 'none',
    overflowY: 'auto',
    borderRadius: '20px',
    WebkitOverflowScrolling: 'touch',
  };

  const cellStyle = {
    border: '1px solid #ddd',
    padding: '8px',
  };
  const handleCommentSubmit = () => {
    const payload = { comment: writeComment };
    dispatch(
      createComments(
        payload,
        ticket._id,
        (response) => {
          enqueueSnackbar('Comment submitted successfully', {
            variant: 'success',
          });
          setComments([...comments, response.data.data.comment]);
          setWriteComment('');
          setTabValue('1');
        },
        (error) => {
          console.error('Error creating comment:', error);
        }
      )
    );
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <IconButton
          edge='end'
          color='inherit'
          onClick={handleClose}
          aria-label='close'
          sx={{
            position: 'absolute',
            top: '15px',
            right: '15px',
          }}
        >
          <HighlightOffOutlinedIcon />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            textAlign: 'center',
          }}
        >
          {ticket ? (
            <Grid container alignItems={'center'}>
              <Grid item md={7} xs={12}>
                <Box
                  sx={{ overflowY: 'scroll', height: '25rem' }}
                >
                  <Typography textAlign={'start'} variant='h4'>
                    Description
                  </Typography>
                  <Typography
                    textAlign={'start'}
                    variant='h6'
                    fontWeight={400}
                    mt={3}
                  >
                    {ticket?.ticketDescription}
                  </Typography>
                  <Box sx={{ width: '100%', marginTop: '16px' }}>
                    <TabContext value={tabValue}>
                      <TabList onChange={handleChange}>
                        <Tab label='All Comments' value='1' />
                        <Tab label='Write Comments' value='2' />
                      </TabList>
                      <TabPanel value='1'>
                        {loading ? (
                          <Typography>
                            Loading comments...
                          </Typography>
                        ) : (
                          <>
                            {comments.length > 0 ? (
                              comments.map((comment, index) => (
                                <Box py={2}>
                                  <Grid container spacing={2}>
                                    <Grid item md={2}>
                                      {' '}
                                      <Typography
                                        variant='p'
                                        fontWeight={600}
                                        key={index}
                                        fontSize={'.8rem'}
                                      >
                                        {
                                          comment?.createdBy
                                            ?.username
                                        }
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      md={7}
                                      textAlign={'start'}
                                      py={3}
                                    >
                                      {' '}
                                      <Typography key={index}>
                                        {comment?.comment}
                                      </Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                      {' '}
                                      <Typography key={index}>
                                        {comment?.createdAt
                                          ? dayjs(
                                              comment?.createdAt
                                            ).format(
                                              'MM-DD-YYYY'
                                            )
                                          : '--'}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Divider />
                                </Box>
                              ))
                            ) : (
                              <Typography>
                                No comments found
                              </Typography>
                            )}
                          </>
                        )}
                      </TabPanel>
                      <TabPanel value='2'>
                        <TextField
                          placeholder='Add your comment....'
                          multiline
                          minRows={2}
                          fullWidth
                          variant='outlined'
                          value={writeComment}
                          onChange={(e) =>
                            setWriteComment(e.target.value)
                          }
                        />
                        <Box mt={3}>
                          {' '}
                          <CustomButton
                            variant='contained'
                            onClick={handleCommentSubmit}
                          >
                            Submit
                          </CustomButton>
                        </Box>
                      </TabPanel>
                    </TabContext>
                  </Box>
                </Box>
              </Grid>
              <Grid
                xs={12}
                item
                md={5}
                alignItems={'center'}
                justifyContent='center'
                p={1}
              >
                <Typography mb={2} variant='h4'>
                  Details
                </Typography>
                <Grid container>
                  <Grid item xs={6} sx={cellStyle}>
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                    >
                      Ticket Name:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={cellStyle}>
                    <Typography variant='body1'>
                      {ticket?.ticketName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6} sx={cellStyle}>
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                    >
                      Description:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={cellStyle}>
                    <Typography variant='body1'>
                      {ticket?.ticketDescription}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6} sx={cellStyle}>
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                    >
                      Assigned To:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={cellStyle}>
                    <Typography variant='body1'>
                      {ticket?.assignedTo[0]?.username ||
                        ticket?.assignedTo[0]?.phone ||
                        'Unknown'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6} sx={cellStyle}>
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                    >
                      Status:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={cellStyle}>
                    <Typography variant='body1'>
                      {ticket?.status}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={cellStyle}>
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                    >
                      Alloted Time:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={cellStyle}>
                    <Typography variant='body1'>
                      {ticket?.allotedTime?.number}
                      <Typography component={'span'}>
                        {ticket?.allotedTime?.unit}
                      </Typography>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6} sx={cellStyle}>
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                    >
                      Created By
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={cellStyle}>
                    <Typography variant='body1'>
                      {ticket?.createdBy?.username ||
                        ticket?.createdBy?.phone}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6} sx={cellStyle}>
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                    >
                      Priority:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={cellStyle}>
                    <Typography variant='body1'>
                      {ticket.priority}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Typography variant='body1'>
              No ticket selected
            </Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ViewTicketModal;
