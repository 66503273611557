// import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { MdCampaign } from "react-icons/md";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { useState } from "react";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

import { Avatar, Fade, Menu, MenuItem } from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";
import NotificationsDropdown from "../../components/mui/NotificationsDropdown";
import { useDispatch } from "react-redux";
import { userLogout } from "../../actions/userAuth";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { LiaUsersSolid } from "react-icons/lia";
import { IoTicketOutline } from "react-icons/io5";
import { Diversity3 } from "@mui/icons-material";
const drawerWidth = 270;

const drawerItems = [
  {
    icon: <img src="/home2.svg" alt="Dashboard" />,
    icon2: <img src="/home.svg" alt="Dashboard" />,
    text: "Dashboard",
    LinkUrl: "/dashboard",
  },
  {
    icon: <img src="/user2.svg" alt="User" />,
    icon2: <img src="/user.svg" alt="User" />,
    text: "User",
    LinkUrl: "/user",
  },
  {
    icon: <img src="/Transations2.svg" alt="Transactions" />,
    icon2: <img src="/Transactions.svg" alt="Transactions" />,
    text: "Transactions",
    LinkUrl: "/transactions",
  },
  {
    icon: <img src="/cart.svg" alt="Transactions" />,
    icon2: <img src="/bCart.svg" alt="Transactions" />,
    text: "Order Management",
    LinkUrl: "/order-management",
  },
  {
    icon: <img src="/bBrandManagement.svg" alt="Transactions" />,
    icon2: <img src="/brandmanag.svg" alt="Transactions" />,
    text: "Brand Management",
    LinkUrl: "/brand-management",
  },
  {
    icon: <img src="/bTicketSale.svg" alt="Transactions" />,
    icon2: <img src="/discoupon.svg" alt="Transactions" />,
    text: "Discount Coupon",
    LinkUrl: "/discount-coupon",
  },
  {
    icon: <img src="/bClientManagement.svg" alt="Transactions" />,
    icon2: <img src="/Usersmanag.svg" alt="Transactions" />,
    text: "Business User",
    LinkUrl: "/business-user",
  },
  {
    icon: <img src="/bBackendUser.svg" alt="Transactions" />,
    icon2: <img src="/clientmang.svg" alt="Transactions" />,
    text: "Backend User",
    LinkUrl: "/backend-user",
  },
  {
    icon: <img src="/bBannermanagement.svg" alt="Transactions" />,
    icon2: <img src="/backenduser.svg" alt="Transactions" />,
    text: "Banner Management",
    LinkUrl: "/Banner-management",
  },
  {
    icon: <IoTicketOutline width={"12rem"} />,
    icon2: <IoTicketOutline />,
    text: "Ticket Management",
    LinkUrl: "/ticket-management",
  },
  {
    icon: <IoTicketOutline width={"12rem"} />,
    icon2: <IoTicketOutline />,
    text: "Sales Management",
    LinkUrl: "/sales-management",
  },
  {
    icon: <EmojiEventsIcon width={"12rem"} />,
    icon2: <EmojiEventsIcon color="blue" />,
    text: "Reward Management",
    LinkUrl: "/reward-management",
  },
  {
    icon: <LiaUsersSolid width={"12rem"} fontSize={"2rem"} />,
    icon2: <LiaUsersSolid fontSize={"2rem"} />,
    text: "Campaign Leads ",
    LinkUrl: "/campaign-leads",
  },
  {
    icon: <Diversity3 width={"12rem"} fontSize={"large"} />,
    icon2: <Diversity3 fontSize={"large"} />,
    text: "Budgetree Leads ",
    LinkUrl: "/budgetree-leads",
  },
];

function HomeLayout(props) {
  const { window, children } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const Navigator = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const userRole = localStorage.getItem("SiriAdminUserRole");

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);

  const handleNotificationClose = () => {
    setAnchorEl(null);
    setNotificationOpen(false);
  };

  const filteredDrawerItems = drawerItems.filter((item, index) => {
    if (userRole === "superAdmin") {
      return true;
    } else if (userRole === "admin") {
      switch (user.hasAccessTo) {
        case "Client management":
          return [0, 6, 11].includes(index);
        case "Order management":
          return [0, 2, 3, 11].includes(index);
        case "Brand management":
          return [0, 1, 4, 5, 8, 11].includes(index);
        default:
          return false;
      }
    } else {
      return false;
    }
  });
  const isActive = (linkUrl) => location.pathname === linkUrl;

  const drawer = (
    <div>
      <Box
        my={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="/logo.webp"
          alt="logo"
          style={{ width: "auto", height: "3.5rem" }}
        />
      </Box>

      <List>
        {filteredDrawerItems.map((item, index) => (
          <ListItem disablePadding key={index}>
            {" "}
            <ListItemButton
              onClick={() => {
                Navigator(item.LinkUrl);
              }}
            >
              <div
                style={{
                  height: "38px",
                  borderRadius: "0 10px 10px 0",
                  border: "3px solid ",
                  marginLeft: "-8%",
                  color: isActive(item.LinkUrl) ? "#11409B" : "white",
                  "&:hover": {
                    color: "#11409B",
                  },
                }}
              ></div>

              <ListItemIcon
                style={{
                  marginLeft: "5%",
                  color: isActive(item.LinkUrl) ? "#11409B" : "gray",
                  "&:hover": {
                    color: "#11409B",
                  },
                }}
              >
                {isActive(item.LinkUrl) ? item.icon : item.icon2}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={{
                  color: isActive(item.LinkUrl) ? "#11409B" : "gray",
                  "&:hover": {
                    color: "#11409B",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const [anchorEl1, setAnchorEl1] = useState(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl1);
  const handleClick = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const navigate = useNavigate();
  const handleClose = () => {
    dispatch(
      userLogout(
        {},
        (data) => {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("refreshToken");

          localStorage.clear();

          dispatch({ type: "LOG_OUT" });

          navigate("/");
        },
        (error) => {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("refreshToken");

          localStorage.clear();

          navigate("/");
        }
      )
    );
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/");
      return;
    }
    const allowedRoutes = drawerItems
      .filter((item, index) => {
        if (userRole === "superAdmin") {
          return true;
        } else if (userRole === "admin") {
          switch (user.hasAccessTo) {
            case "Client management":
              return (
                [0, 6, 11].includes(index) ||
                item.LinkUrl.startsWith("/client-detail")
              );
            case "Order management":
              return [0, 2, 3, 11].includes(index);
            case "Brand management":
              return [0, 1, 4, 5, 8, 11].includes(index);
            default:
              return false;
          }
        } else {
          return false;
        }
      })
      .map((item) => item.LinkUrl);

    const currentRoute = location.pathname;
    if (
      !allowedRoutes.includes(currentRoute) &&
      !currentRoute.startsWith("/client-detail")
    ) {
      enqueueSnackbar("You do not have permission to access this page!", {
        variant: "error",
      });
      setTimeout(() => {
        Navigator("/dashboard");
      }, 1500);
    }
  }, [location.pathname, user, userRole, Navigator, enqueueSnackbar]);
  const handleMenuClose = () => {
    setAnchorEl1(null);
  };
  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        height: "auto",
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          backgroundColor: "white",
        }}
      >
        <Toolbar color="white">
          <IconButton
            color="pink"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Box>
              <IconButton
                size="small"
                edge="end"
                aria-label="account of current user"
                color="inherit"
                sx={{
                  backgroundColor: "none",
                }}
                id="demo-positioned-menu"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <Avatar />
              </IconButton>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl1}
                open={open}
                TransitionComponent={Fade}
                onClose={handleMenuClose}
                onMouseLeave={handleMenuClose}
              >
                <MenuItem onClick={handleClose}>Logout</MenuItem>
              </Menu>

              <NotificationsDropdown
                open={notificationOpen}
                onClose={handleNotificationClose}
                anchorEl={anchorEl}
              />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { md: drawerWidth },
          flexShrink: { md: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          background: "#F5F7FA",

          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "0",
          },
          scrollbarWidth: "none",
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

HomeLayout.propTypes = {
  window: PropTypes.func,
  children: PropTypes.node,
};

export default HomeLayout;
