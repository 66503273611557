"use client";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAllBrands } from "../../actions/brandsAuth";
import { useDispatch } from "react-redux";
import { addBrands } from "../../actions/userAuth";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";

export const BrandManagement = () => {
  const [brands, setBrands] = useState([]);
  const [selectedBrandIds, setSelectedBrandIds] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAllBrands(
        { isActive: true },
        (data) => {
          setBrands(data.data.data.brands);
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }, [dispatch]);

  const handleBrandSelect = (id) => {
    setSelectedBrandIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((brandId) => brandId !== id)
        : [...prevSelected, id]
    );
  };
  const { id } = useParams();
  const handleAddBrands = () => {
    let payload = { brandIds: selectedBrandIds };
    dispatch(
      addBrands(
        id,
        payload,
        (data) => {
          console.log(data);
          enqueueSnackbar("Added Successfully!", { variant: "success" });
        },
        (err) => {
          console.log(err);
          enqueueSnackbar("Error Try Again!", { variant: "error" });
        }
      )
    );
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          my: 4,
        }}
      >
        <Button
          variant="contained"
          sx={{
            textTransform: "none",
          }}
          onClick={handleAddBrands}
        >
          Add Brands
        </Button>
      </Box>
      <Box>
        <Grid container spacing={2}>
          {brands.map((brand, index) => (
            <Grid item xs={6} md={2} key={index}>
              <Card
                elevation={0}
                sx={{
                  p: { md: "0px 10px 10px", xs: 1 },
                  overflow: "hidden",
                  borderRadius: "16px",
                  bgcolor: "rgba(182, 196, 224, 0.4)",
                  position: "relative",
                  cursor: "pointer",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-20px)",
                  },
                  ".imagebox": {
                    width: "100%",
                    height: { md: 120, xs: 80 },
                    borderRadius: 25,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                  ".image": {
                    height: "60%",
                    width: "60%",
                    objectFit: "contain",
                  },
                }}
              >
                <Box className="imagebox">
                  <img alt="img" src={brand?.BrandImage} className="image" />
                  <Checkbox
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 2,
                    }}
                    checked={selectedBrandIds.includes(brand._id)}
                    onChange={() => handleBrandSelect(brand._id)}
                  />
                </Box>

                <Box>
                  <Stack spacing={1}>
                    <Typography
                      variant="h4"
                      sx={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: 1, // Number of lines to show
                        fontSize: { md: "13px", xs: "10px" },
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      {brand?.BrandName}
                    </Typography>
                  </Stack>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
