import React, { useState } from 'react';
import Section from '../../components/Section';
import {
  Box,
  Container,
  Grid,
  List,
  Stack,
  Typography,
  InputAdornment,
  IconButton,
  Modal,
  Link,
} from '@mui/material';
import SectionImg from '../../media/image/section2img.png';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { VisibilityOutlined } from '@mui/icons-material';

import LogoImg from '../../media/image/logo.png';
import InputField1 from '../../components/mui/InputField1';
import CustomButton from '../../components/mui/CustomButton';
import { primaryColor } from '../../style/color';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [passwordsMatchError, setPasswordsMatchError] = useState('');
  const [passwordValidationError, setPasswordValidationError] = useState('');

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);

    newPassword !== e.target.value && newPassword !== ''
      ? setPasswordsMatchError('Passwords do not match')
      : setPasswordsMatchError('');
  };

  const handlePasswordValidation = (password) => {
    const isValid =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
        password
      ) && password.length >= 8;

    isValid
      ? setPasswordValidationError('')
      : setPasswordValidationError(
          'Password must be at least 8 characters and contain at least one special character.'
        );

    return isValid;
  };

  const handleNewPasswordChange = (e) => {
    const newPasswordValue = e.target.value;
    setNewPassword(newPasswordValue);

    newPasswordValue.trim() !== ''
      ? handlePasswordValidation(newPasswordValue)
      : setPasswordValidationError('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!handlePasswordValidation(newPassword)) {
      console.log('Error: Password validation failed');
      return;
    }

    setModalOpen(true);
    console.log('Password is valid');
    setNewPassword('');
    setConfirmPassword('');
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Section>
      <Container maxWidth='xl'>
        <Box mt={4}>
          <img
            src={LogoImg}
            sx={{
              height: { xl: '41.01px', md: '41.01px', xs: '20px' },
              width: '199px',
            }}
            alt='logo'
          />
        </Box>
        <Grid
          container
          spacing={1}
          alignItems={{
            xl: 'center',
            xs: 'flex-start',
            md: 'center',
            sm: 'flex-start',
          }}
          sx={{
            minHeight: 'calc(100vh - 100px)',
            marginTop: { xl: '-5%', xs: '-10%', md: '-10%', sm: '-5%' },
          }}
        >
          <Grid item md={5} xs={12}>
            <Box
              my={7}
              alignItems='center'
              justifyContent='center'
              sx={{ mx: { xs: 0, sm: 0, md: 6, lg: 6, xl: 6 } }}
            >
              <Box sx={{ pb: 4 }}>
                <Typography variant='h1' component='h2'>
                  Reset Password
                </Typography>
                <Typography
                  variant='h5'
                  component='p'
                  mt={1}
                  sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                >
                  Please type something you’ll remember
                </Typography>
              </Box>
              <Box maxWidth={600} mx='auto'>
                <form onSubmit={handleSubmit}>
                  <Stack sx={{ width: '100%', padding: '0' }}>
                    <Stack>
                      <InputField1
                        label='New Password'
                        placeholder='Enter your new password'
                        value={newPassword}
                        onChange={(e) => handleNewPasswordChange(e)}
                        required
                        maxLength={20}
                      />
                    </Stack>
                    {passwordValidationError && (
                      <Stack>
                        <Typography color='error'>
                          {passwordValidationError}
                        </Typography>
                      </Stack>
                    )}
                    <Stack>
                      <InputField1
                        label='Confirm Password'
                        placeholder='Enter your password again'
                        type={showPassword ? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={(e) => handleConfirmPasswordChange(e)}
                        required
                        maxLength={20}
                        endIcon={
                          <InputAdornment position='end'>
                            <IconButton
                              onClick={handleTogglePasswordVisibility}
                            >
                              {showPassword ? (
                                <VisibilityOffOutlinedIcon />
                              ) : (
                                <VisibilityOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </Stack>

                    {passwordsMatchError && (
                      <Stack>
                        <Typography color='error'>
                          {passwordsMatchError}
                        </Typography>
                      </Stack>
                    )}
                    <Stack mt={4}>
                      <CustomButton
                        type='submit'
                        variant='contained'
                        fullWidth
                        color='primary'
                      >
                        Reset Password
                      </CustomButton>
                    </Stack>
                  </Stack>
                </form>
              </Box>
            </Box>
          </Grid>
          <Grid item md={7} sx={{ display: { md: 'block', xs: 'none' } }}>
            <Box
              sx={{
                my: 2,
                px: 5,
                py: 4,
                minHeight: 'calc(100vh - 200px)',
                display: 'flex',
                alignItems: 'center',
                img: { width: '100%', height: 'auto' },
                borderRadius: 3,
              }}
            >
              <Box>
                <img
                  src={SectionImg}
                  style={{
                    height: '100%',
                    width: '100%',
                    borderRadius: '3px',
                  }}
                  alt='Products'
                />
              </Box>
            </Box>
          </Grid>
          <Modal open={isModalOpen} onClose={handleCloseModal}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: { xl: '545px', md: '50vh', sm: '44vh', xs: '40vh' },
                bgcolor: 'background.paper',
                height: { xl: '613px', md: '60vh', sm: '55vh', xs: '50vh' },
                boxShadow: 24,
                p: 4,
                outline: 'none',
                borderRadius: '20px',
              }}
            >
              <Box
                mt={{ xl: 8, sm: 2, xs: 4, md: '5vh' }}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <img
                  src='/spark.svg'
                  alt='spark'
                  style={{
                    width: { xl: '15vh', md: '10vh', sm: '10vh', xs: '7vh' },
                  }}
                ></img>
              </Box>
              <List>
                <Stack>
                  <Typography
                    variant='h2'
                    align='center'
                    color={primaryColor.main}
                    mt={{ xl: 5, sm: 2, xs: 0, md: 3 }}
                    mx='auto'
                  >
                    Password changed
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    variant='body1'
                    my={{ xl: 5, sm: 2, xs: 2, md: 4 }}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mx: 'auto',
                    }}
                  >
                    Your password has been changed successfully.
                  </Typography>
                </Stack>
                <Link href='/'>
                  <Stack>
                    <CustomButton
                      variant='contained'
                      color='primary'
                      fullWidth
                      onClick={handleCloseModal}
                    >
                      Back to Login
                    </CustomButton>
                  </Stack>
                </Link>
              </List>
            </Box>
          </Modal>
        </Grid>
      </Container>
    </Section>
  );
};

export default ResetPassword;
