import { deleteAPI, getAPI, patchAPI, postAPI } from '../utils/api';
import endpoints from '../utils/endpoints';

export const getAllAdminUsers = (payload, userId, callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      payload.page && payload.perPage
        ? `${endpoints.GET_ALL_ADMIN_USERS.replace(':id', userId)}?page=${
            payload.page
          }&perPage=${payload.perPage}`
        : endpoints.GET_ALL_ADMIN_USERS.replace(':id', userId),

      {},
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem('token')
    );
  };
};

export const userAdd = (userId, payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoints.GET_ALL_ADMIN_USERS.replace(':id', userId),
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const updateUser = (userId, payload, callBack, errorCallback) => {
  return (dispatch) => {
    patchAPI(
      endpoints.GET_ALL_ADMIN_USERS.replace(':id', userId),
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      localStorage.getItem('token')
    );
  };
};
export const deleteBackenUser = (userId, bannerId, callBack, errorCallback) => {
  return (dispatch) => {
    deleteAPI(
      `${endpoints.DELET_ALL_ADMIN_USERS}/${userId}/admin?id=${bannerId}`,
      {},
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      localStorage.getItem('token')
    );
  };
};
