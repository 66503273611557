import {
  deleteAPI,
  getAPI,
  patchAPI,
  postAPI,
  putAPI,
} from '../utils/api';
import endpoints from '../utils/endpoints';

export const getAllCoupons = (
  payload,
  callBack,
  errorCallback
) => {
  return (dispatch) => {
    getAPI(
      payload.page && payload.perPage
        ? `${endpoints?.GET_ALL_COUPON}?page=${payload.page}&perPage=${payload.perPage}`
        : endpoints?.GET_ALL_COUPON,
      {},
      (data) => {
        callBack(data);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem('token')
    );
  };
};

export const addCoupon = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoints?.ADD_COUPON,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      localStorage.getItem('token')
    );
  };
};

export const addSpecialCoupon = (
  payload,
  callBack,
  errorCallback
) => {
  return (dispatch) => {
    postAPI(
      endpoints?.ADD_SPECIAL_COUPON,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      localStorage.getItem('token')
    );
  };
};

export const updateCoupon = (
  userId,
  id,
  payload,
  callBack,
  errorCallback
) => {
  return (dispatch) => {
    patchAPI(
      `${endpoints?.UPDATE_COUPON}/${userId}/${id}`,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      localStorage.getItem('token')
    );
  };
};

export const deleteCoupon = (
  userId,
  id,
  payload,
  callBack,
  errorCallback
) => {
  return (dispatch) => {
    deleteAPI(
      `${endpoints?.DELETE_COUPON}/${userId}/${id}`,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      localStorage.getItem('token')
    );
  };
};
