import {
  Box,
  Modal,
  Typography,
  Grid,
  Divider,
  IconButton,
} from '@mui/material';
import React from 'react';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

const ViewRewardModal = ({ open, handleClose, reward }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '50%', xs: '90%' },
    height: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 8,
    outLine: 'none',
    overflowY: 'auto !important',
    borderRadius: '20px',
    WebkitOverflowScrolling: 'touch',
    scrollbarWidth: 'thin',
    scrollbarColor: 'transparent transparent',
  };

  const cellStyle = {
    border: '1px solid #ddd',
    padding: '8px',
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <IconButton
          edge='end'
          color='inherit'
          onClick={handleClose}
          aria-label='close'
          sx={{
            position: 'absolute',
            top: '15px',
            right: '15px',
          }}
        >
          <HighlightOffOutlinedIcon />
        </IconButton>
        <Typography mb={5} variant='h3'>
          Reward Details
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            textAlign: 'center',
          }}
        >
          {reward ? (
            <Grid
              container
              alignItems={'center'}
              justifyContent='center'
            >
              <Grid item xs={6} sx={cellStyle}>
                <Typography variant='body1' fontWeight='bold'>
                  Reward points
                </Typography>
              </Grid>
              <Grid item xs={6} sx={cellStyle}>
                <Typography variant='body1'>
                  {reward?.point}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={6} sx={cellStyle}>
                <Typography variant='body1' fontWeight='bold'>
                  From
                </Typography>
              </Grid>
              <Grid item xs={6} sx={cellStyle}>
                <Typography variant='body1'>
                  {reward?.from?.username ||
                    reward?.from?.phone ||
                    reward?.from?.email ||
                    'Unknown'}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={6} sx={cellStyle}>
                <Typography variant='body1' fontWeight='bold'>
                  To
                </Typography>
              </Grid>
              <Grid item xs={6} sx={cellStyle}>
                <Typography variant='body1'>
                  {reward?.to?.username ||
                    reward?.to?.phone ||
                    reward?.to?.email ||
                    'Unknown'}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={6} sx={cellStyle}>
                <Typography variant='body1' fontWeight='bold'>
                  Status:
                </Typography>
              </Grid>
              <Grid item xs={6} sx={cellStyle}>
                <Typography
                  variant='body1'
                  sx={{
                    color:
                      reward?.action === 'DEBIT'
                        ? 'red'
                        : 'green',
                  }}
                >
                  {reward?.action
                    ? reward?.action.toLowerCase()
                    : '--'}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={6} sx={cellStyle}>
                <Typography variant='body1' fontWeight='bold'>
                  Created On
                </Typography>
              </Grid>
              <Grid item xs={6} sx={cellStyle}>
                <Typography variant='body1'>
                 
                      {reward?.createdAt
                        ? new Date(reward.createdAt)
                            .toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            })
                            .replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$1-$2-$3")
                        : "N/A"}
                   
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Typography variant='body1'>
              No reward selected
            </Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ViewRewardModal;
