export const primaryColor = {
  light: '#718EBF',
  main: '#11409B',
  dark: '#344054',
};
export const secondaryColor = {
  main: '#344054',
  dark: '#232323 ',
  light: '#718EBF',
};
export const formInputColor = {
  main: '#667085',
  dark: '#D0D5DD',
  light: '#718EBF',
};
