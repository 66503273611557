import React from 'react';
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { primaryColor, secondaryColor } from '../../style/color';

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor.main,
      dark: primaryColor.dark,
    },
    secondary: {
      main: secondaryColor.main,
      dark: secondaryColor.dark,
      light: secondaryColor.light,
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    menu: { fontSize: 15, fontWeight: 500 },
    h1: {
      fontSize: 48,
      fontWeight: 700,
      color: secondaryColor.main,
    },
    h2: { fontSize: 40, fontWeight: 700, color: secondaryColor.main },
    h3: {
      fontSize: {
        xl: 33,
        md: 24,
        sm: 20,
        xs: 10,
      },
      fontWeight: 700,
      color: secondaryColor.main,
    },
    h4: { fontSize: 22, fontWeight: 700 },
    h5: { fontSize: 18, fontWeight: 500 },
    h6: { fontSize: 16, fontWeight: 700 },
    p: { fontSize: 16 },
    small: { fontSize: 10, fontWeight: '400' },
    medium: { fontSize: ' 16px', fontWeight: 500 },
    large: { fontSize: 54 },
    op: {
      fontWeight: 400,
      fontSize: '0.99rem',
    },
  },
  span: { fontSize: 18, color: secondaryColor.main, fontWeight: 400 },
});

const responsiveTheme = responsiveFontSizes(theme);

const Theme = ({ children }) => {
  return (
    <ThemeProvider theme={responsiveTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default Theme;
