import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { useDispatch } from 'react-redux';
import { getWeeklyOrders } from '../../actions/ordersAuth';

const BarChart = () => {
  const dispatch = useDispatch();
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null); // Add a ref for the chart instance

  useEffect(() => {
    const fetchData = () => {
      dispatch(getWeeklyOrders((data) => {
        var dates = [];
        var success = [];
        var failed = [];
        var pending = [];
        data.data.data.forEach((item) => {
          dates.push(item.day);
          success.push(item.successCount);
          failed.push(item.failureCount);
          pending.push(item.pendingCount);
        });
        const ctx = chartContainerRef.current.getContext('2d');

        // Create the chart instance and store it in the chartRef
        chartRef.current = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: dates,
            datasets: [
              {
                label: 'Success',
                data: success,
                borderColor: '#16DBCC',
                backgroundColor: '#16DBCC',
                borderWidth: 1,
                barPercentage: 0.5,
                borderRadius: Number.MAX_VALUE,
                borderSkipped: false,
              },
              {
                label: 'Pending',
                data: pending,
                borderColor: '#FFBB38',
                backgroundColor: '#FFBB38',
                borderWidth: 1,
                barPercentage: 0.5,
                borderRadius: Number.MAX_VALUE,
                borderSkipped: false,
              },
              {
                label: 'Failure',
                data: failed,
                borderColor: '#FF82AC',
                backgroundColor: '#FF82AC',
                borderWidth: 1,
                barPercentage: 0.5,
                borderRadius: Number.MAX_VALUE,
                borderSkipped: false,
              },
            ],
          },
          options: {
            plugins: {},
            scales: {
              x: {
                grid: {
                  drawOnChartArea: false,
                  borderRadius: 10,
                },
              },
              y: {
                grid: {
                  drawBorder: false,
                },
              },
            },
            layout: {
              padding: {
                left: 10,
                right: 10,
                top: 10,
                bottom: 10,
              },
            },
            ...legendOptions,
            maintainAspectRatio: false,
          },
        });
      }, (err) => {
      }));
    };

    fetchData();

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy(); // Use chartRef to access the chart instance
      }
    };
  }, [dispatch]); // Add dispatch as a dependency for useEffect

  const legendOptions = {
    plugins: {
      legend: {
        display: true,
        position: 'top',
        align: 'end',
        labels: {
          boxWidth: 10,
        },
        borderRadius:Number.MAX_VALUE
      },
    },
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div
        className='bar-chart'
        style={{
          height: '95%',
          width: '100%',
        }}
      >
        <canvas ref={chartContainerRef} />
      </div>
    </div>
  );
};

export default BarChart;
