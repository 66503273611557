import { deleteAPI, getAPI, patchAPI, postAPI } from '../utils/api';
import endpoints from '../utils/endpoints';
//test
export const getAllAdminBanner = (payload, userId, callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      payload.page && payload.perPage
        ? `${endpoints.GET_ALL_BANNER.replace(':id', userId)}?page=${
            payload.page
          }&perPage=${payload.perPage}`
        : endpoints.GET_ALL_BANNER.replace(':id', userId),

      {},
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem('token')
    );
  };
};

export const addBanner = (userId, payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoints.GET_ALL_BANNER.replace(':id', userId),
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};
export const updateBanner = (
  userId,
  bannerId,
  payload,
  callBack,
  errorCallback
) => {
  return (dispatch) => {
    patchAPI(
      `${endpoints.EDIT_BANNER}/${userId}/${bannerId}`,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};
export const deleteBanner = (
  userId,
  bannerId,
  payload,
  callBack,
  errorCallback
) => {
  return (dispatch) => {
    deleteAPI(
      `${endpoints.EDIT_BANNER}/${userId}/${bannerId}`,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};
