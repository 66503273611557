import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
  Box,
  Stack,
  Modal,
} from "@mui/material";
import HomeLayout from "../../asset/layout/HomeLayout";
import SearchBar from "../../components/mui/SearchBar";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { getAllSales, updateSales } from "../../actions/sales.Auth";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";
import ResponsiveDatePickers from "../../components/mui/PichDate";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import CustomButton from "../../components/mui/CustomButton";
import dayjs from "dayjs";

const columns = [
  "Category",
  "Brand Group",
  "Product",
  "Quantity",
  "Dealer Code",
  "Dealer Name",
  "Architech",
  "Customer Details",
  "Updated On",
  "Remarks",
  "Reward Point",
  "Status",
];

export const SalesManagement = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [sales, setSales] = useState([]);
  const [downloadSales, setDownloadSales] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);
  const [allSales, setAllSales] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSales, setSelectedSales] = useState(null);
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      Navigate("/");
    }
  }, []);

  useEffect(() => {
    fetchSales();
  }, [searchTerm, selectedDateFrom, selectedDateTo, currentPage, countPerPage]);

  const fetchSales = () => {
    setLoading(true);
    dispatch(
      getAllSales(
        { page: 1, perPage: 100000 },
        (data) => {
          const filteredSales = applyFilters(data.data.data.hamrahis);
          setAllSales(filteredSales);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          console.log(err);
        }
      )
    );
  };

  const applyFilters = (salesData) => {
    let filteredSales = salesData;

    if (searchTerm) {
      filteredSales = filteredSales.filter((item) =>
        [
          item.category,
          item.brandGroup,
          item.product,
          item.quantity.toString(),
          item.dealerCode,
          item.dealerName,
          item.architechId?.phone,
        ].some((field) =>
          field?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    if (selectedDateFrom || selectedDateTo) {
      filteredSales = filteredSales.filter((item) => {
        const createdAt = new Date(item.createdAt);

        let isAfterStartDate = true;
        if (selectedDateFrom) {
          isAfterStartDate = createdAt >= selectedDateFrom;
        }

        let isBeforeEndDate = true;
        if (selectedDateTo) {
          const endOfDay = new Date(selectedDateTo);
          endOfDay.setHours(23, 59, 59, 999);
          isBeforeEndDate = createdAt <= endOfDay;
        }

        return isAfterStartDate && isBeforeEndDate;
      });
    }

    return filteredSales;
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };
  const handleStatusUpdate = (id, newStatus) => {
    let payload = {
      status: newStatus,
    };
    dispatch(
      updateSales(
        id,
        payload,
        (data) => {
          enqueueSnackbar("Sales Updated Successfully", { variant: "success" });
          fetchSales();
        },
        (error) => {
          enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
          console.log(error);
        }
      )
    );
  };

  const exportToExcel = () => {
    const header = [
      "Category",
      "Brand Group",
      "Product",
      "Quantity",
      "Dealer Code",
      "Dealer Name",
      "Architech",
      "Customer Details",
      "Updated On",
      "Remarks",
      "Reward Point",
      "Status",
    ];

    const dataToExport = allSales.map((sale) => [
      sale?.category,
      sale?.brandGroup,
      sale?.product,
      sale?.quantity,
      sale?.dealerCode,
      sale?.dealerName,
      sale?.architechId?.phone,
      sale?.customerDetails,
      sale?.updatedAt,
      sale?.remarks,
      sale?.reward,
      sale?.status,
    ]);

    dataToExport.unshift(header);

    const worksheet = XLSX.utils.aoa_to_sheet(dataToExport);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sales Data");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });

    saveAs(blob, "Sales_Data.xlsx");
  };

  // Pagination
  const indexOfLastSales = currentPage * countPerPage;
  const indexOfFirstSales = indexOfLastSales - countPerPage;
  const currentSales = allSales.slice(indexOfFirstSales, indexOfLastSales);

  const handleSearchClear = () => {
    setSearchTerm("");
    setSelectedDateFrom(null);
    setSelectedDateTo(null);
  };
  const handlePhoneClick = (sale) => {
    setSelectedSales(sale);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedSales(null);
  };
  console.log(selectedSales);
  return (
    <HomeLayout>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={3}
      >
        <Typography variant="h4">Sales Management</Typography>
        <CustomButton
          variant="contained"
          width={{ md: "22%", sm: "50%", xs: "40%" }}
          height={"rem"}
          type="button"
          onClick={exportToExcel}
        >
          Export Sales
        </CustomButton>
      </Box>
      <Box>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <SearchBar onSearch={handleSearch} searchTerm={searchTerm} />
          </Grid>

          <Grid item md={3} xs={12}>
            <ResponsiveDatePickers
              selectedDate={selectedDateFrom}
              setSelectedDate={setSelectedDateFrom}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ResponsiveDatePickers
              selectedDate={selectedDateTo}
              setSelectedDate={setSelectedDateTo}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            {" "}
            <CustomButton
              variant="contained"
              onClick={handleSearchClear}
              width={"100%"}
              height={"2.5rem"}
            >
              clear
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
      {loading ? (
        <Typography textAlign={"center"} my={2}>
          <CircularProgress size={24} />
        </Typography>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "25px",
            overflowX: "scroll",
            my: 3,
          }}
        >
          <Table sx={{ maxWidth: "auto" }}>
            <TableHead>
              <TableRow>
                {columns?.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#11409B",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                whiteSpace: "nowrap",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              {currentSales.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row?.category}</TableCell>
                  <TableCell>{row?.brandGroup}</TableCell>
                  <TableCell>{row?.product}</TableCell>
                  <TableCell>{row?.quantity}</TableCell>
                  <TableCell>{row?.dealerCode}</TableCell>
                  <TableCell>{row?.dealerName}</TableCell>
                  <TableCell
                    onClick={() => handlePhoneClick(row)}
                    style={{ cursor: "pointer", color: "blue" }}
                  >
                    +91 {row?.architechId?.phone}
                  </TableCell>
                  <TableCell>{row?.customerDetails}</TableCell>
                  <TableCell>
                    {row?.updatedAt
                      ? new Date(row.updatedAt)
                          .toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })
                          .split("/")
                          .join("-")
                      : "N/A"}
                  </TableCell>
                  <TableCell>{row?.remarks}</TableCell>
                  <TableCell>{row?.reward}</TableCell>
                  <TableCell>
                    {row?.status === "PENDING" ? (
                      <Stack direction="row" spacing={1}>
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() =>
                            handleStatusUpdate(row._id, "APPROVED")
                          }
                        >
                          Approve
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() =>
                            handleStatusUpdate(row._id, "DECLINED")
                          }
                        >
                          Decline
                        </Button>
                      </Stack>
                    ) : (
                      <Typography
                        sx={{
                          color:
                            row?.status === "APPROVED"
                              ? "#16DBCC"
                              : row.status === "DECLINED"
                              ? "#FF82AC"
                              : "#000",
                        }}
                      >
                        {row?.status || "--"}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box
        sx={{
          bgcolor: "mute.dark",
          borderRadius: 1,
          color: "dark.text",
          p: 2,
          mt: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography>
            {(currentPage - 1) * countPerPage + 1} -{" "}
            {Math.min(currentPage * countPerPage, allSales.length)} of{" "}
            {allSales.length}
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography> Rows per page:</Typography>
          <select
            style={{
              marginTop: "-5px",
              background: "transparent",
              border: "none",
            }}
            value={countPerPage}
            onChange={(e) => setCountPerPage(parseInt(e.target.value))}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="30">30</option>
            <option value="50">50</option>
          </select>
          <Box sx={{ ml: 2 }}>
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box>
                <IconButton
                  onClick={() =>
                    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
                  }
                  disabled={currentPage === 1}
                  sx={{
                    border: "1px solid",
                    borderRadius: "10px",
                    borderColor: "mute.main",
                    bgcolor: "white.main",
                    boxShadow: "2px 2px 0px #999",
                    background: "#fff",
                  }}
                >
                  <KeyboardArrowLeftIcon
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: currentPage === 1 ? "#999" : "#000",
                    }}
                  />
                </IconButton>
              </Box>
              <Box>
                <Typography variant="text" component={"span"}>
                  {currentPage}/{Math.ceil(allSales.length / countPerPage)}
                </Typography>
              </Box>
              <Box>
                <IconButton
                  onClick={() =>
                    setCurrentPage((prevPage) =>
                      Math.min(
                        prevPage + 1,
                        Math.ceil(allSales.length / countPerPage)
                      )
                    )
                  }
                  disabled={
                    currentPage === Math.ceil(allSales.length / countPerPage)
                  }
                  sx={{
                    border: "1px solid",
                    borderRadius: "10px",
                    borderColor: "mute.main",
                    bgcolor: "white.main",
                    boxShadow: "2px 2px 0px #999",
                    background: "#fff",
                  }}
                >
                  <KeyboardArrowRightIcon
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: "#000",
                    }}
                  />
                </IconButton>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="order-modal-title"
        aria-describedby="order-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { md: "40%", xs: "80%" },
            bgcolor: "background.paper",
            borderRadius: "2rem",
            boxShadow: 24,
            px: 4,
            py: 2,
            outline: "none",
          }}
        >
          <Typography
            textAlign={"center"}
            pb={4}
            id="order-modal-title"
            variant="h6"
            component="h2"
          >
            Sale Details
          </Typography>
          {selectedSales && (
            <>
              {" "}
              <Box sx={{ textAlign: "center", width: "100%" }}>
                {" "}
                <img
                  src={selectedSales.architechId?.pictureUrl}
                  alt="User"
                  height={100}
                  width={100}
                  style={{ borderRadius: "50%" }}
                />
              </Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Phone</TableCell>
                      <TableCell>{selectedSales?.architechId?.phone}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>
                        {selectedSales?.architechId?.username}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell>{selectedSales?.architechId?.email}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Reward Point</TableCell>
                      <TableCell>
                        {selectedSales?.architechId?.rewardPoint}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
      </Modal>
    </HomeLayout>
  );
};
