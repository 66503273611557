import axios from 'axios';
import {
  deleteAPI,
  getAPI,
  patchAPI,
  postAPI,
  putAPI,
} from '../utils/api';
import endpoints from '../utils/endpoints';

export const getClientByToken = (
  callBack,
  errorCallback,
  manualToken
) => {
  return (dispatch) => {
    getAPI(
      endpoints?.GET_ALL_CLIENTS,
      {},
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      manualToken
    );
  };
};
export const updateClientById = (
  userId,
  payload,
  callBack,
  errorCallback
) => {
  return (dispatch) => {
    patchAPI(
      `${endpoints.UPDATE_CLIENTS_BY_ID}${userId}`,

      payload,
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem('token')
    );
  };
};
export const getClientById = (
  userId,
  callBack,
  errorCallback
) => {
  return (dispatch) => {
    getAPI(
      `${endpoints.GET_CLIENTS_BY_ID}${userId}`,

      {},
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem('token')
    );
  };
};
export const RechargeRequestById = (
  CompanyId,
  callBack,
  errorCallback
) => {
  return (dispatch) => {
    getAPI(
      `${endpoints.GET_RECHARGE_REQUEST}${CompanyId}`,

      {},
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem('token')
    );
  };
};
export const uploadDocument = (
  payload,
  callBack,
  errorCallback
) => {
  return (dispatch) => {
    postAPI(
      endpoints?.UPLOAD_DOC,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const updateRechargeStatus = (
  userId,
  payload,
  callBack,
  errorCallback
) => {
  return (dispatch) => {
    patchAPI(
      `${endpoints.UPDATE_RECHARGE_REQUEST_STATUS}${userId}`,

      payload,
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem('token')
    );
  };
};
