import React from 'react';
import { primaryColor } from '../../style/color';
import { Box, Typography } from '@mui/material';

const InputField1 = (
  {
    required = false,
    label,
    fieldType,
    placeholder,
    value,
    onChange,
    error,
    helperText,
    endIcon,
    marginRight,
    height,
    disabled = false,
    type,
    maxLength,
    placeholderColor,
    spanP = false,
    max,
    name,
  },
  ref
) => {
  return (
    <Box
      sx={{
        position: 'relative',
        marginRight: marginRight || '',
        label: { display: 'block' },
        width: '100%',
        inputContainer: {
          position: 'relative',
        },
        input: {
          height: height || 44,
          borderRadius: 2,
          fontSize: 15,
          px: 2,
          width: '100%',
          maxWidth: '100%',
          paddingRight: endIcon ? '30px' : '2px',
          border: `1px solid ${
            error ? primaryColor.main : '#D0D5DD'
          }`,
          transition: '0.3s',
          boxShadow: '0px 0px 25px rgb(0,0,0,0.02)',
          fontFamily: 'Inter, sans-serif',
          bgcolor: 'none',
          '&:hover': { borderColor: 'primary.main' },
          '&:focus': {
            outline: 'none',
            border: `1px solid ${primaryColor.light}`,
          },
          '&:disabled': {
            backgroundColor: '#f2f2f2',
            color: 'rgba(0, 0, 0, 0.38)',
            cursor: 'not-allowed',
          },
          type: type,
          '::placeholder': {
            color: placeholderColor || 'inherit',
            opacity: 0.5,
          },
        },
        endIconContainer: {
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: '10px',
        },
      }}
    >
      {label && (
        <Typography variant='p' component={'label'} my={1.5}>
          {label}
          {spanP ? (
            <span style={{ color: 'red' }}>*</span>
          ) : null}
        </Typography>
      )}
      <Box sx={{ position: 'relative' }}>
        <input
          placeholder={placeholder || ''}
          required={required}
          value={value}
          onChange={onChange}
          disabled={disabled}
          type={type}
          max={max}
          maxLength={maxLength}
          name={name}
        />
      </Box>

      <Box
        style={{
          position: 'absolute',
          top: '79%',
          transform: 'translateY(-50%)',
          right: '10px',
          color: 'gray',
          display: 'flex',
          alignItems: 'center !important',
        }}
      >
        {endIcon}
      </Box>
      {error && (
        <Typography variant='p' color='error'>
          {helperText}
        </Typography>
      )}
    </Box>
  );
};

export default InputField1;
