import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
const RModal = ({
  open,
  handleClose,
  width,
  height,
  children,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width || 400,
    height: height || 'auto',
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
    outLine: 'none',
    overflowY: 'auto',

    borderRadius: '20px',
    WebkitOverflowScrolling: 'touch',
    scrollbarWidth: 'inherit',
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <IconButton
            edge='end'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
            sx={{
              position: 'absolute',
              top: '15px',
              right: '15px',
            }}
          >
            <HighlightOffOutlinedIcon />
          </IconButton>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};

export default RModal;
