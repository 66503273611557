import { Card, Grid, IconButton, Stack, Typography } from "@mui/material";
import React from "react";

const dummyDataApi = {
  getTransactions: () => {
    return [
      {
        entry: "Withdraw from my Card",
        date: "28 January 2021",
        amount: "$787",
      },
      {
        entry: "Deposit Paypal",
        date: "25 January 2021",
        amount: "$7,878",
      },
      {
        entry: "Jemi Wilson",
        date: "21 January 2021",
        amount: "$909",
      },
      {
        entry: "Deposit Paypal",
        date: "24 January 2021",
        amount: "$909",
      },
      {
        entry: "Deposit Paypal",
        date: "24 January 2021",
        amount: "$909",
      },
      {
        entry: " Jemi Wilson",
        date: "24 January 2021",
        amount: "$909",
      },
    ];
  },
};
const iconData = {
  depoCard: {
    src: "/depoCard.svg",
    alt: "Deposit from Card",
    bgColor: "#FFF5D9",
  },
  paypal: {
    src: "/paypal.svg",
    alt: "Deposit from Paypal",
    bgColor: "#E7EDFF",
  },
  other: {
    src: "/Deposit.svg",
    alt: "Other transactions",
    bgColor: "#DCFAF8",
  },
  withdraw: {
    src: "/money.svg",
    alt: "Withdrawal",
  },
};

const ListCard = ({ transactions }) => {
  transactions = transactions?.length > 0 ? transactions.slice(0, 4) : [];
  const determineIcon = (entry) => {
    if (entry.toLowerCase().includes("withdraw")) {
      return "depoCard";
    } else if (entry.toLowerCase().includes("paypal")) {
      return "paypal";
    } else {
      return "other";
    }
  };
  const determineColor = (entry) => {
    return entry.toLowerCase().includes("withdraw") ? "red" : "green";
  };

  return (
    <Card
      sx={{
        boxShadow: "none",
        height: "40vh",

        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        borderRadius: "20px",
        flexDirection: "column",
      }}
    >
      {transactions?.map((transaction, index) => (
        <Grid
          container
          spacing={1}
          justifyContent="space-evenly"
          alignItems="center"
          key={index}
          py={1}
          px={2.2}
        >
          <Grid item xs={3}>
            <IconButton
              sx={{
                backgroundColor: iconData["other"]?.bgColor,
              }}
            >
              <img src={iconData["other"]?.src} alt={iconData["other"]?.alt} />
            </IconButton>
          </Grid>
          <Grid item xs={7}>
            <Stack>
              <Typography variant="medium">
                {transaction?.customerDetails?.customerId?.username
                  ? transaction?.customerDetails?.customerId?.username
                  : transaction?.customerDetails?.customerId?.phone
                  ? transaction?.customerDetails?.customerId?.phone
                  : "No User Data"}
              </Typography>
            </Stack>

            <Stack>
              <Typography variant="op" sx={{ color: "#718EBF" }}>
                {transaction?.createdAt
                  ? new Date(transaction?.createdAt).toLocaleDateString()
                  : "--"}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ color: determineColor("Other") }}>
              &#8377;{transaction?.orderAmount / 100}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Card>
  );
};

export default ListCard;
