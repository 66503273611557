const version = "v1";
const service = "staging";

export default {
  SIGNIN: `${version}/${service}/auth/send-otp-admin`,
  LOGOUT: `${version}/${service}/auth/logout`,
  VERIFY_OTP: `${version}/${service}/auth/verify-otp`,
  GET_ALL_USERS: `${version}/${service}/users/`,
  GET_USER_BY_TOKEN: `${version}/${service}/users/details`,
  GET_ALL_BRANDS: `${version}/${service}/voucher/get-brands`,
  GET_ALL_TRANSACTIONS: `${version}/${service}/payment`,
  GET_VOUCHERS_TOTAL: `${version}/${service}/payment/total`,
  GET_ALL_VOUCHERS_AND_ORDERS: `${version}/${service}/order/total`,
  GET_ALL_ORDERS: `${version}/${service}/order/all`,
  GET_ALL_SALES: `${version}/${service}/hamrahi/all`,
  UPDATE_SALES: `${version}/${service}/hamrahi/update`,
  GET_ALL_CATEGORY: `${version}/${service}/category`,
  UPDATE_CATEGORY: `${version}/${service}/voucher/update-brands`,
  ADD_BANNER: `${version}/${service}/banner`,
  GET_ALL_COUPON: `${version}/${service}/coupon`,
  ADD_COUPON: `${version}/${service}/coupon`,
  UPDATE_COUPON: `${version}/${service}/coupon`,
  DELETE_COUPON: `${version}/${service}/coupon`,
  GET_ALL_ADMIN_USERS: `${version}/${service}/users/:id/admin`,
  DELET_ALL_ADMIN_USERS: `${version}/${service}/users`,
  GET_ALL_BANNER: `${version}/${service}/banner/:id`,
  EDIT_BANNER: `${version}/${service}/banner`,
  GET_WEEKLY_ORDERS: `${version}/${service}/order/weekly`,
  UPDATE_BRAND: `${version}/${service}/voucher/update-brands`,
  TICKET: `${version}/${service}/ticket`,
  EDIT_TICKET: `${version}/${service}/ticket/:id`,

  GET_WALLET: `${version}/${service}/wallet/reward-point`,
  COMMENTS: `${version}/${service}/comment/`,
  GET_ALL_CLIENTS: `${version}/${service}/business/getAllBusinessAccountUsers`,
  GET_CLIENTS_BY_ID: `${version}/${service}/business/getBusinessAccountUserById/`,
  UPDATE_CLIENTS_BY_ID: `${version}/${service}/business/updateBusinessUserById/`,

  ADD_SPECIAL_COUPON: `${version}/${service}/coupon/specialCoupons`,
  APPLY_PERMISSIONS: `${version}/${service}/business/addGrantPermission`,
  ADD_BRANDS: `${version}/${service}/business/addBrandInBusinessAccount`,
  UPLOAD_DOC: `${version}/${service}/companyDetails/uploadDocument`,
  UPLOAD_FILE: `${version}/${service}/upload-file`,
  GET_RECHARGE_REQUEST: `${version}/${service}/recharge/getRechargeRequestsByBusinessAccountId/`,
  UPDATE_RECHARGE_REQUEST_STATUS: `${version}/${service}/recharge/verifyRechargeRequests/`,
  GET_ALL_CAMPAIGN_LEADS: `${version}/${service}/contact/getAllContacts`,
  GET_ALL_BUDGETREE_LEADS: `${version}/${service}/contact/contactSales`,
  GET_FLIPKART_COUNT: `${version}/${service}/voucher/get-flipkart-balance`,
  IMAGE_UPLOAD: `${version}/${service}/upload-file`,
};
