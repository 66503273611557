import {
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersClient } from "../../actions/userAuth";

const columns = [
  "Admin Name",
  "Email",
  "Number",
  "Role",
  "Last Login Date",
];

const SuperAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);

  const dispatch = useDispatch();

  const getData = () => {
    setLoading(true);
    dispatch(
      getAllUsersClient(
        { page: 1, perPage: 100000 },
        (data) => {
          const allUsers = data.data.data.users;
          const superAdmins = allUsers?.filter(user => user.role === "superAdmin");

          dispatch({
            type: 'ALL_USER',
            payload: allUsers,
          });

          setUserData(superAdmins);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    getData();
  }, [dispatch]);

  return (
    <>
      <Stack alignItems="center" direction={"row"} mt={5}>
        <Typography
          variant="h4"
          my={3}
          flexGrow={1}
          fontSize={{ xl: "22px", sm: "1rem", xs: "1rem" }}
        >
          Super Admin
        </Typography>
      </Stack>

      {loading ? (
        <Box align="center" my={5}>
          <CircularProgress size={24} />
        </Box>
      ) : userData?.length > 0 ? (
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "25px",
            my: 3,
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {columns?.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#11409B",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      textAlign: "center",
                    }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {userData?.map((user, index) => (
                <TableRow key={user.id || index}>
                  <TableCell sx={{ textAlign: "center", verticalAlign: "middle" }}>
                    {user?.username || "--"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", verticalAlign: "middle" }}>
                    {user?.email || "--"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", verticalAlign: "middle" }}>
                    {user?.phone || "--"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", verticalAlign: "middle" }}>
                    {user?.role || "--"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", verticalAlign: "middle" }}>
                    {user?.updatedAt
                      ? dayjs(user.updatedAt).format("MM-DD-YYYY")
                      : "--"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1" sx={{ textAlign: "center", my: 3 }}>
          No results found.
        </Typography>
      )}
    </>
  );
};

export default SuperAdmin;
