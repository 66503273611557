import React from 'react';
import dayjs from 'dayjs';
import {
  DemoContainer,
  DemoItem,
} from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function ResponsiveDatePickers({
  selectedDate,
  setSelectedDate,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DemoItem>
          <DatePicker
            value={selectedDate}
            onChange={(newValue) => {
              // Convert dayjs object to native Date
              const parsedDate = newValue ? newValue.toDate() : null;
              setSelectedDate(parsedDate);
            }}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
}
