import React from 'react';
import { Button } from '@mui/material';

const CustomButton = ({ children, height, width, fontSize, ...props }) => {
  return (
    <Button
      {...props}
      sx={{
        textTransform: 'none',
        height: height || {
          xl: '50px',
          sm: '40px',
          boxShadow: 'none',
        },
        width: width || 'auto',
        fontWeight: '500',
        fontSize: fontSize || '16px',
        borderRadius: '8px',
      }}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
