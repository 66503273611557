import { getAPI, patchAPI, postAPI } from "../utils/api";
import endpoints from "../utils/endpoints";

export const getAllBrands = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      payload.page && payload.perPage
        ? `${endpoints?.GET_ALL_BRANDS}?page=${payload.page}&perPage=${payload.perPage}&searchText=${payload.searchText}`
        : endpoints?.GET_ALL_BRANDS,
      {},
      (data) => {
        callBack(data);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem("token")
    );
  };
};

export const getTotalAmount = (callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endpoints?.GET_VOUCHERS_TOTAL,
      {},
      (data) => {
        callBack(data);
      },
      (err) => {
        errorCallback(err);
      }
    );
  };
};

export const getVouchersAndOrdersCount = (callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endpoints?.GET_ALL_VOUCHERS_AND_ORDERS,
      {},
      (data) => {
        callBack(data);
      },
      (err) => {
        errorCallback(err);
      }
    );
  };
};
export const getFlipkartCount = (callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endpoints?.GET_FLIPKART_COUNT,
      {},
      (data) => {
        callBack(data);
      },
      (err) => {
        errorCallback(err);
      }
    );
  };
};

export const getAllCategory = (callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoints?.GET_ALL_CATEGORY,
      {},
      (data) => {
        callBack(data);
      },
      (err) => {
        errorCallback(err);
      }
    );
  };
};

export const bannerAdd = (id, payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      `${endpoints?.ADD_BANNER}/${id}`,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};
export const updateCategory = (id, payload, callBack, errorCallback) => {
  return (dispatch) => {
    patchAPI(
      `${endpoints?.UPDATE_CATEGORY}/${id}`,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const updateBrand = (id, payload, callBack, errorCallback) => {
  return (dispatch) => {
    patchAPI(
      `${endpoints?.UPDATE_BRAND}/${id}`,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};
export const uploadDocument = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoints?.UPLOAD_FILE,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};
