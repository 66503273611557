import React, { Fragment, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Box,
  Stack,
  Grid,
  CircularProgress,
  FormControlLabel,
} from "@mui/material";
import HomeLayout from "../../asset/layout/HomeLayout";
import { Add } from "@mui/icons-material";
import { useSelector } from "react-redux";
import SearchBar from "../../components/mui/SearchBar";
import Paginations from "../../components/mui/Pagination";
import CustomButton from "../../components/mui/CustomButton";
import RModal from "../../components/mui/RModal";
import InputField1 from "../../components/mui/InputField1";
import BasicDatePicker from "../../components/mui/BasicDatePicker";
import { enqueueSnackbar } from "notistack";
import {
  addCoupon,
  addSpecialCoupon,
  deleteCoupon,
  getAllCoupons,
  updateCoupon,
} from "../../actions/couponAuth";
import { useDispatch } from "react-redux";
import {} from "../../utils/common";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import ResponsiveDatePickers from "../../components/mui/PichDate";
import IOSSwitch from "../../style/globalStyles";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const columns = [
  " Coupon Code",
  "Description ",
  "Expiry Date",
  "Is Applied",
  "Created At",
  "Active",
  "Action",
];

export const SpecialCoupons = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [couponName, setCouponName] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [amount, setAmount] = useState();
  const [amountUpdate, setAmountUpdate] = useState();

  const [minAmount, setMinAmount] = useState();
  const [minAmountUpdate, setMinAmountUpdate] = useState();
  const [description, setDescription] = useState("");
  const [expiryDate, setExpiryDate] = useState(null);
  const [expiryDateUp, setExpiryDateUp] = useState(null);
  const [quantity, setQuantity] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);
  const [error, setError] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [updateFormValid, setUpdateFormValid] = useState(false);

  const [Id, setId] = useState("");
  const Navigate = useNavigate();
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [totalCoupons, setTotalCoupons] = useState(0);
  const [startQuantity, setStartQuantity] = useState("");

  const [formData, setFormData] = useState({
    amount: 0,
    expiryDate: "",
    couponQuantity: "",
    minAmountToApply: 0,
    description: "",

    numericalCodes: false,
  });

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      Navigate("/");
    }
  }, []);
  const { user } = useSelector((state) => ({ ...state }));
  const dispatch = useDispatch();

  const getData = () => {
    const filters = {};

    dispatch(
      getAllCoupons(
        filters,
        (data) => {
          const filteredCoupons = data.data.data.Coupons.filter((item) => {
            const matchQuery =
              item?.couponName
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase()) ||
              item?.description
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase());

            const matchDateRange =
              (!selectedDateFrom ||
                new Date(item?.createdAt) >= new Date(selectedDateFrom)) &&
              (!selectedDateTo ||
                new Date(item?.createdAt) <= new Date(selectedDateTo));

            const isSpecial = item?.isSpecial;

            return matchQuery && matchDateRange && isSpecial;
          });
          setCoupons(filteredCoupons);
          setTotalCoupons(data.data.results);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          console.log(err);
        }
      )
    );
  };
  useEffect(() => {
    setLoading(true);
    getData();
  }, [countPerPage, currentPage, searchTerm, selectedDateFrom, selectedDateTo]);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoadingSubmit(true);

    if (!amount || !expiryDate || !quantity || !minAmount || !description) {
      enqueueSnackbar("Please fill all details.", {
        variant: "error",
      });
      setLoadingSubmit(false);
    } else {
      let payload = {
        amount: amount,
        minAmount: minAmount,
        description: description,
        expiryDate: expiryDate,
        quantity: quantity,
      };
      dispatch(
        addSpecialCoupon(
          payload,
          (data) => {
            enqueueSnackbar("data submit", {
              variant: "success",
            });
            getData();
            setLoadingSubmit(false);
            setCouponName("");
            setAmount();
            setMinAmount();
            setDescription("");
            setExpiryDate(null);
            setQuantity("");
            handleCloseSecondModal();
          },
          (err) => {
            enqueueSnackbar(err?.response?.data?.message, {
              variant: "error",
            });
            setLoadingSubmit(false);
          }
        )
      );
    }
  };
  const handleReset = () => {
    setCouponName("");
    setAmount();
    setMinAmount();
    setDescription("");
    setExpiryDate(null);
    setQuantity("");
  };

  const userId = user.id;

  const handleOpen = (item) => {
    setId(item?._id);
    setCouponName(item?.couponName);
    setDescription(item?.description);
    setExpiryDateUp(dayjs(item?.expiryDate));
    setStartQuantity(item?.startQuantity);
    setAmountUpdate(item?.amount);
    setMinAmountUpdate(item?.minAmount);
    setOpen(true);
  };

  const handleClose = () => {
    console.log("false");
    setOpen(false);
    setCouponName("");
    setAmount();
    setMinAmount();
    setDescription("");
    setExpiryDate(null);
    setQuantity("");
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleOpenSecondModal = () => {
    setOpenSecondModal(true);
  };

  const handleCloseSecondModal = () => {
    setOpenSecondModal(false);
    setCouponName("");
    setAmount();
    setMinAmount("");
    setDescription("");
    setExpiryDate(null);
    setQuantity("");
  };

  const handleDelete = (id) => {
    dispatch(
      deleteCoupon(
        userId,
        id,
        {},
        (data) => {
          enqueueSnackbar("Delete Successfully", {
            variant: "success",
          });
          getData();
        },
        (err) => {
          enqueueSnackbar("Error Occured! Please try again", {
            variant: "error",
          });
        }
      )
    );
  };

  const handleDateChange = (date) => {
    setExpiryDate(date);
  };
  const handleDateChangeUp = (date) => {
    setExpiryDateUp(date);
  };

  const handleSearchClear = () => {
    setSearchTerm("");
    setSelectedDateFrom(null);
    setSelectedDateTo(null);
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    setLoadingUpdate(true);
    console.log("exp", expiryDate);

    // if (
    //   !description ||
    //   !expiryDateUp ||
    //   parseFloat(minAmountUpdate) >= parseFloat(amountUpdate)
    // ) {
    //   enqueueSnackbar("Please fill all details", {
    //     variant: "warning",
    //   });
    //   setLoadingUpdate(false);
    // } else if (expiryDateUp < new Date()) {
    //   enqueueSnackbar("Expiry date cannot be before today.", {
    //     variant: "error",
    //   });
    //   setLoadingUpdate(false);
    // } else {
    let payload = {
      description: description,
      // quantity: quantity,
      expiryDate: setExpiryDateUp,
      amount: amountUpdate,
      minAmount: minAmountUpdate,
      startQuantity: startQuantity,
    };
    dispatch(
      updateCoupon(
        userId,
        Id,
        payload,
        (data) => {
          enqueueSnackbar("data update successfully", {
            variant: "success",
          });
          getData();
          setDescription("");
          setExpiryDateUp("");
          setLoadingUpdate(false);
          handleClose();
        },
        (err) => {
          enqueueSnackbar("Error occured", {
            variant: "error",
          });
          setLoadingUpdate(false);
        }
      )
    );
    // }
  };
  const handleChangeActive = (event, id, newStatus) => {
    const payload = {
      isActive: newStatus,
    };

    dispatch(
      updateCoupon(
        userId,
        id,
        payload,
        (data) => {
          enqueueSnackbar("Status updated successfully", {
            variant: "success",
          });
          getData();
        },
        (err) => {
          enqueueSnackbar("Error occurred", {
            variant: "error",
          });
        }
      )
    );
  };
  const paginatedData = coupons.slice(
    (currentPage - 1) * countPerPage,
    currentPage * countPerPage
  );

  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(coupons);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Coupons");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAs(new Blob([excelBuffer]), "coupons.xlsx");
  };
  useEffect(() => {
    if (parseFloat(minAmount) < parseFloat(amount)) {
      setError(
        "Minimum Amount to Apply should be greater than or equal to Amount"
      );
    } else {
      setError("");
    }
  }, [amount, minAmount]);
  useEffect(() => {
    if (parseFloat(minAmountUpdate) < parseFloat(amountUpdate)) {
      setError(
        "Minimum Amount to Apply should be greater than or equal to Amount"
      );
    } else {
      setError("");
    }
  }, [amountUpdate, minAmountUpdate]);
  const validateForm = () => {
    if (
      amount &&
      quantity &&
      minAmount &&
      description &&
      expiryDate &&
      parseFloat(minAmountUpdate) >= parseFloat(amountUpdate)
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };

  useEffect(() => {
    validateForm();
  }, [amount, quantity, minAmount, description, expiryDateUp]);
  const validateUpdateForm = () => {
    if (description && expiryDateUp && amountUpdate && minAmountUpdate) {
      setUpdateFormValid(true);
    } else {
      setUpdateFormValid(false);
    }
  };

  useEffect(() => {
    validateUpdateForm();
  }, [description, expiryDate, amountUpdate, minAmountUpdate]);

  return (
    <Fragment>
      <Stack alignItems="center" direction={"row"} mb={3}>
        <Typography
          variant="h4"
          my={3}
          flexGrow={1}
          fontSize={{ xl: "22px", sm: "1rem", xs: "1rem" }}
        ></Typography>
        <Box pr={2}>
          {" "}
          <CustomButton
            variant="contained"
            height={"3rem"}
            onClick={handleExport}
            fontSize={{ xl: "1rem", sm: ".7rem", xs: ".7rem" }}
          >
            Export Coupons
          </CustomButton>
        </Box>
        <CustomButton
          height={"3rem"}
          variant="contained"
          onClick={handleOpenSecondModal}
          fontSize={{ xl: "1rem", sm: ".7rem", xs: ".5rem" }}
        >
          {" "}
          <Add sx={{ mr: { xl: 1, sm: 0.2 } }} />
          Add Special Coupon
        </CustomButton>
      </Stack>
      <Box>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <SearchBar onSearch={handleSearch} searchTerm={searchTerm} />
          </Grid>

          <Grid item md={3} xs={12}>
            <ResponsiveDatePickers
              selectedDate={selectedDateFrom}
              setSelectedDate={setSelectedDateFrom}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ResponsiveDatePickers
              selectedDate={selectedDateTo}
              setSelectedDate={setSelectedDateTo}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            {" "}
            <CustomButton
              variant="contained"
              onClick={handleSearchClear}
              width={"100%"}
              height={"2.5rem"}
            >
              clear
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
      {loading ? (
        <Box align="center" my={5}>
          <CircularProgress size={24} />
        </Box>
      ) : paginatedData.length > 0 ? (
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "25px",
            my: 3,
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {columns?.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#11409B",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      textAlign: "center",
                    }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                whiteSpace: "nowrap",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              {paginatedData?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    {row?.couponName}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    {row?.description}
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    {row?.expiryDate
                      ? dayjs(row?.expiryDate).format("MM-DD-YYYY ")
                      : ""}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    {row?.isApplied == true ? "Yes" : "No"}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    {row?.createdAt
                      ? dayjs(row?.createdAt)?.format("MM-DD-YYYY ")
                      : ""}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    <FormControlLabel
                      style={{
                        padding: "1%",
                      }}
                      control={
                        <IOSSwitch
                          checked={row?.isActive}
                          onChange={(e) =>
                            handleChangeActive(e, row._id, !row.isActive)
                          }
                          name="isActive"
                        />
                      }
                    />
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    <IconButton onClick={() => handleOpen(row)}>
                      <img src="/liEdit.svg" alt="liEdit" />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(row?._id)}>
                      <img src="/liDelet.svg" alt="liDelet" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1" sx={{ textAlign: "center", my: 3 }}>
          No results found.
        </Typography>
      )}

      {/* Pagination */}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography>
            {(currentPage - 1) * countPerPage + 1} -{" "}
            {Math.min(currentPage * countPerPage, coupons.length)} of{" "}
            {coupons.length}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>Rows per page:</Typography>
          <select
            style={{
              marginTop: "-5px",
              background: "transparent",
              border: "none",
            }}
            value={countPerPage}
            onChange={(e) => setCountPerPage(e.target.value)}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
          </select>
          <Box sx={{ ml: 2 }}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <IconButton
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1 || countPerPage > coupons.length}
                sx={{
                  border: "1px solid",
                  borderRadius: "10px",
                  borderColor: "mute.main",
                  bgcolor: "white.main",
                  boxShadow: "2px 2px 0px #999",
                  background: "#fff",
                }}
              >
                <KeyboardArrowLeftIcon
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color:
                      currentPage === 1 || countPerPage > coupons.length
                        ? "#999"
                        : "#000",
                  }}
                />
              </IconButton>
              <Typography variant="text" component="span">
                {currentPage}/{Math.ceil(coupons.length / countPerPage)}
              </Typography>
              <IconButton
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={
                  countPerPage > coupons.length ||
                  currentPage === Math.ceil(coupons.length / countPerPage)
                }
                sx={{
                  border: "1px solid",
                  borderRadius: "10px",
                  borderColor: "mute.main",
                  bgcolor: "white.main",
                  boxShadow: "2px 2px 0px #999",
                  background: "#fff",
                }}
              >
                <KeyboardArrowRightIcon
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "#000",
                  }}
                />
              </IconButton>
            </Stack>
          </Box>
        </Box>
      </Box>
      {open && (
        <RModal
          open={open}
          handleClose={handleClose}
          width={"80%"}
          height={{ xl: "70%", md: "80%", sm: "80%", xs: "80%" }}
        >
          <form onSubmit={handleUpdate}>
            <Typography variant="h4">Edit Coupon</Typography>

            <Grid container spacing={{ xl: 1, sm: 0, xs: 0 }}>
              <Grid item xl={6} xs={12} sm={12}>
                <InputField1
                  placeholderColor=" #8D98AA"
                  label={"Coupon Code"}
                  placeholder={"Enter Coupon Code"}
                  spanP={true}
                  value={couponName}
                  required
                  maxLength={20}
                  disabled
                />
              </Grid>
              <Grid item xl={6} xs={12} sm={12}>
                <InputField1
                  placeholderColor=" #8D98AA"
                  label={"Description"}
                  placeholder={"Enter Description"}
                  spanP={true}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xl={6} xs={12} sm={12}>
                <Typography mt={2}>
                  Expiry date <span style={{ color: "red" }}>*</span>
                </Typography>
                <BasicDatePicker
                  width={{ xl: "100%", xs: "100%" }}
                  required
                  onChange={handleDateChangeUp}
                  label="Expiry Date"
                  value={expiryDateUp}
                />
              </Grid>{" "}
              <Grid item xl={6} xs={12} sm={12}>
                <InputField1
                  placeholderColor=" #8D98AA"
                  label={" Quantity"}
                  placeholder={"Enter  Quantity"}
                  spanP={true}
                  value={startQuantity}
                  onChange={(e) => setStartQuantity(e.target.value)}
                  required
                  type="number"
                />
              </Grid>
              <Grid item xl={6} xs={12} sm={12}>
                <InputField1
                  placeholderColor=" #8D98AA"
                  label={"Amount"}
                  type="number"
                  placeholder={"Enter Amount"}
                  spanP={true}
                  value={amountUpdate}
                  onChange={(e) => setAmountUpdate(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xl={6} xs={12} sm={12}>
                <InputField1
                  placeholderColor=" #8D98AA"
                  type="number"
                  label={"Minimum Amount To Apply "}
                  placeholder={"Enter Minimum Amount "}
                  spanP={true}
                  value={minAmountUpdate}
                  onChange={(e) => setMinAmountUpdate(e.target.value)}
                  required
                />{" "}
                {error && (
                  <Typography color="red" variant="body2">
                    {error}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Stack
              direction={"row"}
              spacing={{ xl: 6, md: 6, sm: 1, xs: 1 }}
              mt={4}
            >
              {" "}
              <CustomButton
                variant="contained"
                width={{
                  xl: "25vh",
                  md: "50%",
                  sm: "50%",
                  xs: "60%",
                }}
                height={"3rem"}
                type="submit"
                disabled={!updateFormValid || loadingUpdate}
              >
                {loadingUpdate ? (
                  <CircularProgress size={24} />
                ) : (
                  "Update Coupon"
                )}
              </CustomButton>
            </Stack>
          </form>
        </RModal>
      )}
      {openSecondModal && (
        <RModal
          open={openSecondModal}
          handleClose={handleCloseSecondModal}
          width={{ md: "60%", xs: "80%" }}
          height={{ xl: "70%", md: "80%", sm: "80%", xs: "80%" }}
        >
          <form onSubmit={handleSubmit}>
            <Typography variant="h4">Add Special Coupon</Typography>

            <Grid container spacing={{ xl: 1, sm: 0, xs: 0 }}>
              <Grid item xl={6} xs={12} sm={12}>
                <InputField1
                  placeholderColor=" #8D98AA"
                  label={"Amount"}
                  type="number"
                  placeholder={"Enter Amount"}
                  spanP={true}
                  required
                  name="amount"
                  onChange={(e) => setAmount(parseFloat(e.target.value) || 0)}
                />
              </Grid>
              <Grid item xl={6} xs={12} sm={12}>
                <Typography mt={2}>
                  Expiry date <span style={{ color: "red" }}>*</span>
                </Typography>
                <BasicDatePicker
                  width={{ xl: "100%", xs: "100%" }}
                  required
                  onChange={handleDateChange}
                  name="expiryDate"
                  value={expiryDate}
                />
              </Grid>
              <Grid item xl={6} xs={12} sm={12}>
                <InputField1
                  placeholderColor=" #8D98AA"
                  label={" Quantity "}
                  placeholder={"Enter Coupon Quantity "}
                  type="number"
                  spanP={true}
                  required
                  name="couponQuantity"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </Grid>
              <Grid item xl={6} xs={12} sm={12}>
                <InputField1
                  placeholderColor=" #8D98AA"
                  type="number"
                  label={"Minimum Amount To Apply "}
                  placeholder={"Enter Minimum Amount "}
                  spanP={true}
                  required
                  name="minAmountToApply"
                  value={minAmount}
                  onChange={(e) =>
                    setMinAmount(parseFloat(e.target.value) || 0)
                  }
                />
                {error && (
                  <Typography color="red" variant="body2">
                    {error}
                  </Typography>
                )}
              </Grid>
              <Grid item xl={6} xs={12} sm={12}>
                <InputField1
                  placeholderColor=" #8D98AA"
                  label={"Description"}
                  placeholder={"Enter Description"}
                  spanP={true}
                  required
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
              <Grid item sm={12} mt={2.5}>
                <Typography>
                  Do You Want Coupon Codes to be Numerical?
                </Typography>
                <FormControlLabel
                  style={{
                    padding: "1%",
                  }}
                  control={
                    <IOSSwitch
                      checked={formData.numericalCodes}
                      onChange={handleChange}
                      name="numericalCodes"
                    />
                  }
                />
              </Grid>
            </Grid>
            <Stack
              direction={"row"}
              spacing={{ xl: 6, md: 6, sm: 1, xs: 1 }}
              mt={4}
            >
              <CustomButton
                variant="contained"
                width={{ md: "30%", sm: "50%", xs: "60%" }}
                height={"3rem"}
                type="submit"
                // disabled={!formValid || loadingSubmit}
              >
                {loadingSubmit ? (
                  <CircularProgress size={24} />
                ) : (
                  "Add Special Coupon"
                )}
              </CustomButton>
              <CustomButton
                variant="outlined"
                width={{
                  xl: "25vh",
                  md: "50%",
                  sm: "50%",
                  xs: "40%",
                }}
                height={"3rem"}
                type="button"
                onClick={handleReset}
              >
                Reset
              </CustomButton>
            </Stack>
          </form>
        </RModal>
      )}
    </Fragment>
  );
};
