import React, { useEffect, useState } from 'react';
import Section from '../../components/Section';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  List,
  Stack,
  Typography,
} from '@mui/material';
import SectionImg from '../../media/image/section2img.png';
import LogoImg from '../../media/image/logo.png';
import { primaryColor, secondaryColor } from '../../style/color';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import InputField1 from '../../components/mui/InputField1';
import CustomButton from '../../components/mui/CustomButton';
import OTPInput from 'react-otp-input';
import { useDispatch } from 'react-redux';
import { login, verifyOtp } from '../../actions/userAuth';
import { enqueueSnackbar } from 'notistack';
import { ClassRounded } from '@mui/icons-material';

export const Login = () => {
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [otp, setOtp] = useState('');
  const [otpInputActive, setOtpInputActive] = useState(false);
  const Navigate = useNavigate();
  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
    if (e.target.value) {
      setIsEmailValid(validateEmail(enteredEmail));
    } else {
      setIsEmailValid(true);
    }
  };
  const validateEmail = (email) => {
    // const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(email);
  };

  const dispatch = useDispatch();
  const [sendOtpLoading, setSendOtpLoading] = useState(false);
  const [token, setToken] = useState('');

  useEffect(() => {
    if (localStorage.getItem('token')) {
      Navigate('/dashboard');
    }
  }, []);
  const handleContinue = (e) => {
    e.preventDefault();
    setSendOtpLoading(true);
    if (isEmailValid) {
      dispatch(
        login(
          { phone: email },
          (data) => {
            enqueueSnackbar('Otp sent successfully', {
              variant: 'success',
            });
            setToken(data.data.data.token);
            setOtpInputActive(true);
            setSendOtpLoading(false);
          },
          (err) => {
            console.error("err is",err.response


)
            enqueueSnackbar(err?.response?.data, {
              variant: 'error',
            });
            setSendOtpLoading(false);
          }
        )
      );
    }
  };

  const [loginLoading, setLoginLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoginLoading(true);
    dispatch(
      verifyOtp(
        { otp, token },
        (data) => {
          localStorage.setItem('token', data.data.jwt);
          localStorage.setItem(
            'SiriAdminUserRole',
            data.data.data.role
          );
          localStorage.setItem('User id', data.data.data.id);
          dispatch({
            type: 'LOG_IN',
            payload: data.data.data,
          });
          enqueueSnackbar('Logged in successfully', {
            variant: 'success',
          });
          if (
            data.data.data.role == 'admin' ||
            data.data.data.role == 'superAdmin'
          ) {
            Navigate('/dashboard');
          } else {
            enqueueSnackbar(
              "you don't have the access to this portal",
              {
                variant: 'error',
              }
            );
          }
          setLoginLoading(false);
        },
        (err) => {

          console.error(err.response.data.message
)
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          });
          setLoginLoading(false);
        }
      )
    );
  };

  const otpStyle = {
    input: {
      minHeight: 40,
      fontSize: 16,
      flexGrow: 1,
      '&+input': { ml: 2 },

      appearance: 'textfield',
    },
    '& input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
      display: 'none',
    },
    '& input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
      display: 'none',
    },
  };
  const inputStyle = {
    ...otpStyle.input,
    width: '15%',
    height: '4rem',
    margin: '1%',
    borderRadius: '8px',
    border: '1px solid gray',
    background: '#fff',
    textAlign: 'center',
    '&:hover, &:active, &:focus': {
      '& .otp-input-arrow': {
        display: 'none',
        border: '1px solid gray',
      },
    },
  };

  return (
    <Section>
      <Container maxWidth={'xl'}>
        <Box mt={4}>
          <img
            src='/logo.webp'
            style={{
              height: {
                xl: '41.01px',
                md: '41.01px',
                xs: '20px',
              },
              width: '199px',
            }}
            alt='logo'
          />
        </Box>
        <Grid
          container
          spacing={1}
          alignItems={{
            xl: 'center',
            xs: 'flex-start',
            md: 'center',
            sm: 'flex-start',
          }}
          sx={{
            minHeight: 'calc(100vh - 100px)',
            marginTop: {
              xl: '-5%',
              xs: '-10%',
              md: '-10%',
              sm: '-5%',
            },
          }}
        >
          <Grid item md={5} xs={12}>
            <Box
              my={7}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                mx: {
                  xs: 0,
                  sm: 0,
                  md: 6,
                  lg: 6,
                  xl: 6,
                },
              }}
            >
              <Box sx={{ pb: 4 }}>
                <Typography variant='h1' component={'h2'}>
                  {otpInputActive ? 'Enter OTP' : 'Login'}
                </Typography>
                <Typography
                  variant='h5'
                  component={'p'}
                  mt={1}
                  sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                >
                  {otpInputActive
                    ? `We’ve sent a code to  ${email}`
                    : 'Please fill your detail to access your account.'}
                </Typography>
              </Box>
              <Box maxWidth={600} mx={'auto'}>
                <form>
                  <Stack sx={{ marginBottom: '2%' }}>
                    <InputField1
                      label={'Enter Phone Number'}
                      placeholder={'Your Phone Number'}
                      value={email}
                      required
                      maxLength={50}
                      disabled={otpInputActive}
                      onChange={handleEmailChange}
                    />
                  </Stack>
                  {!isEmailValid ? (
                    <Stack>
                      <Typography sx={{ color: 'red' }}>
                        Please enter a valid mobile number
                      </Typography>
                    </Stack>
                  ) : (
                    ''
                  )}
                  {!otpInputActive ? (
                    <>
                      <Box sx={{ opacity: '.1' }} mt={3}>
                        <Stack>
                          <Typography variant='p'>
                            Enter OTP
                          </Typography>
                        </Stack>
                        <Stack>
                          <OTPInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={4}
                            inputType='number'
                            renderSeparator={<span> </span>}
                            renderInput={(props) => (
                              <input
                                {...props}
                                style={inputStyle}
                                disabled={!otpInputActive}
                              />
                            )}
                            isInputNumValid={() => false}
                          />
                        </Stack>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Stack mt={3}>
                        <Typography variant='p'>
                          Enter OTP
                        </Typography>
                      </Stack>
                      <Stack>
                        <Box>
                          <OTPInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={4}
                            inputType='number'
                            renderSeparator={<span> </span>}
                            renderInput={(props) => (
                              <input
                                {...props}
                                style={inputStyle}
                                disabled={!otpInputActive}
                              />
                            )}
                            isInputNumValid={() => false}
                          />
                        </Box>
                      </Stack>
                    </>
                  )}
                  {!otpInputActive ? (
                    <Stack mt={4}>
                      <CustomButton
                        onClick={handleContinue}
                        variant='contained'
                        fullWidth
                        color='primary'
                        disabled={
                          !validateEmail(email) ||
                          email.length === 0 ||
                          sendOtpLoading
                        }
                      >
                        {sendOtpLoading ? (
                          <CircularProgress />
                        ) : (
                          'Continue'
                        )}
                      </CustomButton>
                    </Stack>
                  ) : (
                    <Stack mt={4}>
                      <CustomButton
                        onClick={handleSubmit}
                        variant='contained'
                        fullWidth
                        color='primary'
                        disabled={
                          (otpInputActive && otp.length !== 4) ||
                          loginLoading
                        }
                      >
                        {loginLoading ? (
                          <CircularProgress />
                        ) : (
                          'Submit'
                        )}
                      </CustomButton>
                    </Stack>
                  )}
                </form>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            md={7}
            sx={{ display: { md: 'block', xs: 'none' } }}
          >
            <Box
              sx={{
                my: 2,
                px: 5,
                py: 4,
                minHeight: 'calc(100vh - 200px)',
                display: 'flex',
                alignItems: 'center',
                img: { width: '100%', height: 'auto' },
                borderRadius: 3,
              }}
            >
              <Box>
                <img
                  src={SectionImg}
                  style={{
                    height: '100%',
                    width: '100%',
                    borderRadius: '3px',
                  }}
                  alt='Products'
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};