import React, { useEffect, useState } from "react";
import HomeLayout from "../../asset/layout/HomeLayout";
import { ProfileCard } from "../../components/Cards/ProfileCard";
import { Box, Card, Grid, Typography } from "@mui/material";
import { Container } from "@mui/material";

import { MasterCard } from "../../components/Cards/MasterCard";
import Chart from "../../components/Chart/Chart";

import ListCard from "../../components/Cards/ListCard";
import { useDispatch } from "react-redux";
import { getAllUsers } from "../../actions/userAuth";
import {
  getFlipkartCount,
  getTotalAmount,
  getVouchersAndOrdersCount,
} from "../../actions/brandsAuth";
import { getAllTransactions } from "../../actions/transactionsAuth";
import { useNavigate } from "react-router-dom";

const iconData = [
  {
    src: "/Ticket.svg",
    alt: "users",
    name: "Vouchers",
    bgColor: "#FFF5D9",
  },
  {
    src: "/flipkart.svg",
    alt: "flipkart",
    name: "Flipkart",
    bgColor: "#E7EDFF",
  },
  {
    src: "/Users.svg",
    alt: "users",
    name: "Users",
    bgColor: "#E7EDFF",
  },

  {
    src: "/Card.svg",
    alt: "users",
    name: "Total Amount",
    bgColor: "#DCFAF8",
  },
];

export const DashBoard = () => {
  const [users, setUsers] = useState([]);
  const [usersresults, setUsersresults] = useState("");
  const [vouchers, setVouchers] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [balance, setBalance] = useState(0);
  const Navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      Navigate("/");
    }
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getAllUsers(
        (data) => {
          setUsersresults(data?.data?.results);
          setUsers(data?.data?.data?.users);
        },
        (err) => {
          console.log(err);
        }
      )
    );

    dispatch(
      getAllTransactions(
        (data) => {
          setTransactions(data?.data?.data?.Transactions);
        },
        (err) => {
          console.log(err);
        }
      )
    );

    dispatch(
      getTotalAmount(
        (data) => {
          setTotalAmount(data?.data?.data?.amount);
        },
        (err) => {
          console.log(err);
        }
      )
    );

    dispatch(
      getVouchersAndOrdersCount(
        (data) => {
          setVouchers(data?.data?.data?.voucherCount);
        },
        (err) => {
          console.log(err);
        }
      )
    );
    dispatch(
      getFlipkartCount(
        (data) => {
          setBalance(data?.data?.data?.clients[0]?.balance);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }, []);
  return (
    <Box sx={{ display: "flex", background: "#F5F7FA" }}>
      <HomeLayout>
        <Container maxWidth={"xl"}>
          <Grid container spacing={4} my={4}>
            {iconData.map((data, index) => (
              <Grid
                item
                key={index}
                md={3}
                xs={6}
                xl={3}
                sm={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <MasterCard
                  {...data}
                  users={users}
                  vouchers={vouchers}
                  totalAmount={totalAmount}
                  usersresults={usersresults}
                  flipkartAmount={balance}
                />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={2}>
            <Grid item xl={8} md={6} sm={12} xs={12}>
              <Typography variant="h3" my={2}>
                Weekly Activity
              </Typography>
              <Card
                sx={{
                  height: "40vh",
                  boxShadow: "none",
                  borderRadius: "20px",
                }}
              >
                <Chart />
              </Card>
            </Grid>

            <Grid item md={6} xs={12} xl={4} sm={12}>
              <Typography variant="h3" my={2}>
                Recent Transaction
              </Typography>
              <ListCard transactions={transactions} />
            </Grid>
          </Grid>
          <Typography mt={5} mb={2} variant="h3">
            Recent Users
          </Typography>
          <Grid container spacing={3}>
            {users.map((data, index) => (
              <Grid item key={index} md={3} xs={6} xl={2.4} sm={4}>
                <ProfileCard
                  username={data.username}
                  phone={data.phone}
                  email={data.email}
                  image={data}
                  dis={data.dis}
                  showMessageButton={false}
                />{" "}
              </Grid>
            ))}
          </Grid>
        </Container>
      </HomeLayout>
    </Box>
  );
};
